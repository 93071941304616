import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import { getCalendarEvent } from '../../libs/dataFunctions';
import Sidebar from '../../components/Sidebar/Sidebar';
import AdminNavbar from '../../components/AdminNavbar/AdminNavbar';
import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!

import placeholderImg from '../../assets/images/placeholder.png';
import plaisportVector from '../../assets/images/plaisportVector.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import moment from "moment";
import { daysInMonth, years } from '../../libs/utils';
import Spinner from '../../components/Spinner/Spinner';
import ReactDatePicker from 'react-datepicker';
import { useAuthState } from '../../contexts/AuthContext';
import 'react-calendar/dist/Calendar.css';
import './CalendarDuties.scss';

const CalendarDuties = () => {
    const userDetails = useAuthState() //read user details from context
    const navigate = useNavigate();
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';
    const [selectedYear, setSelectedYear] = useState(new Date());
    const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1 < 10 ? "0" + (moment().month() + 1) : moment().month() + 1);
    const [selectedDay, setSelectedDay] = useState(moment().date(moment().date()).format("DD"));
    const [typeDropdown, setShowTypeDropdown] = useState(false);
    const [pageData, setPageData] = useState({
        data: [],
        event_type: {
            Game: 0,
            Others: 0,
            Practise: 0,
            'Social Event': 44
        },
        total_event: 0
    });
    const [popupData, setPopupData] = useState({
        open: false,
        data: null
    });
    const [loading, setIsLoading] = useState(false);
    const daySliderRef = useRef();
    const monthSliderRef = useRef();
    const calendarRef = useRef();
    const caltypedropref = useRef();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        centerMode: true,
        draggable: false,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 999,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    const daySettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 7,
        centerMode: false,
        draggable: false,
        swipeToSlide: false,        
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 999,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
        ]
    };

    const getSlidesToScroll = () => {
        const width = window.innerWidth;
        if (width <= 480) return 1;  // Mobile
        if (width <= 768) return 3;  // Tablet
        return 7;                   // Desktop
    };

    useEffect(() => {
        if (daySliderRef?.current) {
            daySliderRef?.current?.slickGoTo(selectedDay - 1);
        }
    }, [daySliderRef?.current, selectedDay]);

    useEffect(() => {
        if (monthSliderRef?.current) {
            monthSliderRef?.current?.slickGoTo(Number(selectedMonth) - 1);
        }
    }, [monthSliderRef?.current, selectedMonth]);

    useEffect(() => {
        if (calendarRef?.current?.calendar?.gotoDate) {
            calendarRef?.current?.calendar?.gotoDate(`${moment(selectedYear).format("yyyy")}-${selectedMonth}-${selectedDay}`);
        }
    }, [calendarRef?.current, selectedMonth, selectedDay, selectedYear]);

    const _getCalendarEvents = async () => {
        try {
            setIsLoading(true);
            const result = await getCalendarEvent(token, `${moment(selectedYear).format("yyyy")}-${selectedMonth}-${selectedDay}`);
            setIsLoading(false);
            if (result.status) {
                setPageData({
                    data: result.data?.map((item) => {
                        return {
                            id: item._id,
                            title: item.name,
                            start: `${item.dateStart_display}T${item.timeStart_display}`,
                            end: moment(`${item.dateStart_display}T${item.timeStart_display}`).add(30, 'minutes'),
                            extendedProps: item,
                        }
                    }),
                    event_type: result.event_type,
                    total_event: result.total_event
                })
            } else {
                setPageData({
                    data: [],
                    event_type: {
                        Game: 0,
                        Others: 0,
                        Practise: 0,
                        'Social Event': 44
                    },
                    total_event: 0
                });
            }
        } catch (error) {
            //console.log("error: _getCalendarEvents", error);
            setPageData({
                data: [],
                event_type: {
                    Game: 0,
                    Others: 0,
                    Practise: 0,
                    'Social Event': 44
                },
                total_event: 0
            });
        }

        const calendarApi = calendarRef.current?.getApi();
        if (calendarApi) {
            const dayViewContainer = document.querySelector(
              ".fc-scrollgrid" // Container for time-grid events
            );
      
            // Locate the first event element
            const firstEvent = dayViewContainer?.querySelector(".fc-event");
            if (firstEvent) {
              firstEvent.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
    }

    useEffect(() => {
        _getCalendarEvents();
    }, [selectedDay, selectedMonth, selectedYear]);

    useEffect(()=>{
        const calendarApi = calendarRef.current?.getApi();
        if (calendarApi) {
            const dayViewContainer = document.querySelector(
              ".fc-scrollgrid" // Container for time-grid events
            );

            const scrollContainer = document.querySelector(
                ".fc-scroller" // Container for time-grid events
              );
      
            // Locate the first event element
            const firstEvent = dayViewContainer?.querySelector(".fc-event");
            if (scrollContainer &&  firstEvent) {
                /*const eventTopOffset = firstEvent.offsetTop;
                scrollContainer.scrollTo({
                    top: eventTopOffset - 10, // Optional padding
                    behavior: "smooth",
                });*/
                firstEvent.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
    },[pageData]);

    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if ((typeDropdown) && caltypedropref.current && !caltypedropref.current.contains(e.target)) {
                setShowTypeDropdown(false);
            }
        }      
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[typeDropdown]);

    return (
        <>
            <Sidebar type="thin" />
            <div id="page" className="page-calendar-duties">
                <AdminNavbar heading="Calendar" />
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Calendar
                </div>
                <div className='page-header flex items-center pl-5 pr-7'>
                    <h1>Calendar View</h1>
                </div>
                <div className="body px-5 lg:px-8 py-4">
                    <div className='flex justify-between items-center w-100 lg:w-9/12'>
                        <button type="button" className='opacity-50' onClick={()=>navigate(-1)}> <span className='separator'>&lt;</span><span className='separator'>&lt;</span> Back</button>
                        <div className='flex gap-5 items-start'>
                            <div className='relative' ref={caltypedropref}>
                                <button type='button' onClick={()=>{setShowTypeDropdown(!typeDropdown)}} className='py-3.5 px-4 rounded-xl bg-white flex gap-5 items-center'>
                                    Weekly
                                    <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5 1.5L7.5 7.5L13.5 1.5" stroke="#5E6366" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                <div className={`bg-white shadow-lg rounded-xl flex-col absolute top-[100%] mt-2 w-full z-20 ${typeDropdown ? 'flex' : 'hidden'}`}>
                                    <NavLink to='/calendar' className="px-5 py-2 border-b border-slate-300 text-sm">Weekly</NavLink>
                                    <NavLink to='/calendar/month' className="px-5 py-2 text-sm">Monthly</NavLink>
                                </div>
                            </div>
                            <ReactDatePicker
                                selected={selectedYear}
                                onChange={(date) => {
                                    setSelectedYear(date);
                                }}
                                showYearPicker
                                dateFormat="yyyy"
                                className='yearPicker !w-28'
                            />
                        </div>
                    </div>
                    <div className='pb-4 flex flex-col-reverse lg:flex-row'>
                        <div className='w-100 lg:w-9/12 clubbed-card mt-6 lg:mt-0'>
                            <div className='months'>
                                <Slider {...settings} ref={monthSliderRef}>
                                    {moment.months().map((month) => {
                                        return (
                                            <p key={month} className={`${moment().month(month).format("MM") === selectedMonth ? 'active' : ''}`} onClick={() => {
                                                setSelectedMonth(moment().month(month).format("MM"));
                                            }}>{month}</p>
                                        )
                                    })}
                                </Slider>
                            </div>
                            <div className='divider mb-5'></div>
                            <div className='days'>
                                <Slider {...daySettings} ref={daySliderRef}>
                                    {Array.from({ length: daysInMonth(selectedMonth, moment(selectedYear).format("yyyy")) }, (_, i) => i + 1).map((day) => {
                                        const formatted = day > 10 ? "" + day : moment().date(day).format("DD");
                                        return (
                                            <div className='day-circle' key={day} onClick={() => {
                                                setSelectedDay(formatted);
                                            }}>
                                                <div className={`circle-day flex justify-center items-center ${formatted === selectedDay ? 'active' : ''}`}>
                                                    <p>
                                                        {day}
                                                    </p>
                                                </div>
                                                <p className={`${formatted === selectedDay ? 'active' : ''}`}>{moment(`${day}-${selectedMonth}-${moment(selectedYear).format("yyyy")}`, 'DD-MM-YYYY').format('dddd')}</p>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </div>
                            <div className='divider'></div>
                            <FullCalendar
                                ref={calendarRef}
                                plugins={[timeGridPlugin]}
                                initialView="timeGridDay"
                                businessHours={false}
                                height={'100vh'}
                                slotDuration={'00:30:00'}
                                allDaySlot={false}
                                headerToolbar={true}
                                dayHeaders={false}
                                slotLaneClassNames={'slot-lane'}
                                slotLabelInterval={'00:30:00'}
                                slotLabelClassNames={'slot-label'}
                                events={pageData?.data ?? []}
                                eventBackgroundColor="#363F6C"
                                eventOrderStrict={true}
                                slotEventOverlap={false}
                                eventContent={(props) => {
                                    const data = props.event.extendedProps;
                                    return (
                                        <div className='flex cell-card' id="registerTip">
                                            <div className='plaisport-vector'>
                                                <img src={plaisportVector} alt=''></img>
                                            </div>
                                            {/* <div className='flex cell-img'>
                                                    <img src={data?.club_logo} alt=''></img>
                                                </div> */}
                                            <div className='flex flex-col cell-title'>
                                                <p className='title'>Name</p>
                                                <p className='value'>{data?.name}</p>
                                            </div>
                                            {/* <div className='flex flex-col cell-description'>
                                                <p className='title'>Location</p>
                                                <p className='value'>{data?.location}</p>
                                            </div> */}
                                        </div>
                                    )
                                }}
                                eventClick={(props) => {
                                    setPopupData({
                                        open: true,
                                        data: props.event.extendedProps
                                    });
                                }}
                            />
                        </div>
                        <div className='w-100 lg:w-1/4 flex items-center flex-col'>
                            {userDetails?.user?.profilePic !== "" ?
                            <img src={userDetails?.user?.profilePic} className="w-40 h-40 rounded-full img-right-profile" alt="" width={40} height={40} />
                            :
                            <img src={placeholderImg} alt="" className="w-40 h-40 rounded-full img-right-profile" width={40} height={40} />
                            }
                            <div className='h-64 w-64 bg-[#363F6C] rounded-2xl -top-10 flex justify-center items-center flex-col'>
                                <div className='gradient-1'></div>
                                <p className='font-normal text-2xl text-white pt-7 pb-2'>Events this week</p>
                                <p className='font-extrabold text-7xl count-event pb-7'>{pageData?.total_event}</p>
                                <div className='gradient-2'></div>
                            </div>
                            <div className='grid grid-cols-2 gap-4 mt-6'>
                                <div className='category-count'>
                                    <p className='category'>Practice</p>
                                    <p className='count'>{pageData?.event_type?.Practise}</p>
                                </div>
                                <div className='category-count'>
                                    <p className='category'>Games</p>
                                    <p className='count'>{pageData?.event_type?.Game}</p>
                                </div>
                                <div className='category-count'>
                                    <p className='category'>Social</p>
                                    <p className='count'>{pageData?.event_type?.['Social Event']}</p>
                                </div>
                                <div className='category-count'>
                                    <p className='category'>Others</p>
                                    <p className='count'>{pageData?.event_type?.Others}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                popupData?.open &&
                <div className="popup-form justify-center">
                    <div className="w-full max-w-[800px] relative">
                        <button type="button" className="close-popup z-100" onClick={(e) => {
                            setPopupData({
                                open: false,
                                data: null
                            })
                        }}><span>close</span></button>
                        <div className='flex cell-card pop-up' id="registerTip">
                            <div className='plaisport-vector'>
                                <img src={plaisportVector} alt='' />
                            </div>
                            <NavLink className='flex cell-img relative' to={`/team/${popupData?.data?.teamId}/event/${popupData?.data?._id}`} target='_blank'>
                                <img src={popupData?.data?.club_logo} alt=''/>
                            </NavLink>
                            <div className='flex flex-col cell-title'>
                                <p className='title'>Name</p>
                                <NavLink to={`/team/${popupData?.data?.teamId}/event/${popupData?.data?._id}`} target='_blank'>
                                    <p className='value'>{popupData?.data?.name}</p>
                                </NavLink>
                                <NavLink to={`/team/${popupData?.data?.teamId}/event/${popupData?.data?._id}`} target='_blank' className="mt-2.5 text-[#fbdb09] text-[14px]">View event</NavLink>
                            </div>
                            <div className='flex flex-col cell-description'>
                                <p className='title'>Location</p>
                                <p className='value'>{popupData?.data?.location}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                loading &&
                <div className='popup-form justify-center'>
                    <Spinner></Spinner>
                </div>
            }
        </>
    )
}
export default CalendarDuties;