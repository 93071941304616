import {useState, useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { useFormData } from '../../contexts/FormContext';
import nochildren from '../../assets/images/no-children.svg';
import SignUpChildernForm from './SignUpChildrenForm';
import SignUpChildRow from './SignUpChildRow';

const SignUpStepFour =({ formStep, nextFormStep }) => {
    const { setFormValues } = useFormData();
    const [childrenList, setChildrenList] = useState([]);
    const [showChildForm, setChildFormShow] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "all"
    });
    
    const ChildAddForm = ({setShowForm}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap !w-full !max-w-[870px] relative !px-7.5">                    
                    <SignUpChildernForm isEditChild={false} setShowForm={setShowForm} setChildrenList={setChildrenList} childrenList={childrenList} />
                </div>
            </div>
        )
    }

    const onSubmitNochild = (values) =>{
        setFormValues({});
        nextFormStep();
    }
    const onSubmitWithchild = (values) => {
        setFormValues({children:childrenList});
        nextFormStep();
    };
    
    return (
        <>
        <div className='auth-form-content text-center mb-[34px]'>
            {childrenList.length>0 ?
                <>
                    <h2>Your Children</h2>
                </>
            :
                <>
                    <h2>Do you have Children playing in Junior Sport?</h2>
                </>
            }
        </div>
        <div className={formStep === 3 ? '' : 'hidden'}>
            <div className='auth-form-field-wrap justify-center mb-5 relative'>
            {childrenList.length>0 ?
                <>
                {childrenList.map((childitem,index)=>{
                    return(
                        <SignUpChildRow childitem={childitem} key={index} setChildrenList={setChildrenList} childrenList={childrenList} index={index}/>
                    )
                })}
                </>
            :
                <>
                <div className='flex flex-col justify-center items-center mb-10'>
                    <img src={nochildren} width={231} height={214} alt="" className='mb-2.5' />
                    <p className='text-center text-[16px] leading-[28px] text-black'>No Children added yet!</p>
                </div>
                <div className='auth-form-field-wrap flex justify-center mb-2.5 md:mb-10'>
                    <input className='button button--large' type="button" value={'Add your child'} onClick={()=>{setChildFormShow(true)}} />
                </div>
                </>
            }
            </div>
            {childrenList.length>0 ?
                <form onSubmit={handleSubmit(onSubmitWithchild)}>
                    <div className='auth-form-field-wrap flex flex-col justify-center items-center'>
                        <input className='button button--large mb-5' type="submit" value={'Continue'} />
                        <button type='button' className='text-[14px] leading-[28px] font-medium underline hover:no-underline text-[#363F6C]' onClick={()=>{setChildFormShow(true)}}>Add another child</button>
                    </div>                
                </form>
            :
                <form onSubmit={handleSubmit(onSubmitNochild)}>
                    <div className='auth-form-field-wrap flex justify-center'>
                        <button className='text-[14px] leading-[28px] font-medium underline hover:no-underline text-[#363F6C]'>I don't have children participating in Junior Sport</button>
                    </div>                
                </form>
            }
        </div>
        {showChildForm &&
            <ChildAddForm setShowForm={setChildFormShow} />
        }
        </>
    );
}

export default SignUpStepFour;