import { useContext, useState, useEffect, useRef} from "react";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import * as DOMPurify from 'dompurify';
import { Tooltip } from 'react-tooltip';
import { useAuthState } from "../../contexts/AuthContext";
import { calculate_age } from "../../libs/utils";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import IsMobile from "../../components/isMobile";
import Sidebar from "../../components/Sidebar/Sidebar";
import { TeamContext, TeamContextProvider } from "../../contexts/TeamContext";
import TabNews from "./News/TabNews";
import TabEvents from "./Events/TabEvents";
import TabDuties from "./Duties/TabDuties";
import TabShop from "./Shop/TabShop";
import TabMembers from "./Members/TabMembers";
import TabLinks from "./Links/TabLinks";
import TabGroups from "./Groups/TabGroups";
import TabManageStripe from "./ManageStripe/TabManageStripe";
import TabAds from "./Ads/TabAds";
import placeholderImg from '../../assets/images/placeholder.png';
import TeamForm from "../../components/TeamForm/TeamForm";
import TabSubscriptions from "./Subscriptions/TabSubscriptions";
import TabDiscounts from "./Discounts/TabDiscounts";
import CFGSubscriptionForm from "./FantacyGame/CFGSubscriptionForm";
import AffiliateDashboard from "./AffiliateDashboard/AffiliateDashboard";
import Spinner from "../../components/Spinner/Spinner";
import TabRequests from "./Requests/TabRequests";
import UpdateDetailForm from "./UpdateDetailForm";
import NotificationForm from "./Notifications/Notifications";
import copyIcon from "../../assets/images/copy.svg";
import plaiSmallLogo from "../../assets/images/logo-small-white.svg";
import InfoIcon from '../../assets/images/info-white.svg';
import { confirmAlert } from 'react-confirm-alert';
import LeaveTeamWithNewCreator from "./LeaveTeamWithNewCreator";
import TabGallery from "./Gallery/TabGallery";
import 'react-confirm-alert/src/react-confirm-alert.css';
import './Team.scss';

import {
    FacebookShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    WhatsappIcon,    
} from "react-share";
import DraftNews from "./News/DraftNews";
import AdminForms from "./YourForms/AdminForms";



const TeamOverView = () => {
    const { team, teamUpdating, refreshTeam } = useContext(TeamContext);
    const userDetailAuth = useAuthState();
    const userDetail = userDetailAuth?.user;
    const { tab } = useParams();
    const [tabActive, setTabActive] = useState(tab || 'news');
    const ismobile = IsMobile(window);
    const [showSubscription, setShowSubscription] = useState(false);
    const [showDeleteTeamPrompt, setShowDeleteTeamPrompt] = useState(false);
    const [showShareRefferal, setShowShare] = useState(false);
    const [galleryMedia,setGalleryMedia] = useState(null);
    
    useEffect(() => {
        setTabActive(tab || 'news');
    }, [tab]);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
        ? localStorage.getItem('auth_token')
        : '';

    const schema = yup.object({
        password: yup.string().required('Please Enter your password'),
    }).required();

    const copyToClipboard=(data) => {
        let textField = document.createElement('textarea');
        textField.innerText = data;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        alert('Copied!');
    }

    const ShareReferralCode = ({ setShowShare }) => {
        return (
            <div className="popup-form">
                <div className="popup-form-wrap relative w-full !max-w-[400px]">
                    <button type="button" className="close-popup" onClick={(e) => { setShowShare(false) }}><span>close</span></button>
                    <div className="form-heading text-center mb-[30px]">Share</div>
                    <div className='flex flex-wrap items-center'>
                        <div className='px-2.5 mb-5'>
                            <FacebookShareButton url={process.env.REACT_APP_WEBURL} quote={team.affiliate_program_share_message}>
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <LinkedinShareButton url={process.env.REACT_APP_WEBURL} title={`${team.name} are using PLAI to streamline the running of their program this year!`} summary={team.affiliate_program_share_message} source='PLAI Sports'>
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <WhatsappShareButton url={process.env.REACT_APP_WEBURL} title={team.affiliate_program_share_message}>
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const ShareQRCode = ({ setShowpopup }) => {
        return (
            <div className="popup-form">
                <div className="popup-form-wrap relative w-full !max-w-[400px]">
                    <button type="button" className="close-popup" onClick={(e) => { setShowpopup(false) }}><span>close</span></button>
                    <div className="form-heading text-center mb-[30px]">Share</div>
                    <div className='flex flex-wrap items-center'>
                        <div className='px-2.5 mb-5'>
                            <FacebookShareButton url={team.qrcode_image} quote="Scan the Code to Join the Team!">
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <LinkedinShareButton url={team.qrcode_image} title="Scan the Code to Join the Team!" summary="Scan the Code to Join the Team!" source='PLAI Sports'>
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <WhatsappShareButton url={team.qrcode_image} title="Scan the Code to Join the Team!">
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const DeletePromptForm = ({setShowForm}) =>{
        const [ploading, setPLoading] = useState(false);
        const [errorMessage, setErrorMessage] = useState(false);
        const [showPassword, setShowPassword] = useState(false);
        const { register, handleSubmit, formState: { errors } } = useForm({
            resolver: yupResolver(schema)
        });

        const onSubmit = async (data) => {
            setPLoading(true);
            setErrorMessage(false);
            var formdata = new FormData();
            formdata.append("type", "delete_team");
            formdata.append("team_id", team._id);
            formdata.append("password", data.password);
            
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status===1){
                    setPLoading(false);
                    setErrorMessage(true);
                    window.location.href = '/';
                }
                else{
                    setPLoading(false);
                    setErrorMessage(true);
                }
            })
            .catch(error => console.log('error', error));
        }
        const handleClickShowPassword = () => {
            setShowPassword(!showPassword);
        };
    
        return(
            <div className="popup-form">
                <div className="popup-form-wrap !w-full !max-w-[400px] relative">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <div className='text-center font-semibold mb-5 px-4'>Please enter your password to delete the team</div>
                        <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">  
                            <div className='auth-form-field-wrap mb-5 password-field-wrap relative'>
                                <input 
                                    type={showPassword ? "text" : "password"} disabled={ploading} placeholder="Password" className='password-field' onFocus={()=>{setErrorMessage(false)}}
                                    {...register("password")} 
                                    aria-invalid={errors.password ? "true" : "false"}
                                />
                                <span className={`passwordTrigger bg-no-repeat bg-center bg-contain ${showPassword ? 'toggled' : ''}`} onClick={handleClickShowPassword} id="passwordTrigger"></span>
                            </div>
                            {errors.password && <p className="error mb-2.5" role="alert">{errors.password?.message}</p>}
                            {!ploading && errorMessage ? <p className='w-full error text-center mb-2.5'>You've entered an incorrect password.</p> : null}
                            <div className="w-full px-2.5">
                                <div className='flex justify-center'>
                                {!ploading ?
                                    <input className='button' type="submit" value="Confirm" disabled={ploading}/>
                                    :
                                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
                                }
                                </div>
                            </div>
                    </form>
                </div>
            </div>
        )
    }

    const leaveTeamSubmitNonCreator=async()=>{
        var formdata = new FormData();
            formdata.append("type", "leave_team");
            formdata.append("team_id", team._id);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            var apipoint=process.env.REACT_APP_APIURL + '/app_teams';
            
            await fetch(apipoint , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status===1){
                    window.location.href = '/';
                }                
            })
            .catch(error => console.log('error', error));
    }

    const leaveTeamSubmitCreator=async()=>{
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <LeaveTeamWithNewCreator teamID={team._id} onClose={onClose} token={token} userDetail={userDetail} />
                );
            }
        });
    }

    const leaveTeam = ()=>{
        if(team.createdBy===userDetail.sub){
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                    <div className='confirm-ui'>
                        <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                        <p className="text-center text-[14px] leading-[18px] mb-5">As the team Creator, if you wish to leave the team, you will need to transfer ownership to a new member. Are you sure you want to leave this club?</p>
                        <div className="flex justify-center">
                            <button className="button button-blue mx-1" onClick={onClose}>No</button>
                            <button className="button mx-1"
                            onClick={async() => { 
                                onClose();
                                leaveTeamSubmitCreator();
                            }}
                            >
                            Yes
                            </button>
                        </div>
                    </div>
                    );
                }
            });
        }
        else{
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                    <div className='confirm-ui'>
                        <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                        <p className="text-center text-[14px] leading-[18px] mb-5">Are you sure you want to leave this club?</p>
                        <div className="flex justify-center">
                            <button className="button button-blue mx-1" onClick={onClose}>No</button>
                            <button className="button mx-1"
                            onClick={() => { 
                                leaveTeamSubmitNonCreator();                       
                                onClose();
                            }}
                            >
                            Yes
                            </button>
                        </div>
                    </div>
                    );
                }
            });
        }
    }

    const EditTeam = ({setShowEditForm}) =>{        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowEditForm(false)}}><span>close</span></button>
                    <TeamForm team={team} refreshTeam={refreshTeam} />
                </div>
            </div>
        )
    }

    const ManageNotification = ({setShowNotificationForm}) =>{        
        return(
            <NotificationForm setShowFrom={setShowNotificationForm} teamID = {team._id} />
        )
    }

    const UpdateMember = () =>{        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-full !max-w-[750px]">                    
                    <UpdateDetailForm team={team} refreshTeam={refreshTeam} />
                </div>
            </div>
        )
    }
    
    const TeamHeader = () =>{
        const [showEditForm, setShowEditForm]=useState(false);
        const [showCFGSubscription, setShowCFGSubscription] = useState(false);
        const [showMoreOpt, setShowMoreOpt] = useState(false); 
        const [showMoreFantacyOpt, setShowMoreFantacyOpt] = useState(false);
        const [showReferralCode, setShowReferralCode] = useState(false);
        const [showManageNotification, setShowNotificationForm] = useState(false);
        const isUserAbove18 = userDetail.isAbove18 ? true : (calculate_age(userDetail.dateOfBirth) >= 18 ? true : false);
        const [showQR, setShowQR] = useState(false);
        const [showQRShare, setShowQRShare] = useState(false);
        const navigate = useNavigate();
        const CFGFormSubscription = ({setShowForm}) =>{        
            return(
                <div className="popup-form">
                    <div className="popup-form-wrap !w-full !max-w-[500px] relative">                    
                        <CFGSubscriptionForm setShowForm={setShowForm} teamID={team._id}/>
                    </div>
                </div>
            )
        }

        const ReferralCodePopup = ({setShowForm}) =>{        
            return(
                <div className="popup-form">
                    <div className="popup-form-wrap !w-full !max-w-[300px] relative !p-6">
                        <button type="button" className="close-popup !-top-2.5 !-right-2.5" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                        <p className="mb-5 text-center text-[12px] leading-[14px]" style={{whiteSpace: "pre-wrap"}}>{team.affiliate_program_message}</p>
                        <div className="flex justify-between items-center">                
                            <span className="rounded-lg bg-[#EFF1F9] px-2.5 py-2 text-[16px] leading-[24px] font-semibold flex-1 mr-2.5 relative">
                                {team.referral_code}
                                <img src={copyIcon} alt="" className='absolute right-2.5 top-2 w-[19px] h-[22px] cursor-pointer' onClick={()=>copyToClipboard(team.referral_code)}/>
                            </span>
                            <button type="button" className="button-tiny share rounded-full" onClick={() => { setShowShare(true) }}>Share</button>
                        </div>
                    </div>
                </div>
            )
        }

        const QRCodePopup = ({setShowQR}) =>{            
            return(
                <div className="popup-form">
                    <div className="popup-form-wrap !w-full !max-w-[500px] relative !p-6 !bg-[#363F6C]">
                        <button type="button" className="close-popup !-top-2.5 !-right-2.5" onClick={(e)=>{setShowQR(false)}}><span>close</span></button>
                        <button type="button" className="button-tiny share rounded-full" onClick={() => { setShowQRShare(true) }}>Share</button>
                        <div className="flex flex-col justify-center items-center">
                            <div className="text-[24px] leading-[32px] mb-5 text-[#FBDB09] font-medium">{team.name}</div>
                            <div className="mb-5">
                                <img src={team.qrcode_image} width={200} height={200} alt={team.name} />
                            </div>
                            <div className="flex flex-col">
                                <div className="text-[18px] leading-[24px] text-right mb-2.5">Scan the Code to Join the Team!<br/>Be sure to use your PLAI in-app QR Code scanner.</div>
                                <div className="flex justify-end gap-4">
                                    <img src={InfoIcon} alt="" id="scancode-info"/><img src={plaiSmallLogo} alt="PLAI" width={80} />
                                </div>
                                <Tooltip anchorSelect="#scancode-info">
                                    <div className="w-[280px]">
                                        <h3 className="uppercase text-[22px] leading-[33px] font-semibold border-b border-b-[#FBDB03] border-b-solid text-center">PLAI</h3>
                                        <div className="mb-4 text-left text-[14px] leading-[19px]">
                                        Your PLAI in-app QR Code scanner can be found as follows:<br/><br/>
                                        &gt; HOME SCREEN<br/>
                                        &gt; FIND A CLUB<br/>
                                        &gt; Click the QR SCANNER icon in the TOP RIGHT of screen.
                                        </div>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        const ref = useRef();
        useEffect(()=>{
            const checkIfClickedOutside = e => {
                // If the menu is open and the clicked target is not within the menu,
                // then close the menu
                if ((showMoreOpt || showMoreFantacyOpt) && ref.current && !ref.current.contains(e.target)) {
                    setShowMoreOpt(false);
                    setShowMoreFantacyOpt(false);
                }
            }
            document.addEventListener("mousedown", checkIfClickedOutside);      
            return () => {
                document.removeEventListener("mousedown", checkIfClickedOutside)
            }
        },[showMoreOpt, showMoreFantacyOpt]);
        return(
            <>
                <div className="team-header rounded-xl p-5 mb-[30px]">
                    <div className="flex flex-col sm:flex-row items-center">
                        <div className="image-wrapper rounded-[14px] w-[120px] h-[120px] overflow-hidden relative mb-2.5 sm:mb-0 sm:mr-[18px]">
                        {team.logo && team.logo !== "" ?                                
                            <img src={team.logo} alt={team.name} className="absolute object-cover w-full h-full" />
                            :
                            <img src={placeholderImg} alt={team.name} className="absolute object-cover w-full h-full" />
                        }
                        </div>
                        <div className="team-detail flex-1">
                            <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between w-full">
                                <div className="team-info flex-1 mb-5 text-center sm:text-left lg:mb-0">
                                    <h2 className="flex">
                                        <span className="mr-2.5">{team.name}</span>
                                        <button onClick={()=>{setShowQR(!showQR)}} id="team-qr">
                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M13.9095 14.1667V13.3333H11.4095V10.8333H13.9095V12.5H15.5762V14.1667H14.7428V15.8333H13.0762V17.5H11.4095V15H13.0762V14.1667H13.9095ZM18.0762 17.5H14.7428V15.8333H16.4095V14.1667H18.0762V17.5ZM3.07617 2.5H9.74284V9.16667H3.07617V2.5ZM4.74284 4.16667V7.5H8.07617V4.16667H4.74284ZM11.4095 2.5H18.0762V9.16667H11.4095V2.5ZM13.0762 4.16667V7.5H16.4095V4.16667H13.0762ZM3.07617 10.8333H9.74284V17.5H3.07617V10.8333ZM4.74284 12.5V15.8333H8.07617V12.5H4.74284ZM15.5762 10.8333H18.0762V12.5H15.5762V10.8333ZM5.57617 5H7.24284V6.66667H5.57617V5ZM5.57617 13.3333H7.24284V15H5.57617V13.3333ZM13.9095 5H15.5762V6.66667H13.9095V5Z" fill="#FBDB09"/>
                                                </g>
                                            </svg>
                                        </button>                                        
                                    </h2>
                                    <Tooltip anchorSelect="#team-qr" className="z-10 text-sm">Show QR Code</Tooltip>
                                    {showQR &&
                                        <QRCodePopup setShowQR={setShowQR} />
                                    }
                                    <div className="team-about" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(team.description)}} />                                        
                                    <div className="team-social flex justify-center sm:justify-start">
                                        {team.facebook && team.facebook!=="" && (
                                            <a href={team.facebook} className="icon-facebook" rel="noopener noreferrer" target="_blank">
                                                <span>Facebook</span>
                                            </a>
                                        )}
                                        {team.twitter && team.twitter!=="" && (
                                            <a href={team.twitter} className="icon-twitter" rel="noopener noreferrer" target="_blank">
                                                <span>Twitter</span>
                                            </a>
                                        )}
                                        {team.instagram && team.instagram!=="" && (
                                            <a href={team.instagram} className="icon-instagram" rel="noopener noreferrer" target="_blank">
                                                <span>Instagram</span>
                                            </a>
                                        )}
                                        {team.youtube && team.youtube!=="" && (
                                            <a href={team.youtube} className="icon-youtube" rel="noopener noreferrer" target="_blank">
                                                <span>YouTube</span>
                                            </a>
                                        )}
                                    </div>
                                </div>
                                {team.role === 'admin' ?
                                    <>
                                    <div className="team-cta lg:ml-[20px] max-w-[400px]">
                                        <div className="flex mb-2.5 relative justify-center md:justify-start" ref={ref}>
                                            {team.slug && team.slug!=='' &&
                                                <>
                                                <a id="team-site" href={process.env.REACT_APP_TEAMWEBURL+team.slug} rel="noreferrer" target="_blank" className="rounded-full bg-white w-[44px] h-[44px] flex items-center justify-center mr-4 hover:bg-[#FBDB09]">
                                                    <svg width="24" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.6667 8.49998C12.3083 8.49998 12.8275 7.97498 12.8275 7.33331L12.8333 1.49998C12.8333 0.858313 12.3083 0.333313 11.6667 0.333313H2.33333C1.69167 0.333313 1.16667 0.858313 1.16667 1.49998V7.33331C1.16667 7.97498 1.69167 8.49998 2.33333 8.49998H0.583333C0.2625 8.49998 0 8.76248 0 9.08331C0 9.40415 0.2625 9.66665 0.583333 9.66665H13.4167C13.7375 9.66665 14 9.40415 14 9.08331C14 8.76248 13.7375 8.49998 13.4167 8.49998H11.6667ZM2.91667 1.49998H11.0833C11.4042 1.49998 11.6667 1.76248 11.6667 2.08331V6.74998C11.6667 7.07081 11.4042 7.33331 11.0833 7.33331H2.91667C2.59583 7.33331 2.33333 7.07081 2.33333 6.74998V2.08331C2.33333 1.76248 2.59583 1.49998 2.91667 1.49998Z" fill="#363F6C"/>
                                                    </svg>
                                                </a>
                                                <Tooltip anchorSelect="#team-site" className="z-10 text-sm">Team Site</Tooltip>
                                                </>
                                            }                                            
                                            <NavLink id="team-chat" to={"/team/"+team._id+"/chat"} title="Chat" className="chat-cta relative rounded-full bg-white w-[44px] h-[44px] flex items-center justify-center mr-4 hover:bg-[#FBDB09]">
                                                <svg width="25" height="25" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.76026 11.4797C2.76026 6.65974 6.66762 2.75238 11.4876 2.75238C16.3076 2.75238 20.215 6.65974 20.215 11.4797C20.215 16.2997 16.3076 20.2071 11.4876 20.2071C10.0114 20.2071 8.61881 19.8399 7.39823 19.1916L3.95217 20.1716C3.79201 20.217 3.62263 20.2188 3.46154 20.1768C3.30044 20.1349 3.15346 20.0507 3.03578 19.9329C2.9181 19.8152 2.83398 19.6682 2.79213 19.5071C2.75027 19.3459 2.75218 19.1766 2.79766 19.0164L3.77762 15.571C3.10789 14.3114 2.7585 12.9063 2.76026 11.4797ZM7.74732 9.60959C7.74732 9.77492 7.813 9.93348 7.92991 10.0504C8.04681 10.1673 8.20537 10.233 8.3707 10.233H14.6045C14.7699 10.233 14.9284 10.1673 15.0453 10.0504C15.1622 9.93348 15.2279 9.77492 15.2279 9.60959C15.2279 9.44426 15.1622 9.2857 15.0453 9.16879C14.9284 9.05188 14.7699 8.98621 14.6045 8.98621H8.3707C8.20537 8.98621 8.04681 9.05188 7.92991 9.16879C7.813 9.2857 7.74732 9.44426 7.74732 9.60959ZM8.3707 12.7265C8.20537 12.7265 8.04681 12.7922 7.92991 12.9091C7.813 13.026 7.74732 13.1846 7.74732 13.3499C7.74732 13.5152 7.813 13.6738 7.92991 13.7907C8.04681 13.9076 8.20537 13.9733 8.3707 13.9733H12.111C12.2763 13.9733 12.4349 13.9076 12.5518 13.7907C12.6687 13.6738 12.7344 13.5152 12.7344 13.3499C12.7344 13.1846 12.6687 13.026 12.5518 12.9091C12.4349 12.7922 12.2763 12.7265 12.111 12.7265H8.3707Z" fill="#363F6C"/>
                                                </svg>
                                                {team.unread_chat_msg_count>0 &&
                                                    <span className="rounded-full w-[20px] h-[20px] text-white text-[10px] leading-[10px] bg-[#ff0000] flex items-center justify-center absolute -right-2 -top-2">{team.unread_chat_msg_count}</span>
                                                }
                                            </NavLink> 
                                            <Tooltip anchorSelect="#team-chat" className="z-10 text-sm">Chat</Tooltip>                                           
                                            <div className="mr-4">
                                                <span id="team-referal" className="cursor-pointer text-[#363F6C] w-[44px] h-[44px] hover:bg-[#FBDB09] flex items-center justify-center rounded-full bg-white" onClick={()=>{setShowReferralCode(true)}}>
                                                    <svg width="30" height="30" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M37.9278 29.9522C33.1115 28.8694 28.436 28.034 23.906 26.7132C21.6715 26.0598 19.0709 24.893 19.0005 22.0181C18.93 19.1244 21.465 17.995 23.7041 17.1409C33.2805 13.4866 42.9273 13.4866 52.5036 17.1409C54.7569 17.9997 57.32 19.2225 57.2308 22.0321C57.1416 24.7903 54.5738 26.1018 52.2877 26.7365C47.5887 28.0433 42.763 28.9021 37.9231 29.9568L37.9278 29.9522Z" fill="currentColor"/>
                                                    <path d="M41.6501 42.1847C41.1431 43.3748 40.4108 45.8577 39.1151 48.0093C34.5617 55.5887 28.4591 61.5626 20.0986 64.9416C17.9814 65.7957 15.5827 66.6218 13.7143 64.7783C11.9305 63.0141 12.6252 60.6012 13.4749 58.627C17.7279 48.77 24.8539 41.704 34.8246 37.5456C35.6038 37.2189 36.4722 37.0415 37.3172 36.9342C40.284 36.5422 41.7862 37.919 41.6454 42.1894L41.6501 42.1847Z" fill="currentColor"/>
                                                    <path d="M50.067 47.5282C49.9074 42.2591 44.9596 40.6022 42.5186 36.7379C43.1476 36.4392 43.7719 36.0611 44.4526 35.8278C53.6252 32.6915 62.8073 32.6075 71.9565 35.8791C73.2568 36.3412 74.5148 37.0692 75.6086 37.9093C77.9229 39.6735 78.0356 41.9977 75.7119 43.7199C74.0595 44.9473 72.1255 45.9694 70.1585 46.5762C63.6382 48.583 56.977 48.723 50.0623 47.5236L50.067 47.5282Z" fill="currentColor"/>
                                                    <path d="M43.9502 40.9384C43.9502 40.9384 44.0863 41.013 44.1943 41.1204C48.9449 45.6662 49.3017 47.645 46.0673 53.4696C41.88 61.007 35.9699 66.8829 28.1633 70.6446C22.6005 73.3235 20.69 72.6888 16.897 67.8863C30.5198 63.5039 39.2324 54.319 43.9549 40.9384H43.9502Z" fill="currentColor"/>
                                                    <path d="M77.2378 63.5972C77.7682 70.0845 76.7214 71.802 70.4545 73.6875C61.5964 76.3524 52.7336 76.3104 44.035 72.9968C39.8806 71.4146 38.6178 69.5198 38.9746 66.1874C39.1248 64.8013 39.5661 63.7652 41.3123 64.54C53.4048 69.9071 65.3143 69.3891 77.2378 63.5925V63.5972Z" fill="currentColor"/>
                                                    <path d="M77.3221 54.2164C77.3221 55.9059 77.3738 57.4554 77.308 59.0002C77.2048 61.3945 75.4069 62.6313 73.5432 63.308C70.4215 64.4421 67.1825 65.4782 63.9058 65.9356C57.6765 66.8083 51.4425 66.533 45.4197 64.4048C44.1945 63.9707 43.0303 63.378 41.6455 62.7759C42.9317 61.0958 43.9316 59.4949 45.246 58.2255C45.753 57.7354 47.0393 57.7541 47.8842 57.9361C55.9819 59.6816 63.995 59.607 71.8627 56.858C73.6653 56.228 75.327 55.1965 77.3221 54.2164Z" fill="currentColor"/>
                                                    <path d="M77.1909 44.8637C77.7026 51.981 76.6511 53.5399 70.2668 55.5467C63.1879 57.7729 54.3626 57.8896 47.0442 55.7987C47.9455 53.7032 48.8421 51.617 49.7105 49.6055C54.4753 49.6055 59.2212 49.8202 63.9389 49.5448C68.6379 49.2694 73.1163 47.9393 77.1909 44.859V44.8637Z" fill="currentColor"/>
                                                    <path d="M18.5266 26.1064C31.8349 32.6638 44.472 32.6124 57.109 26.3491V31.6463C54.5506 31.9824 52.1659 32.2717 49.7906 32.6171C48.7907 32.7618 47.772 32.9344 46.8191 33.2658C43.4768 34.4279 40.2565 35.9261 36.5198 35.4547C35.4589 35.3193 34.2572 35.9494 33.1775 36.3741C29.2625 37.9236 22.4463 36.6355 20.0804 33.1491C18.8505 31.3383 19.0195 28.5847 18.5266 26.1064Z" fill="currentColor"/>
                                                    <path d="M18.8692 35.3192C21.9017 36.6587 24.6479 37.8721 27.5677 39.1649C25.7839 40.6164 23.9062 42.1472 21.9862 43.7107C17.4515 42.1192 19.2682 38.3388 18.8692 35.3192Z" fill="currentColor"/>
                                                    </svg>
                                                </span>  
                                                <Tooltip anchorSelect="#team-referal" className="z-10 text-sm">Referral Code</Tooltip>                                               
                                            </div>
                                            {team.is_purchase_fantasy_league && team.is_member_create_fantasy_game && 
                                                <div>
                                                    <button id="team-leadership" type="button" className="leadership-cta rounded-full bg-white w-[44px] h-[44px] flex items-center justify-center mr-4 hover:bg-[#FBDB09]" onClick={(e) => {navigate(`/team/${team._id}/leadershipboard`);}}>
                                                        <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.68213 23.4674C6.68213 21.6456 6.68213 19.8237 6.68213 18.0018C6.68213 16.1199 6.68213 14.238 6.68213 12.3561C6.68213 11.8088 6.92205 11.5614 7.46187 11.5614C9.42622 11.5614 11.3906 11.5614 13.3549 11.5614C13.8872 11.5614 14.1347 11.8088 14.1347 12.3336C14.1347 15.9924 14.1347 19.6512 14.1347 23.3175C14.1347 23.37 14.1347 23.4225 14.1347 23.4674H6.68213ZM9.74861 15.5351C9.74861 16.1949 9.74861 16.8471 9.74861 17.4919C9.74861 17.8668 10.041 18.1517 10.4009 18.1517C10.7608 18.1517 11.0532 17.8743 11.0607 17.4994C11.0607 16.6147 11.0607 15.73 11.0607 14.8453C11.0607 14.4854 10.7833 14.208 10.4234 14.2005C10.191 14.193 9.95854 14.193 9.72612 14.2005C9.44121 14.208 9.21629 14.3955 9.12632 14.6654C8.99886 15.0702 9.23878 15.4076 9.74861 15.5276V15.5351Z" fill="#363F6C"/>
                                                            <path d="M1.02985 23.4674C0.647477 23.3174 0.52002 23.025 0.52002 22.6202C0.527517 20.1085 0.52002 17.5968 0.52002 15.0852C0.52002 14.4629 0.744945 14.238 1.36724 14.238C2.61182 14.238 3.84891 14.238 5.0935 14.238H5.37091V23.4599H1.02985V23.4674Z" fill="#363F6C"/>
                                                            <path d="M15.4548 23.4674C15.4548 20.9258 15.4548 18.3916 15.4548 15.85C15.4548 15.76 15.4548 15.6775 15.4548 15.565H15.7022C16.9768 15.565 18.2438 15.565 19.5184 15.565C20.0432 15.565 20.2982 15.8125 20.2982 16.3448C20.2982 18.4366 20.2982 20.5284 20.2982 22.6277C20.2982 23.0326 20.1707 23.325 19.7883 23.4749H15.4473L15.4548 23.4674Z" fill="#363F6C"/>
                                                            <path d="M10.5434 0.974915C10.7684 1.07988 10.9633 1.20734 11.0233 1.46975C11.1058 1.80714 10.9258 2.14453 10.5884 2.25699C10.2735 2.36195 9.92864 2.212 9.78619 1.90461C9.64374 1.58971 9.77119 1.22983 10.0786 1.05739C10.1311 1.0274 10.1836 1.0049 10.2285 0.974915C10.3335 0.974915 10.431 0.974915 10.5359 0.974915L10.5434 0.974915Z" fill="#363F6C"/>
                                                            <path d="M12.3585 4.70865C12.8684 4.39375 13.3632 4.08635 13.858 3.77896C14.0455 3.659 14.2254 3.59152 14.4278 3.71898C14.6453 3.85393 14.6453 4.06386 14.6078 4.28129C14.3229 6.11818 14.0305 7.94757 13.7456 9.78446C13.6931 10.1144 13.5506 10.2343 13.2132 10.2343C11.3314 10.2343 9.442 10.2343 7.56012 10.2343C7.23023 10.2343 7.08778 10.1069 7.0353 9.77697C6.75039 7.94008 6.45799 6.11068 6.17309 4.27379C6.1431 4.06386 6.1356 3.86143 6.33803 3.72647C6.54796 3.58402 6.7354 3.659 6.92284 3.77896C7.41017 4.08635 7.90501 4.38626 8.40734 4.70115C8.46732 4.64867 8.51981 4.59619 8.57979 4.5437C9.05963 4.06386 9.53947 3.57652 10.0193 3.10418C10.2742 2.84927 10.4991 2.84927 10.7541 3.10418C11.2864 3.6365 11.8187 4.16883 12.351 4.70865H12.3585Z" fill="#363F6C"/>
                                                            <path d="M6.66004 2.72929C6.66004 3.08917 6.36763 3.38908 6.01525 3.39657C5.65537 3.39657 5.35547 3.10417 5.35547 2.74429C5.35547 2.38441 5.64787 2.08451 6.00025 2.07701C6.36014 2.07701 6.66004 2.36941 6.66004 2.72929Z" fill="#363F6C"/>
                                                            <path d="M15.4326 2.7368C15.4326 3.09668 15.1327 3.38908 14.7729 3.38908C14.413 3.38908 14.1206 3.08168 14.1281 2.7218C14.1281 2.36192 14.428 2.06952 14.7879 2.06952C15.1477 2.06952 15.4401 2.37692 15.4326 2.7368Z" fill="#363F6C"/>
                                                        </svg>
                                                    </button>
                                                    <Tooltip anchorSelect="#team-leadership" className="z-10 text-sm">Leadership Board</Tooltip>
                                                </div>
                                            }
                                            {team.is_fantasy_game_available && team.stripeEnabled &&
                                                <div>
                                                    <button id="team-fantasygame" type="button" className="fantacy-cta rounded-full bg-white w-[44px] h-[44px] flex items-center justify-center mr-4 hover:bg-[#FBDB09]" onClick={(e) => {setShowMoreFantacyOpt(!showMoreFantacyOpt);}}>
                                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_2809_17260)">
                                                                <path d="M4.76258 3.91758V1.97714H16.0551V3.91758H19.8193V6.68238C19.8193 9.05853 18.0417 11.0093 15.7502 11.3236C15.422 12.2861 14.8399 13.1421 14.0653 13.801C13.2908 14.46 12.3526 14.8974 11.3499 15.0671V16.0929C11.3499 16.592 11.5482 17.0707 11.9012 17.4237C12.2541 17.7767 12.7328 17.975 13.232 17.975H14.1731V19.857H6.64467V17.975H7.58572C8.08488 17.975 8.5636 17.7767 8.91656 17.4237C9.26952 17.0707 9.46781 16.592 9.46781 16.0929V15.0671C8.46515 14.8974 7.52696 14.46 6.7524 13.801C5.97784 13.1421 5.39569 12.2861 5.06748 11.3236C2.77602 11.0093 0.998386 9.05853 0.998386 6.68238V3.91758H4.76258ZM17.9372 6.68238V5.79968H16.0551V9.34366C16.6052 9.14861 17.0814 8.78809 17.4184 8.3116C17.7554 7.8351 17.9366 7.26599 17.9372 6.68238ZM2.88048 6.68238C2.88119 7.26609 3.06246 7.83528 3.39942 8.31191C3.73637 8.78854 4.21253 9.14926 4.76258 9.3446V5.79968H2.88048V6.68238Z" fill="#363F6C"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_2809_17260">
                                                                    <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20.4087 0.500427)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </button>
                                                    <Tooltip anchorSelect="#team-fantasygame" className="z-10 text-sm">Fantasy Game</Tooltip>
                                                    <ul className={`sort-actions w-[175px] text-[14px] leading-[18px] text-black absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showMoreFantacyOpt ? 'hidden' : ''}`}>
                                                    {team.is_purchase_fantasy_league ?
                                                        <li><div className='cursor-pointer py-1.5' onClick={(e) => {navigate(`/team/${team._id}/joinfantasygame`);}}>Join Fantasy Game</div></li>
                                                        :
                                                        <li><div className='cursor-pointer py-1.5' onClick={() => {setShowCFGSubscription(true);}}>Join Fantasy Game</div></li>
                                                    }
                                                        <li><div className='cursor-pointer py-1.5' onClick={(e)=> {setShowMoreFantacyOpt(false); navigate(`/team/${team._id}/fantasygame`);}}>Manage Fantasy Game</div></li>                                                    
                                                    </ul>
                                                    {showCFGSubscription &&
                                                        <CFGFormSubscription setShowForm={setShowCFGSubscription} />
                                                    }
                                                </div>
                                            }
                                            <div>
                                                <button type="button" className="more-cta rounded-full bg-[#FBDB09] w-[44px] h-[44px] flex items-center justify-center hover:bg-white" onClick={(e)=>{setShowMoreOpt(!showMoreOpt);}}>
                                                    <svg width="6" height="21" viewBox="0 0 6 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.16615 5.19413C4.46227 5.19413 5.51297 4.14342 5.51297 2.84731C5.51297 1.55119 4.46227 0.500488 3.16615 0.500488C1.87004 0.500488 0.819336 1.55119 0.819336 2.84731C0.819336 4.14342 1.87004 5.19413 3.16615 5.19413Z" fill="#363F6C"/>
                                                        <path d="M3.16615 12.8472C4.46227 12.8472 5.51297 11.7965 5.51297 10.5004C5.51297 9.20427 4.46227 8.15356 3.16615 8.15356C1.87004 8.15356 0.819336 9.20427 0.819336 10.5004C0.819336 11.7965 1.87004 12.8472 3.16615 12.8472Z" fill="#363F6C"/>
                                                        <path d="M3.16615 20.5005C4.46227 20.5005 5.51297 19.4498 5.51297 18.1537C5.51297 16.8576 4.46227 15.8069 3.16615 15.8069C1.87004 15.8069 0.819336 16.8576 0.819336 18.1537C0.819336 19.4498 1.87004 20.5005 3.16615 20.5005Z" fill="#363F6C"/>
                                                    </svg>
                                                </button>
                                                <ul className={`sort-actions w-[175px] text-[14px] leading-[18px] text-black absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showMoreOpt ? 'hidden' : ''}`}>
                                                    <li><div className='cursor-pointer py-1.5' onClick={(e) => {setShowMoreOpt(false); setShowMoreFantacyOpt(false); setShowEditForm(true);}}>Edit</div></li>
                                                    {team.subscription_type !== undefined && (team.subscription_type === "add_ads" || team.subscription_type === "remove_ads") &&
                                                        <li><div className='cursor-pointer py-1.5' onClick={(e) => {setShowMoreOpt(false); setShowMoreFantacyOpt(false); setShowSubscription(true); setTabActive('');}}>Manage Subscription</div></li>
                                                    }
                                                    {team.role==="admin" && team.subscription_type !== undefined && team.subscription_type === "add_ads" &&
                                                        <li><div className='cursor-pointer py-1.5' onClick={(e) => {setShowMoreOpt(false); setShowMoreFantacyOpt(false); setShowSubscription(false); setTabActive('manage_ads');}}>Manage Ads</div></li>
                                                    }
                                                    <li><div className='cursor-pointer py-1.5' onClick={(e)=> {setShowMoreOpt(false); setShowMoreFantacyOpt(false); setTabActive('affiliate_dashbaord');}}>Affiliate Dashboard</div></li>
                                                    <li><div className='cursor-pointer py-1.5' onClick={(e) => {setShowMoreOpt(false); setShowMoreFantacyOpt(false); setShowNotificationForm(true);}}>Manage Notifications</div></li>
                                                    <li><div className='cursor-pointer py-1.5' onClick={(e) => {leaveTeam();}}>Leave team</div></li>
                                                    <li><div className='cursor-pointer py-1.5' onClick={(e) => {setShowMoreOpt(false); setShowMoreFantacyOpt(false); setShowDeleteTeamPrompt(true);}}>Delete team</div></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>                                    
                                    </>
                                :
                                <>
                                    <div className="team-cta lg:ml-[20px] flex flex-col sm:flex-row justify-center sm:justify-start max-w-[400px]">
                                        <div className="flex mb-2.5 relative justify-center md:justify-start" ref={ref}>
                                            {team.slug && team.slug!=='' &&
                                                <a href={process.env.REACT_APP_TEAMWEBURL+team.slug} rel="noreferrer" target="_blank" className="rounded-full bg-white w-[44px] h-[44px] flex items-center justify-center mr-4 hover:bg-[#FBDB09]">
                                                    <svg width="24" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.6667 8.49998C12.3083 8.49998 12.8275 7.97498 12.8275 7.33331L12.8333 1.49998C12.8333 0.858313 12.3083 0.333313 11.6667 0.333313H2.33333C1.69167 0.333313 1.16667 0.858313 1.16667 1.49998V7.33331C1.16667 7.97498 1.69167 8.49998 2.33333 8.49998H0.583333C0.2625 8.49998 0 8.76248 0 9.08331C0 9.40415 0.2625 9.66665 0.583333 9.66665H13.4167C13.7375 9.66665 14 9.40415 14 9.08331C14 8.76248 13.7375 8.49998 13.4167 8.49998H11.6667ZM2.91667 1.49998H11.0833C11.4042 1.49998 11.6667 1.76248 11.6667 2.08331V6.74998C11.6667 7.07081 11.4042 7.33331 11.0833 7.33331H2.91667C2.59583 7.33331 2.33333 7.07081 2.33333 6.74998V2.08331C2.33333 1.76248 2.59583 1.49998 2.91667 1.49998Z" fill="#363F6C"/>
                                                    </svg>
                                                </a>
                                            }
                                            {(team.role==='group_admin' || (team.is_show_chat_button && team.is_active_team_chat)) &&
                                                <NavLink to={"/team/"+team._id+"/chat"} title="Chat" className="chat-cta rounded-full relative bg-white w-[44px] h-[44px] flex items-center justify-center mr-4 hover:bg-[#FBDB09]">
                                                    <svg width="25" height="25" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.76026 11.4797C2.76026 6.65974 6.66762 2.75238 11.4876 2.75238C16.3076 2.75238 20.215 6.65974 20.215 11.4797C20.215 16.2997 16.3076 20.2071 11.4876 20.2071C10.0114 20.2071 8.61881 19.8399 7.39823 19.1916L3.95217 20.1716C3.79201 20.217 3.62263 20.2188 3.46154 20.1768C3.30044 20.1349 3.15346 20.0507 3.03578 19.9329C2.9181 19.8152 2.83398 19.6682 2.79213 19.5071C2.75027 19.3459 2.75218 19.1766 2.79766 19.0164L3.77762 15.571C3.10789 14.3114 2.7585 12.9063 2.76026 11.4797ZM7.74732 9.60959C7.74732 9.77492 7.813 9.93348 7.92991 10.0504C8.04681 10.1673 8.20537 10.233 8.3707 10.233H14.6045C14.7699 10.233 14.9284 10.1673 15.0453 10.0504C15.1622 9.93348 15.2279 9.77492 15.2279 9.60959C15.2279 9.44426 15.1622 9.2857 15.0453 9.16879C14.9284 9.05188 14.7699 8.98621 14.6045 8.98621H8.3707C8.20537 8.98621 8.04681 9.05188 7.92991 9.16879C7.813 9.2857 7.74732 9.44426 7.74732 9.60959ZM8.3707 12.7265C8.20537 12.7265 8.04681 12.7922 7.92991 12.9091C7.813 13.026 7.74732 13.1846 7.74732 13.3499C7.74732 13.5152 7.813 13.6738 7.92991 13.7907C8.04681 13.9076 8.20537 13.9733 8.3707 13.9733H12.111C12.2763 13.9733 12.4349 13.9076 12.5518 13.7907C12.6687 13.6738 12.7344 13.5152 12.7344 13.3499C12.7344 13.1846 12.6687 13.026 12.5518 12.9091C12.4349 12.7922 12.2763 12.7265 12.111 12.7265H8.3707Z" fill="#363F6C"/>
                                                    </svg>
                                                    {team.unread_chat_msg_count>0 &&
                                                        <span className="rounded-full w-[20px] h-[20px] text-white text-[10px] leading-[10px] bg-[#ff0000] flex items-center justify-center absolute -right-2 -top-2">{team.unread_chat_msg_count}</span>
                                                    }
                                                </NavLink>
                                            }
                                            {team.is_create_fantasy_game ?
                                                <>
                                                    {team.is_purchase_fantasy_league ?
                                                    <>
                                                        <button type="button" className="fantacy-cta rounded-full bg-white w-[44px] h-[44px] flex items-center justify-center mr-4 hover:bg-[#FBDB09]" onClick={() => {navigate(`/team/${team._id}/joinfantasygame`);}}>
                                                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_2809_17260)">
                                                                    <path d="M4.76258 3.91758V1.97714H16.0551V3.91758H19.8193V6.68238C19.8193 9.05853 18.0417 11.0093 15.7502 11.3236C15.422 12.2861 14.8399 13.1421 14.0653 13.801C13.2908 14.46 12.3526 14.8974 11.3499 15.0671V16.0929C11.3499 16.592 11.5482 17.0707 11.9012 17.4237C12.2541 17.7767 12.7328 17.975 13.232 17.975H14.1731V19.857H6.64467V17.975H7.58572C8.08488 17.975 8.5636 17.7767 8.91656 17.4237C9.26952 17.0707 9.46781 16.592 9.46781 16.0929V15.0671C8.46515 14.8974 7.52696 14.46 6.7524 13.801C5.97784 13.1421 5.39569 12.2861 5.06748 11.3236C2.77602 11.0093 0.998386 9.05853 0.998386 6.68238V3.91758H4.76258ZM17.9372 6.68238V5.79968H16.0551V9.34366C16.6052 9.14861 17.0814 8.78809 17.4184 8.3116C17.7554 7.8351 17.9366 7.26599 17.9372 6.68238ZM2.88048 6.68238C2.88119 7.26609 3.06246 7.83528 3.39942 8.31191C3.73637 8.78854 4.21253 9.14926 4.76258 9.3446V5.79968H2.88048V6.68238Z" fill="#363F6C"/>
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_2809_17260">
                                                                        <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20.4087 0.500427)"/>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </button>
                                                        {team.is_member_create_fantasy_game &&
                                                            <button type="button" className="leadership-cta rounded-full bg-white w-[44px] h-[44px] flex items-center justify-center mr-4 hover:bg-[#FBDB09]" onClick={(e) => {navigate(`/team/${team._id}/leadershipboard`);}}>
                                                                <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M6.68213 23.4674C6.68213 21.6456 6.68213 19.8237 6.68213 18.0018C6.68213 16.1199 6.68213 14.238 6.68213 12.3561C6.68213 11.8088 6.92205 11.5614 7.46187 11.5614C9.42622 11.5614 11.3906 11.5614 13.3549 11.5614C13.8872 11.5614 14.1347 11.8088 14.1347 12.3336C14.1347 15.9924 14.1347 19.6512 14.1347 23.3175C14.1347 23.37 14.1347 23.4225 14.1347 23.4674H6.68213ZM9.74861 15.5351C9.74861 16.1949 9.74861 16.8471 9.74861 17.4919C9.74861 17.8668 10.041 18.1517 10.4009 18.1517C10.7608 18.1517 11.0532 17.8743 11.0607 17.4994C11.0607 16.6147 11.0607 15.73 11.0607 14.8453C11.0607 14.4854 10.7833 14.208 10.4234 14.2005C10.191 14.193 9.95854 14.193 9.72612 14.2005C9.44121 14.208 9.21629 14.3955 9.12632 14.6654C8.99886 15.0702 9.23878 15.4076 9.74861 15.5276V15.5351Z" fill="#363F6C"/>
                                                                    <path d="M1.02985 23.4674C0.647477 23.3174 0.52002 23.025 0.52002 22.6202C0.527517 20.1085 0.52002 17.5968 0.52002 15.0852C0.52002 14.4629 0.744945 14.238 1.36724 14.238C2.61182 14.238 3.84891 14.238 5.0935 14.238H5.37091V23.4599H1.02985V23.4674Z" fill="#363F6C"/>
                                                                    <path d="M15.4548 23.4674C15.4548 20.9258 15.4548 18.3916 15.4548 15.85C15.4548 15.76 15.4548 15.6775 15.4548 15.565H15.7022C16.9768 15.565 18.2438 15.565 19.5184 15.565C20.0432 15.565 20.2982 15.8125 20.2982 16.3448C20.2982 18.4366 20.2982 20.5284 20.2982 22.6277C20.2982 23.0326 20.1707 23.325 19.7883 23.4749H15.4473L15.4548 23.4674Z" fill="#363F6C"/>
                                                                    <path d="M10.5434 0.974915C10.7684 1.07988 10.9633 1.20734 11.0233 1.46975C11.1058 1.80714 10.9258 2.14453 10.5884 2.25699C10.2735 2.36195 9.92864 2.212 9.78619 1.90461C9.64374 1.58971 9.77119 1.22983 10.0786 1.05739C10.1311 1.0274 10.1836 1.0049 10.2285 0.974915C10.3335 0.974915 10.431 0.974915 10.5359 0.974915L10.5434 0.974915Z" fill="#363F6C"/>
                                                                    <path d="M12.3585 4.70865C12.8684 4.39375 13.3632 4.08635 13.858 3.77896C14.0455 3.659 14.2254 3.59152 14.4278 3.71898C14.6453 3.85393 14.6453 4.06386 14.6078 4.28129C14.3229 6.11818 14.0305 7.94757 13.7456 9.78446C13.6931 10.1144 13.5506 10.2343 13.2132 10.2343C11.3314 10.2343 9.442 10.2343 7.56012 10.2343C7.23023 10.2343 7.08778 10.1069 7.0353 9.77697C6.75039 7.94008 6.45799 6.11068 6.17309 4.27379C6.1431 4.06386 6.1356 3.86143 6.33803 3.72647C6.54796 3.58402 6.7354 3.659 6.92284 3.77896C7.41017 4.08635 7.90501 4.38626 8.40734 4.70115C8.46732 4.64867 8.51981 4.59619 8.57979 4.5437C9.05963 4.06386 9.53947 3.57652 10.0193 3.10418C10.2742 2.84927 10.4991 2.84927 10.7541 3.10418C11.2864 3.6365 11.8187 4.16883 12.351 4.70865H12.3585Z" fill="#363F6C"/>
                                                                    <path d="M6.66004 2.72929C6.66004 3.08917 6.36763 3.38908 6.01525 3.39657C5.65537 3.39657 5.35547 3.10417 5.35547 2.74429C5.35547 2.38441 5.64787 2.08451 6.00025 2.07701C6.36014 2.07701 6.66004 2.36941 6.66004 2.72929Z" fill="#363F6C"/>
                                                                    <path d="M15.4326 2.7368C15.4326 3.09668 15.1327 3.38908 14.7729 3.38908C14.413 3.38908 14.1206 3.08168 14.1281 2.7218C14.1281 2.36192 14.428 2.06952 14.7879 2.06952C15.1477 2.06952 15.4401 2.37692 15.4326 2.7368Z" fill="#363F6C"/>
                                                                </svg>
                                                            </button>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                    {(team.is_fantasy_game_available && team.stripeEnabled && team.is_create_fantasy_game) &&
                                                        <>
                                                            <button type="button" className="fantacy-cta rounded-full bg-white w-[44px] h-[44px] flex items-center justify-center mr-4 hover:bg-[#FBDB09]" onClick={() => {setShowCFGSubscription(true);}}>
                                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_2809_17260)">
                                                                        <path d="M4.76258 3.91758V1.97714H16.0551V3.91758H19.8193V6.68238C19.8193 9.05853 18.0417 11.0093 15.7502 11.3236C15.422 12.2861 14.8399 13.1421 14.0653 13.801C13.2908 14.46 12.3526 14.8974 11.3499 15.0671V16.0929C11.3499 16.592 11.5482 17.0707 11.9012 17.4237C12.2541 17.7767 12.7328 17.975 13.232 17.975H14.1731V19.857H6.64467V17.975H7.58572C8.08488 17.975 8.5636 17.7767 8.91656 17.4237C9.26952 17.0707 9.46781 16.592 9.46781 16.0929V15.0671C8.46515 14.8974 7.52696 14.46 6.7524 13.801C5.97784 13.1421 5.39569 12.2861 5.06748 11.3236C2.77602 11.0093 0.998386 9.05853 0.998386 6.68238V3.91758H4.76258ZM17.9372 6.68238V5.79968H16.0551V9.34366C16.6052 9.14861 17.0814 8.78809 17.4184 8.3116C17.7554 7.8351 17.9366 7.26599 17.9372 6.68238ZM2.88048 6.68238C2.88119 7.26609 3.06246 7.83528 3.39942 8.31191C3.73637 8.78854 4.21253 9.14926 4.76258 9.3446V5.79968H2.88048V6.68238Z" fill="#363F6C"/>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_2809_17260">
                                                                            <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20.4087 0.500427)"/>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </button>
                                                            {showCFGSubscription &&
                                                                <CFGFormSubscription setShowForm={setShowCFGSubscription} />
                                                            }
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                    <button type="button" className="rounded-full bg-white w-[44px] h-[44px] flex items-center justify-center mr-4 hover:bg-[#FBDB09]" onClick={() => {setShowNotificationForm(true);}}>
                                                        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.9984 18.2609C11.6525 18.2609 11.3209 18.1289 11.0763 17.8941C10.8318 17.6593 10.6944 17.3408 10.6944 17.0087H13.3023C13.3023 17.3408 13.1649 17.6593 12.9204 17.8941C12.6758 18.1289 12.3442 18.2609 11.9984 18.2609ZM17.2141 16.3826H6.78259V15.1304L8.08653 14.5044V11.0609C8.05219 10.1775 8.25992 9.30103 8.68895 8.51896C8.90009 8.16038 9.18799 7.84887 9.53368 7.60497C9.87937 7.36107 10.275 7.19033 10.6944 7.10401V5.73914H13.0715C12.6585 6.18241 12.4021 6.73985 12.3392 7.33159C12.2763 7.92334 12.41 8.51902 12.7212 9.03332C13.0324 9.54762 13.5051 9.95414 14.0716 10.1946C14.6382 10.4351 15.2694 10.4973 15.875 10.3722C15.8978 10.5957 15.9089 10.8273 15.9089 11.0609V14.5044L17.2128 15.1304V16.3826H17.2141ZM15.2582 9.49566C15.0014 9.49525 14.7471 9.44626 14.51 9.35149C14.2728 9.25672 14.0574 9.11802 13.8761 8.94332C13.6948 8.76861 13.5511 8.56132 13.4532 8.33329C13.3553 8.10525 13.3051 7.86092 13.3056 7.61427C13.306 7.36761 13.357 7.12345 13.4557 6.89573C13.5544 6.668 13.6988 6.46118 13.8807 6.28705C14.0627 6.11293 14.2785 5.97493 14.516 5.88091C14.7534 5.7869 15.0079 5.73873 15.2647 5.73914C15.7835 5.73997 16.2806 5.93865 16.6468 6.29148C17.013 6.64431 17.2182 7.12238 17.2174 7.62053C17.2165 8.11867 17.0096 8.59609 16.6422 8.94774C16.2748 9.2994 15.7769 9.49649 15.2582 9.49566V9.49566Z" fill="#363F6C"/>
                                                        </svg>
                                                    </button>
                                                    <button type="button" className="more-cta rounded-full bg-[#FBDB09] w-[44px] h-[44px] flex items-center justify-center hover:bg-white" onClick={(e)=>{setShowMoreOpt(!showMoreOpt);}}>
                                                        <svg width="6" height="21" viewBox="0 0 6 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.16615 5.19413C4.46227 5.19413 5.51297 4.14342 5.51297 2.84731C5.51297 1.55119 4.46227 0.500488 3.16615 0.500488C1.87004 0.500488 0.819336 1.55119 0.819336 2.84731C0.819336 4.14342 1.87004 5.19413 3.16615 5.19413Z" fill="#363F6C"/>
                                                            <path d="M3.16615 12.8472C4.46227 12.8472 5.51297 11.7965 5.51297 10.5004C5.51297 9.20427 4.46227 8.15356 3.16615 8.15356C1.87004 8.15356 0.819336 9.20427 0.819336 10.5004C0.819336 11.7965 1.87004 12.8472 3.16615 12.8472Z" fill="#363F6C"/>
                                                            <path d="M3.16615 20.5005C4.46227 20.5005 5.51297 19.4498 5.51297 18.1537C5.51297 16.8576 4.46227 15.8069 3.16615 15.8069C1.87004 15.8069 0.819336 16.8576 0.819336 18.1537C0.819336 19.4498 1.87004 20.5005 3.16615 20.5005Z" fill="#363F6C"/>
                                                        </svg>
                                                    </button>
                                                    <ul className={`sort-actions w-[175px] text-[14px] leading-[18px] text-black absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showMoreOpt ? 'hidden' : ''}`}>
                                                        <li><div className='cursor-pointer py-1.5' onClick={(e) => {leaveTeam();}}>Leave team</div></li>                                                
                                                    </ul>
                                                </>
                                                :
                                                <>
                                                    <button type="button" className="rounded-full bg-white w-[44px] h-[44px] flex items-center justify-center mr-4 hover:bg-[#FBDB09]" onClick={() => {setShowNotificationForm(true);}}>
                                                        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.9984 18.2609C11.6525 18.2609 11.3209 18.1289 11.0763 17.8941C10.8318 17.6593 10.6944 17.3408 10.6944 17.0087H13.3023C13.3023 17.3408 13.1649 17.6593 12.9204 17.8941C12.6758 18.1289 12.3442 18.2609 11.9984 18.2609ZM17.2141 16.3826H6.78259V15.1304L8.08653 14.5044V11.0609C8.05219 10.1775 8.25992 9.30103 8.68895 8.51896C8.90009 8.16038 9.18799 7.84887 9.53368 7.60497C9.87937 7.36107 10.275 7.19033 10.6944 7.10401V5.73914H13.0715C12.6585 6.18241 12.4021 6.73985 12.3392 7.33159C12.2763 7.92334 12.41 8.51902 12.7212 9.03332C13.0324 9.54762 13.5051 9.95414 14.0716 10.1946C14.6382 10.4351 15.2694 10.4973 15.875 10.3722C15.8978 10.5957 15.9089 10.8273 15.9089 11.0609V14.5044L17.2128 15.1304V16.3826H17.2141ZM15.2582 9.49566C15.0014 9.49525 14.7471 9.44626 14.51 9.35149C14.2728 9.25672 14.0574 9.11802 13.8761 8.94332C13.6948 8.76861 13.5511 8.56132 13.4532 8.33329C13.3553 8.10525 13.3051 7.86092 13.3056 7.61427C13.306 7.36761 13.357 7.12345 13.4557 6.89573C13.5544 6.668 13.6988 6.46118 13.8807 6.28705C14.0627 6.11293 14.2785 5.97493 14.516 5.88091C14.7534 5.7869 15.0079 5.73873 15.2647 5.73914C15.7835 5.73997 16.2806 5.93865 16.6468 6.29148C17.013 6.64431 17.2182 7.12238 17.2174 7.62053C17.2165 8.11867 17.0096 8.59609 16.6422 8.94774C16.2748 9.2994 15.7769 9.49649 15.2582 9.49566V9.49566Z" fill="#363F6C"/>
                                                        </svg>
                                                    </button>
                                                    <button type="button" className="more-cta rounded-full bg-[#FBDB09] w-[44px] h-[44px] flex items-center justify-center hover:bg-white" onClick={(e)=>{setShowMoreOpt(!showMoreOpt);}}>
                                                        <svg width="6" height="21" viewBox="0 0 6 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.16615 5.19413C4.46227 5.19413 5.51297 4.14342 5.51297 2.84731C5.51297 1.55119 4.46227 0.500488 3.16615 0.500488C1.87004 0.500488 0.819336 1.55119 0.819336 2.84731C0.819336 4.14342 1.87004 5.19413 3.16615 5.19413Z" fill="#363F6C"/>
                                                            <path d="M3.16615 12.8472C4.46227 12.8472 5.51297 11.7965 5.51297 10.5004C5.51297 9.20427 4.46227 8.15356 3.16615 8.15356C1.87004 8.15356 0.819336 9.20427 0.819336 10.5004C0.819336 11.7965 1.87004 12.8472 3.16615 12.8472Z" fill="#363F6C"/>
                                                            <path d="M3.16615 20.5005C4.46227 20.5005 5.51297 19.4498 5.51297 18.1537C5.51297 16.8576 4.46227 15.8069 3.16615 15.8069C1.87004 15.8069 0.819336 16.8576 0.819336 18.1537C0.819336 19.4498 1.87004 20.5005 3.16615 20.5005Z" fill="#363F6C"/>
                                                        </svg>
                                                    </button>
                                                    <ul className={`sort-actions w-[175px] text-[14px] leading-[18px] text-black absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showMoreOpt ? 'hidden' : ''}`}>
                                                        <li><div className='cursor-pointer py-1.5' onClick={(e) => {leaveTeam();}}>Leave team</div></li>                                                
                                                    </ul>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {showEditForm &&
                    <EditTeam setShowEditForm={setShowEditForm} />
                }
                {team.is_need_to_update_team_member_question &&
                    <UpdateMember setShowEditForm={setShowEditForm} />
                }
                {showDeleteTeamPrompt &&
                    <DeletePromptForm setShowForm={setShowDeleteTeamPrompt} />
                }
                {showReferralCode &&
                    <ReferralCodePopup setShowForm={setShowReferralCode} />
                }
                {showShareRefferal &&
                    <ShareReferralCode setShowShare={setShowShare} />
                }
                {showQRShare &&
                    <ShareQRCode setShowpopup={setShowQRShare} />
                }
                {showManageNotification &&
                    <ManageNotification setShowNotificationForm={setShowNotificationForm} />
                }
            </>
        )
    }

    const TabsMenu = ( { tabActive, setTabActive, team, ismobile }) => {
        let tabmenuItems = [
            {
                name: 'Requests',
                slug: 'requests',
                class: tabActive === 'requests' ? 'active' : '',
                hide: team.requested_data  > 0 && team.role==='admin' ? false : true,
            },
            {
                name: 'News',
                slug: 'news',
                class: tabActive === 'news' ? 'active' : '',
                hide: false,
            },
            {
                name: 'Events',
                slug: 'events',
                class: tabActive === 'events' ? 'active' : '',
                hide: false,
            },
            {
                name: 'Duties',
                slug: 'duties',
                class: tabActive === 'duties' ? 'active' : '',
                hide: team.duties_data > 0 ? false : true,
            },
            {
                name: 'Shop',
                slug: 'shop',
                class: tabActive === 'shop' ? 'active' : '',
                hide: false,
            },
            {
                name: 'Discounts',
                slug: 'discounts',
                class: tabActive === 'discounts' ? 'active' : '',
                hide: team.discount_data  > 0 ? false : true,
            },
            {
                name: 'Gallery',
                slug: 'gallery',
                class: tabActive === 'gallery' ? 'active' : '',
                hide: false,
            },
            {
                name: 'Members',
                slug: 'members',
                class: tabActive === 'members' ? 'active' : '',
                hide: false,
            },
            {
                name: 'Forms',
                slug: 'your-forms',
                class: tabActive === 'your-forms' ? 'active' : '',
                hide: (team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0) || (team.role==='member' && team.form_data!==0) ) ? false :true,
            },
            {
                name: 'Links',
                slug: 'links',
                class: tabActive === 'links' ? 'active' : '',
                hide: false,
            },
            {
                name: 'Groups',
                slug: 'groups',
                class: tabActive === 'groups' ? 'active' : '',
                hide: false,
            },            
            /*{
                name: 'Manage Ads',
                slug: 'manage_ads',
                class: tabActive === 'manage_ads' ? 'active' : '',
                hide: team.role==="admin" && team.subscription_type !== undefined && team.subscription_type === "add_ads" ? false : true,
            },*/        
        ]
        return(
            <>
                <ul className="tabs-menu flex">
                    {tabmenuItems.map((el,id) => {
                        return(
                            !el.hide && el.name &&
                            <li key={id} className={el.class} onClick={() => {
                                setTabActive(el.slug);
                                setShowSubscription(false);
                                setGalleryMedia(null);
                            }}>{el.name}
                            </li>
                        )
                    })}                    
                </ul>
            </>
        );
    }
    
    return(
        !teamUpdating ?
        <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                {team.name}                    
            </div>
            <div className="body px-5 lg:px-8 py-10">
                <TeamHeader/>
                <div className="team-contents w-full rounded-xl bg-white">
                    {team.role && (
                        <>
                            <TabsMenu
                                tabActive={tabActive}
                                setTabActive={setTabActive}
                                team={team}                            
                                ismobile={ismobile}
                            />
                            <div className="tabs-contents w-full flex">
                                <div className="tabs-content lg:w-full relative">
                                    {tabActive === 'requests' && <TabRequests />}
                                    {tabActive === 'news' && <TabNews setTabActive={setTabActive} />}
                                    {tabActive === 'drafts' && <DraftNews setTabActive={setTabActive} team={team} token={token} />}
                                    {tabActive === 'events' && <TabEvents />}
                                    {tabActive === 'duties' && <TabDuties />}
                                    {tabActive === 'shop' && <TabShop />}
                                    {tabActive === 'discounts' && <TabDiscounts />}
                                    {tabActive === 'gallery' && <TabGallery galleryMedia={galleryMedia} setGalleryMedia={setGalleryMedia} />}
                                    {tabActive === 'members' && <TabMembers />}
                                    {tabActive === 'links' && <TabLinks />}
                                    {tabActive === 'groups' && <TabGroups />}
                                    {tabActive === 'manage_ads' && <TabAds />}
                                    {tabActive === 'manage_stripe_accounts' && <TabManageStripe />}
                                    {tabActive === 'affiliate_dashbaord' && <AffiliateDashboard />}
                                    {tabActive === 'your-forms' && <AdminForms team={team} token={token} />
                                    }
                                    {showSubscription &&
                                        <TabSubscriptions setShowSubscription={setShowSubscription} />
                                    }
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
        :
        <div className="text-center py-5"><Spinner /></div>        
    )
}

function Team(){
    const { id } = useParams();

    return(
        <>
            <Sidebar type="thin"/>
            <TeamContextProvider id={id}>
                <div id="page" className="page-team">
                    <AdminNavbar heading="Team"/>
                    <TeamOverView/>                    
                </div>
            </TeamContextProvider>
        </>
    )
}

export default Team;