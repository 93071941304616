import { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate, NavLink, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";

import * as DOMPurify from 'dompurify';
import dayjs from "dayjs";
import truncate from '../../../libs/utils';
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Spinner from '../../../components/Spinner/Spinner';
import { TeamContext, TeamContextProvider } from "../../../contexts/TeamContext";
import NewsEditForm from './NewsEditForm';
import placeholderImg from '../../../assets/images/placeholder-white.png';
import Comments from '../../../components/Comments/Comments';
import DocumentView from '../../../components/DocumentView/DocumentView';
import NewsSeenBy from './NewsSeenBy';
import JsFileDownloader from 'js-file-downloader';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
} from "react-share";
import { getNewsDetail } from '../../../libs/dataFunctions';

const NewsArticleView = ({ newsid }) => {
    const [loading, setLoading] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [bookmark, setBookmark] = useState(false);
    const [bookmarkLoading, setBookmarkLoading] = useState(false);
    const [pinning, setPinning] = useState(false);
    const [pinLoading, setPinLoading] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [openSeenBy,setOpenSeenBy] = useState(false);
    const { team, refreshTeam, teamUpdating } = useContext(TeamContext);
    const [showShare, setShowShare] = useState(false);
    const [news, setNews] = useState([]);
    const [showMoreOpt, setShowMoreOpt] = useState(false);
    const navigate = useNavigate();
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';
    
    async function handleBookmarkAction(e) {
        e.preventDefault();
        if (bookmarkLoading) {
            return;
        }
        setBookmarkLoading(true);
        var formdata = new FormData();
        formdata.append("type", "bookmark_news");
        formdata.append("team_id", team._id);
        formdata.append("news_id", news._id);
        formdata.append("bookmark", bookmark ? 'no' : 'yes');
        formdata.append("bookmark_type", 'news');
        var secureHeader = new Headers();
        secureHeader.append('Authorization', 'Bearer ' + token);
        secureHeader.append('device_type', 'W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_news', requestOptions)
            .then(response => setBookmarkLoading(false))
            .catch(error => console.log('error', error));

        setBookmark(!bookmark);
    }

    async function handlePinAction(e) {
        e.preventDefault();
        if (pinLoading) {
            return;
        }
        setPinLoading(true);
        var formdata = new FormData();
        formdata.append("type", "add_pin");
        formdata.append("team_id", team._id);
        formdata.append("news_id", news._id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization', 'Bearer ' + token);
        secureHeader.append('device_type', 'W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_news', requestOptions)
            .then(response => {
                setPinLoading(false);
            })
            .catch(error => console.log('error', error));
        setPinning(!pinning);
    }

    const deleteArticle = async () => {
        if (window.confirm("Are you sure you want to delete this article?")) {
            var formdata = new FormData();
            formdata.append("type", "delete_news");
            formdata.append("news_id", news._id);
            formdata.append("team_id", team._id);

            var secureHeader = new Headers();
            secureHeader.append('Authorization', 'Bearer ' + token);
            secureHeader.append('device_type', 'W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_news', requestOptions)
                .then(response => response.text())
                .then(result => {
                    window.location.href = '/team/' + team._id;
                })
                .catch(error => console.log('error', error));
        }
    }

    const EditNewsForm = ({ setShowForm }) => {
        return (
            <div className="popup-form">
                <div className="popup-form-wrap relative !px-7.5 !w-full">
                    <NewsEditForm news={news} team={team} refreshTeam={refreshTeam} setShowForm={setShowForm} />
                </div>
            </div>
        )
    }
    const ShareNews = ({ setShowShare }) => {
        return (
            <div className="popup-form">
                <div className="popup-form-wrap relative w-full !max-w-[400px]">
                    <button type="button" className="close-popup" onClick={(e) => { setShowShare(false) }}><span>close</span></button>
                    <div className="form-heading text-center mb-[30px]">Share</div>
                    <div className='flex flex-wrap items-center'>
                        <div className='px-2.5 mb-5'>
                            <FacebookShareButton url={process.env.REACT_APP_WEBURL + 'team/' + team._id + '/news/' + news._id} quote={news.title}>
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <LinkedinShareButton url={process.env.REACT_APP_WEBURL + 'team/' + team._id + '/news/' + news._id} title={news.title} summary={news.details_new} source='PLAI Sports'>
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <TwitterShareButton url={process.env.REACT_APP_WEBURL + 'team/' + team._id + '/news/' + news._id} title={news.title}>
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <WhatsappShareButton url={process.env.REACT_APP_WEBURL + 'team/' + team._id + '/news/' + news._id} title={news.title}>
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <EmailShareButton url={process.env.REACT_APP_WEBURL + 'team/' + team._id + '/news/' + news._id} subject={truncate(news.title,500,'...')} body={truncate(news.details_new.replaceAll("&nbsp;", "\n"), 1024, '...')}>
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        setBookmark(news.bookmark === 'yes' ? true : false);
        setPinning(news.pin === 'yes' ? true : false);
    }, [news]);

    const refreshNewsDetail = async () => {
        setLoading(true);
        let newsData = await getNewsDetail(team._id, newsid, token);
        setNews(newsData.data);
        setLoading(false);
    }

    async function deletDocument(docfile) {
        if (window.confirm("Are you sure you want to delete this document?")) {
            var formdata = new FormData();
            formdata.append("type", "delete_document");
            formdata.append("news_id", news._id);
            formdata.append("team_id", team._id);
            formdata.append('document_url', docfile);

            var secureHeader = new Headers();
            secureHeader.append('Authorization', 'Bearer ' + token);
            secureHeader.append('device_type', 'W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_news', requestOptions)
                .then(response => response.text())
                .then(result => {
                    refreshNewsDetail();
                })
                .catch(error => console.log('error', error));
        }
    }

    const downloadPDf=async()=>{
        setDownloadLoading(true);
        var formdata = new FormData();
            formdata.append("type", "get_news_pdf");
            formdata.append("news_id", news._id);
            formdata.append("team_id", team._id);

            var secureHeader = new Headers();
            secureHeader.append('Authorization', 'Bearer ' + token);
            secureHeader.append('device_type', 'W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_news', requestOptions)
                .then(response => response.text())
                .then(result => {
                    const res=JSON.parse(result);
                    if(res.status===1 && res.data.pdf!==undefined && res.data.pdf!==""){
                        new JsFileDownloader({ 
                            url: res.data.pdf,
                        })
                        .then(function () {
                            setDownloadLoading(false);
                        })
                        .catch(function (error) {
                            setDownloadLoading(false);
                            alert(error);
                        });
                    }
                    else{
                        setDownloadLoading(false);
                        alert("PDF not found");
                    }
                })
                .catch(error => console.log('error', error));
    }

    const ref = useRef();
    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (showMoreOpt && ref.current && !ref.current.contains(e.target)) {
                setShowMoreOpt(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[showMoreOpt]);

    useEffect(() => {
        if (!teamUpdating) {
            refreshNewsDetail();
        }
        else {
            setLoading(true);
        }
    }, [teamUpdating]);

    return (
        <>
            {!loading ?
                <>
                    <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                        <NavLink to="/" className='home'>
                            <span>Dashboard</span>
                        </NavLink>
                        <span className='separator'>&lt;</span>
                        <NavLink to={`/team/${team._id}/news`}>
                            News
                        </NavLink>
                        <span className='separator'>&lt;</span>
                        {news.title}
                    </div>
                    <div className="body news-article px-5 lg:px-2.5 lg:px-8 py-10">
                        <div className='article-content shadow-none bg-none lg:px-8 pt-5 pb-8 mb-6'>
                            <div className='flex justify-between'>
                                <div>
                                    {(news?.user?.firstName !== undefined && news?.user?.lastName !== undefined) &&
                                        <div className='publish-by flex flex-col mb-3 order-2 md:order-1'>
                                            <span>created by</span>
                                            {news?.user?.firstName} {news?.user?.lastName}
                                        </div>
                                    }
                                    <div className='article-meta italic text-black mb-8'>
                                        {dayjs(news.date).format("DD MMM, YYYY")}
                                    </div>
                                </div>
                                <div className='flex gap-4'>
                                    <div className='w-[44px]'>
                                        {!downloadLoading ?
                                            <>
                                            <button type="button" id="news-download" onClick={()=>{downloadPDf()}} className='bg-[var(--color-yellow)] rounded-full w-[44px] h-[44px] flex items-center justify-center hover:bg-white'>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z" fill="black" />
                                                </svg>
                                            </button>
                                            <Tooltip anchorSelect={`#news-download`} className="z-10 text-sm">Download PDF</Tooltip>
                                            </>
                                        :
                                            <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={44} height={44} alt="" />
                                        }
                                    </div>
                                    {(team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0)) &&
                                        <div className='article-actions w-100'>
                                            <div className='relative' ref={ref}>
                                                <button type="button" className="more-cta rounded-full bg-[#FBDB09] w-[44px] h-[44px] flex items-center justify-center hover:bg-white" onClick={(e)=>{setShowMoreOpt(!showMoreOpt);}}>
                                                    <svg width="6" height="21" viewBox="0 0 6 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.16615 5.19413C4.46227 5.19413 5.51297 4.14342 5.51297 2.84731C5.51297 1.55119 4.46227 0.500488 3.16615 0.500488C1.87004 0.500488 0.819336 1.55119 0.819336 2.84731C0.819336 4.14342 1.87004 5.19413 3.16615 5.19413Z" fill="#363F6C"/>
                                                        <path d="M3.16615 12.8472C4.46227 12.8472 5.51297 11.7965 5.51297 10.5004C5.51297 9.20427 4.46227 8.15356 3.16615 8.15356C1.87004 8.15356 0.819336 9.20427 0.819336 10.5004C0.819336 11.7965 1.87004 12.8472 3.16615 12.8472Z" fill="#363F6C"/>
                                                        <path d="M3.16615 20.5005C4.46227 20.5005 5.51297 19.4498 5.51297 18.1537C5.51297 16.8576 4.46227 15.8069 3.16615 15.8069C1.87004 15.8069 0.819336 16.8576 0.819336 18.1537C0.819336 19.4498 1.87004 20.5005 3.16615 20.5005Z" fill="#363F6C"/>
                                                    </svg>
                                                </button>
                                                <ul className={`sort-actions w-[175px] text-[14px] leading-[18px] text-black absolute overflow-hidden rounded-lg bg-white top-full right-0 top-[45px] ${!showMoreOpt ? 'hidden' : ''}`}>                                            
                                                    <li><div className='cursor-pointer py-1.5' onClick={(e) => {
                                                        setShowMoreOpt(false); 
                                                        navigate('/team/'+team._id+'/news/duplicate-news',{
                                                            state:{
                                                            news:news,
                                                            }          
                                                        })
                                                    }}>Duplicate</div></li>
                                                    <li><div className='cursor-pointer py-1.5' onClick={(e) => {setOpenForm(true); setShowMoreOpt(false);}}>Edit</div></li>
                                                    {!news.is_draft==="Yes" &&
                                                        <li><div className='cursor-pointer py-1.5' onClick={(e) => {setOpenSeenBy(true); setShowMoreOpt(false);}}>Seen By</div></li>
                                                    }
                                                    <li><div className='cursor-pointer py-1.5' onClick={(e) => { setShowMoreOpt(false); deleteArticle()}}>Delete</div></li>
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {openForm &&
                                    <EditNewsForm setShowForm={setOpenForm} />
                                }
                                {openSeenBy && 
                                    <NewsSeenBy setShowForm={setOpenSeenBy} newsid={newsid} team={team} />
                                }
                            </div>
                            <div className='flex flex-col lg:flex-row lg:items-start'>
                                <div className='article-header w-full mb-5 mr-0.5'>
                                    <div className="newsitem-cta flex order-1 md:order-2 absolute z-10 p-3 right-0">
                                        <button type="button" id='news-pin' className={`button-tiny pin mr-2 rounded-full ${pinning ? 'pinned' : 'dopinned'} ${pinLoading ? 'loading' : ''}`} onClick={(e) => handlePinAction(e)} title={pinning ? 'Unpin' : 'Pin'}>Pin</button>
                                        <Tooltip anchorSelect={`#news-pin`} className="z-10 text-sm">{pinning ? 'Unpin' : 'Pin'}</Tooltip>
                                        <button type="button" id='news-bookmark' className={`button-tiny bookmark mr-2 rounded-full ${bookmark ? 'bookmarked' : 'dobookmark'} ${bookmarkLoading ? 'loading' : ''}`} onClick={(e) => handleBookmarkAction(e)} title={bookmark ? 'Remove Bookmark' : 'Bookmark'}>Bookmark</button>
                                        <Tooltip anchorSelect={`#news-bookmark`} className="z-10 text-sm">{bookmark ? 'Remove Bookmark' : 'Bookmark'}</Tooltip>
                                        <button type="button" id='news-share' className="button-tiny share rounded-full" onClick={() => { setShowShare(true) }}>Share</button>
                                        <Tooltip anchorSelect={`#news-share`} className="z-10 text-sm">Share</Tooltip>
                                    </div>
                                    <div className={`image-wrapper ${news.images ? '' : 'bg-[#363F6C]'} responsive`}>
                                        <img className={`absolute ${news.images ? 'object-cover' : 'object-contain max-w-[90%] max-h-[90%] m-auto left-0 right-0 top-0 bottom-0'} w-full h-full`} src={news.images ? news.images : placeholderImg} alt={news.title} height={430} />
                                        {showShare &&
                                            <ShareNews setShowShare={setShowShare} />
                                        }
                                    </div>
                                    <div className='article-heading w-full flex flex-col md:flex-row items-center justify-between px-2.5 md:px-8 py-6'>
                                        <h1 className='text-white text-center md:text-left'>{news.title}</h1>
                                    </div>
                                </div>
                                <div className='article-text no-border pb-10 w-full pl-7' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(news.details) }} />
                            </div>
                            {(news.users && news.users.length) > 0 &&
                                <div className='article-groups flex flex-wrap items-center pt-10 mb-5'>
                                    <span className='mr-3 mb-4'>Users : </span>
                                    {news.users && news.users.map((user, index) => {
                                        return (
                                            <div className="ml-2 mb-4 font-medium text-[14px] leading-[21px] py-[4px] px-[19px] rounded-[14px] bg-[#3139610d]" key={index}>{user.name}</div>
                                        )
                                    })
                                    }
                                </div>
                            }
                            {(news.groups && news.groups.length) > 0 &&
                                <div className='article-groups flex flex-wrap items-center  mb-5'>
                                    <span className='mr-3 mb-4'>Group You selected : </span>
                                    {news.groups && news.groups.map((group, index) => {
                                        return (
                                            <div className="article-group ml-2 mb-4" key={index}>{group}</div>
                                        )
                                    })
                                    }
                                </div>
                            }
                            {(news.documents !== undefined && news.documents.length > 0) &&
                                <div className='documents'>
                                    <h3>Documents</h3>
                                    <ul className='flex flex-wrap'>
                                        {news.documents.map((doc, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className='mr-5 mb-5 w-[150px] h-[180px] relative'>
                                                        <DocumentView doc={doc} />
                                                        {(news.user_role === 'admin' || news.user_role === 'group_admin') &&
                                                            <button type="button" className='button-tiny delete absolute top-[10px] right-[10px] rounded-full' onClick={() => { deletDocument(doc) }}></button>
                                                        }
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            }
                        </div>
                        <Comments element={news} team={team} type="news" commentList={news.comments ? news.comments : []} commentsEnabled={news.commentsEnabled} refreshData={refreshNewsDetail} />
                    </div>
                </>
                :
                <>
                    <div className="py-5 text-center"><Spinner /></div>
                </>
            }
        </>
    )
}

const NewsArticle = () => {
    const { id, newsid } = useParams();
    return (
        <>
            <Helmet>
                <meta property="al:ios:url" content="applinks:plaisports.page.link/team/439d7002-c28c-4336-825c-1b12857f54fe/news/78de7bc7-495b-4d02-8b44-723b844f51f0" />
                <meta property="al:ios:app_store_id" content="1537033173" />
                <meta property="al:ios:app_name" content="PLAI" />
                <meta property="al:android:url" content="apps://plai.com/team/439d7002-c28c-4336-825c-1b12857f54fe/news/78de7bc7-495b-4d02-8b44-723b844f51f0" />
                <meta property="al:android:app_name" content="PLAI" />
                <meta property="al:android:package" content="com.app.plaisports" />
                <meta property="al:web:url"
                    content="https://login.plaisport.com/team/439d7002-c28c-4336-825c-1b12857f54fe/news/78de7bc7-495b-4d02-8b44-723b844f51f0" />
            </Helmet>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="page-newsdetail">
                    <AdminNavbar heading="News" />
                    <NewsArticleView newsid={newsid} />
                </div>
            </TeamContextProvider>
        </>
    )
}
export default NewsArticle;