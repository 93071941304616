import { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import placeholderImg from '../../../assets/images/placeholder.png';
import ResponsePaymentForm from "./ResponsePaymentForm";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const schema = yup.object({
    note: yup.string().required("Enter Note."),
}).required();

const AttendeeRow = ({attendi, refreshEvent, eventUpdating, team, eventid, userrole}) =>{
    const [loading,setLoading] = useState(false);
    const [delLoading, setDeleteLoading] = useState(false);
    const [showPaidOtherWayForm, setShowPayOtherWayForm] = useState(false);
    const [errorMessage,setErrorMessage] = useState(false);
    const [noteloading,setNoteLoading] = useState(false);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const handleAttendResponse=async(status)=>{
        try {
            setLoading(true);
            var formdata = new FormData();
            formdata.append("type", "set_attendance");
            formdata.append("team_id", team._id);
            formdata.append("event_id", eventid);
            formdata.append("attendee_id", attendi._id);
            formdata.append("is_attendate",status);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                refreshEvent();                
            })
            .catch(error => console.log('error', error));
        } catch (e) {
        }
    }

    const ShowPayOtherWay = ({ setShowForm }) => {
        return (
            <div className="popup-form">
                <div className="popup-form-wrap relative !px-7.5 !w-full !max-w-[560px]">
                    <button type="button" className="close-popup" onClick={(e) => { setShowForm(false) }}><span>close</span></button>
                    <ResponsePaymentForm setShowForm={setShowForm} eventID={eventid} teamID={team._id} responseSub={attendi.sub} refreshEvent={refreshEvent} />
                </div>
            </div>
        )
    }

    const deleteAttendiAction=async()=>{        
        setDeleteLoading(true);
        var formdata = new FormData();
        formdata.append("type", "delete_manually_attendance");
        formdata.append("event_id",eventid);
        formdata.append("attendance_id", attendi._id);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setDeleteLoading(false);
            refreshEvent();         
        })
        .catch(error => console.log('error', error));
    }

    const deleteAttendi = () =>{
        confirmAlert({
          customUI: ({ onClose }) => {
              return (
                <div className='confirm-ui'>
                    <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                    <p className="text-center text-[14px] leading-[18px] mb-5">Are you sure to remove <strong>{attendi.name}</strong> from Attendance</p>
                    <div className="flex justify-center">
                        <button type="submit" className="button"
                            onClick={() => {
                                deleteAttendiAction();
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                        <button type="button" className="button button-blue mx-1" onClick={onClose}>No</button>
                    </div>                   
                </div>
              );
          }
      });
      }

    const onSubmit = async(data) => {
        setErrorMessage(false);
        setNoteLoading(true);
        var formdata = new FormData();
            formdata.append("type", "set_attendance_note");
            formdata.append("team_id", team._id);
            formdata.append("event_id", eventid);
            formdata.append("req_id", attendi.req_id);            
            formdata.append("note",data.note);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
            .then(rresponse => rresponse.text())
            .then(result => {
                setNoteLoading(false);
                refreshEvent();
            })
            .catch(error => console.log('error', error));
    }

    useEffect(()=>{
        setValue("note",attendi.note);
    },[]);

    return(
    <>
        <div className='attendence-row mb-2.5 flex items-center justify-between pl-[14px] pr-4 md:pr-[30px] py-[15px] w-full'>
            <div className='flex items-center flex-1'>
                <div className="self-start">
                    {attendi.profilePicture!=="" ?
                    <img className='rounded-full mr-2.5' src={attendi.profilePicture} width="40px" height="40px" alt={attendi.name} />
                    :
                    <img className='rounded-full mr-2.5' src={placeholderImg} width="40px" height="40px" alt={attendi.name} />                                                    
                    }
                </div>
                <div className="flex-1">
                    <div className="attendi-name mb-1.5">{attendi.name}</div>
                    <div className="attendi-response mb-2">{!attendi.is_manual_attendance ? "Responded on PLAI" : "Manually Added"}</div>                    
                    {(attendi.is_paid!=="" && (userrole==='admin' || userrole==='group_admin') ) &&
                    <>
                        <div className="flex justify-start mb-2">
                            <div><span className={`rounded-full px-[18px] py-[4px] bg-[#fdbcbc] text-[#f01515] text-[10px] leading-[15px] border border-[#f01515]`}>Not Paid</span></div>
                            <div className='ml-2.5'>
                                <button type="button" className="response-button accept-blue" onClick={()=>{setShowPayOtherWayForm(true)}}>Paid Another Way</button>
                            </div>
                        </div>
                    </>
                    }
                    {(userrole==='admin' || userrole==='group_admin') &&
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="w-full max-w-[400px]">
                                <div className="mb-2">
                                    <textarea className="bg-white focus:bg-white text-[14px] w-full !min-h-0 !border-[1px] !border-solid !border-[#363F6C]" rows="2" placeholder="Write notes for attendee" 
                                        {...register("note")}
                                        disabled={noteloading}
                                        aria-invalid={errors.note ? "true" : "false"}
                                        ></textarea>
                                </div>
                                {errors.note?.message && <p className="text-red-600 text-[12px] leading-[14px] mb-2.5" role="alert">{errors.note?.message}</p> }                                
                                <div>
                                {!noteloading ?
                                    <>
                                    <button type="submit" className="button button-medium">Submit</button>                                    
                                    </>
                                    :
                                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
                                }
                                </div>                                
                            </div>
                        </form>
                    }
                </div>
            </div>            
            <div className='attendence-status self-start'>
                {((loading || eventUpdating)) ?
                <div className='flex'><button type="button" className='response-button loading'></button></div>
                :
                <>
                {userrole==='admin' || userrole==='group_admin' ? 
                    <>
                    <div className="flex gap-4 items-center">
                        <span className={attendi.attended ? 'attended block relative cursor-pointer' : 'notattended block relative cursor-pointer'} onClick={()=>{handleAttendResponse(attendi.attended ? 'No' : 'Yes')}}>
                        {attendi.attended ? 
                            'Attended' : 
                            'Did not attend'
                        }</span>
                        {attendi.is_manual_attendance &&
                            <>
                            {!delLoading ?
                                <button type="button" className="button-tiny delete rounded-full !bg-[var(--color-yellow)]" onClick={()=>{deleteAttendi()}}>Delete</button>
                            :
                                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
                            }
                            </>
                        }
                    </div>
                    </>
                    :
                    <>
                    <span className={attendi.attended ? 'attended block relative' : 'notattended block relative'}>
                        {attendi.attended ? 
                            'Attended' : 
                            'Did not attend'
                        }</span>
                    </>
                }
                </>
                }
            </div>            
        </div>
        {showPaidOtherWayForm &&
            <ShowPayOtherWay setShowForm={setShowPayOtherWayForm} />
        }
    </>
    );
}

export default AttendeeRow;