import { useEffect, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import Select, { components } from "react-select";
import { yupResolver } from '@hookform/resolvers/yup';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import * as yup from 'yup';
import { getSports, getTimeZones, getPlayTeams } from "../../libs/dataFunctions";
import Spinner from "../Spinner/Spinner";
import TextEditor from "../TextEditor";
import CountriesList from "../../libs/countries";
import PlaiImageCropper from "../ImageCropper/ImageCropper";
import { useAuthState } from "../../contexts/AuthContext";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { generateRandomName } from "../ImageCropper/cropUtils";
import DownArrowSvg from "../../assets/images/icon-down.svg";
import InfoIcon from '../../assets/images/info-white.svg';

registerPlugin(FilePondPluginFileValidateType);

const schemaNew = yup.object({
    team_name: yup.string().required('Team name is required.'),
    email: yup.string().email().required('Email address is required.'),
    sport: yup.string().required('Please select sport.'),
    country: yup.string().required('Please select Country.'),
    timezone: yup.string().required('Please select your timezone.'),
    referral_code: yup.string()
    .when('is_referral_code',{
        is:'Yes',
        then:yup.string().required('Enter referral code.')
    }).nullable(),
}).required();

const schemaUpdate = yup.object({
    team_name: yup.string().required('Team name is required.'),
    email: yup.string().email().required('Email address is required.'),
    sport: yup.string().required('Please select sport.'),
    country: yup.string().required('Please select Country.'),
    timezone: yup.string().required('Please select your timezone.'),
}).required();

const TeamForm = ({team, refreshTeam}) =>{ 
    const [sportsList,setSportsList] = useState([]);
    const [timezoneList,setTimezeonList] = useState([]);
    const [loading,setLoading] = useState(false);
    const [aboutEditor, setAboutEditor]= useState("");
    const [errorMessage,setErrorMessage] = useState(false);
    const [image, setTeamImage] = useState(null);
    const [locationValue,setLocationValue] = useState(null);
    const [memberQuestions, setMemberQuestions] = useState([]);
    const [memberQuestionId, setMemberQuestionId] = useState(1);
    const [memberQuestion, setMemberQuestion] = useState('');
    const [memberQueRequired, setMemberQueRequired] = useState('No');
    const [latlong,setLatLong] = useState([]);
    const { timezone } = Intl.DateTimeFormat().resolvedOptions();
    const [isReferralCode,setIsReferralcode] = useState(false);
    const [isReferralCodeValid, setIsReferralcodeValid] = useState(false);
    const [referralCodeValidMsg, setReferralCodeValidMsg] = useState(null);
    const [imageFiles,setImageFile] = useState([]);
    const [teamSlug, setTeamSlug] = useState(null);
    const [uploadedWebImages, setUploadedWebImags] = useState([]);
    const [playCrickeTeams, setPlayCricketTeams] = useState([]);
    const [selectedPlayTeam, setSelectedPlayTeam] = useState(null);
    const [selectedPlayTeamOut, setSelectedPlayTeamOut] = useState([]);
    const [timezoneLoding, setTimeZoneLoading] = useState(false);

    const isAddMode = !team;    
    const { register, handleSubmit, watch, control, setValue,formState: { errors } } = useForm({
        resolver: yupResolver(isAddMode ? schemaNew : schemaUpdate)
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const userDetails = useAuthState();
    
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
            resolve(base64String);
        }
        reader.onerror = error => reject(error);
    });

    function handleSelectChange(current_val) {
        setSelectedPlayTeam(current_val.value);
        setSelectedPlayTeamOut(current_val);        
    }

    
    const createNewTeam = async(data) =>{
        var formdata = new FormData();
        formdata.append("type", "create_team");
        formdata.append("code", "");
        formdata.append("description", aboutEditor);
        formdata.append("email", data.email);
        formdata.append("name", data.team_name);
        formdata.append("sport", data.sport);
        formdata.append("facebook", data.fb_url);
        formdata.append("youtube", data.youtube_url);
        formdata.append("twitter", data.tw_url);
        formdata.append("instagram", data.insta_url);
        formdata.append("country", data.country);
        if(selectedPlayTeam!==undefined && selectedPlayTeam!==null){
            formdata.append("site_id", selectedPlayTeam);
        }
        if(locationValue!==null && locationValue.label!==undefined){
            formdata.append('location',locationValue.label);
        }
        
        if(latlong.lng !== undefined){
            formdata.append('longitude',latlong.lng);
            formdata.append('latitude',latlong.lat);
        }
        formdata.append('timezone',data.timezone);
        if(data.is_referral_code==='Yes' && data.referral_code!==""){
            formdata.append("referral_code",data.referral_code);
        }
        if(image!==null){
            /*let imageBs64 = image.replace('data:', '').replace(/^.+,/, '');
            let extension = image.split(';')[0].split('/')[1];

            formdata.append("content",imageBs64);
            formdata.append("extension",extension);*/

            var formdatas3 = new FormData();
            let tmpFilename = generateRandomName();
            const imgFileObj = new File([image], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: image.type })
            formdatas3.append("files[]", imgFileObj);
            var requestOptionss3 = {
                method: 'PUT',
                body: formdatas3,
                redirect: 'follow'
            };
            const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
            const resultsApp = await responsesApp.text();
            const rdatasApp = JSON.parse(resultsApp);
            formdata.append('content',rdatasApp.data[0]);
        }
        if(imageFiles.length>0){
            let webimageCodes = [];
            let exts = [];
            for(let i=0; i<imageFiles.length; i++){
                webimageCodes[i] = await toBase64(imageFiles[i].file);
                exts[i] = imageFiles[i].fileExtension;
            };
            formdata.append("image_content", webimageCodes.join('@#'));
            formdata.append("image_extension", exts.join('@#'));            
        }
        if(memberQuestions.length>0){
            var questions=[];
            var requireds=[]
            memberQuestions.forEach(element => {
                questions.push(element.question);
                requireds.push(element.required);
            });

            formdata.append('custom_fields_name',questions.join('@#'));
            formdata.append('custom_fields_required',requireds.join('@#'));
        }
        formdata.append('separate_by','@#');
        formdata.append('approval_to_join',data.approval_to_join);
        setLoading(true);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        
        await fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                window.location.href = '/team/'+rdata.data._id;
            }
        })
        .catch(error => console.log('error', error));
        setLoading(false);
    } 

    const UpdateTeam = async(data)=>{
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "update_team");
        formdata.append("team_id", team._id);
        formdata.append("code", ""); //agent code removed
        formdata.append("description", aboutEditor);
        formdata.append("email", data.email);
        formdata.append("name", data.team_name);
        formdata.append("sport", data.sport);
        formdata.append("facebook", data.fb_url);
        formdata.append("youtube", data.youtube_url);
        formdata.append("twitter", data.tw_url);
        formdata.append("instagram", data.insta_url);
        formdata.append("country", data.country);
        if(selectedPlayTeam!==undefined && selectedPlayTeam!==null){
            formdata.append("site_id", selectedPlayTeam);
        }
        if(locationValue!==null && locationValue.label!==undefined){
            formdata.append('location',locationValue.label);
        }
        if(latlong.lng !== undefined){
            formdata.append('longitude',latlong.lng);
            formdata.append('latitude',latlong.lat);
        }
        formdata.append('timezone',data.timezone);

        if(image!==null){
            /*let imageBs64 = image.replace('data:', '').replace(/^.+,/, '');
            let extension = image.split(';')[0].split('/')[1];

            formdata.append("content",imageBs64);
            formdata.append("extension",extension);*/
            
            var formdatas3 = new FormData();
            let tmpFilename = generateRandomName();
            const imgFileObj = new File([image], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: image.type })
            formdatas3.append("files[]", imgFileObj);
            var requestOptionss3 = {
                method: 'PUT',
                body: formdatas3,
                redirect: 'follow'
            };
            const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
            const resultsApp = await responsesApp.text();
            const rdatasApp = JSON.parse(resultsApp);
            formdata.append('content',rdatasApp.data[0]);
        }

        if (imageFiles.length > 0 || uploadedWebImages.length>0) {
            let web_images = [];
            let exts = [];
            if(uploadedWebImages.length>0){
                for (let i = 0; i < uploadedWebImages.length; i++) {
                    exts.push(uploadedWebImages[i].split('.').pop());
                }
            }
            if(imageFiles.length > 0){
                for (let i = 0; i < imageFiles.length; i++) {
                    web_images[i] = await toBase64(imageFiles[i].file);
                    uploadedWebImages.push(web_images[i]);
                    exts.push(imageFiles[i].fileExtension);
                }
            }
            formdata.append("image_content", uploadedWebImages.join('@#'));
            formdata.append("image_extension", exts.join('@#'));
        }
        if(memberQuestions.length>0){
            var questions=[];
            var requireds=[]
            memberQuestions.forEach(element => {
                questions.push(element.question);
                requireds.push(element.required);
            });

            formdata.append('custom_fields_name',questions.join('@#'));
            formdata.append('custom_fields_required',requireds.join('@#'));            
        }

        formdata.append('separate_by','@#');
        formdata.append('approval_to_join',data.approval_to_join);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                setLoading(false);
                refreshTeam();                    
            }
        })
        .catch(error => console.log('error', error));
        setLoading(false);
    }
    const onSubmit = (data) => {
        if(isAddMode){
            createNewTeam(data);
        }
        else{
            UpdateTeam(data);
        }
    }

    function searchInCF(nameKey, cfArray){
        for (let i=0; i < cfArray.length; i++) {
            if (cfArray[i].id === nameKey) {
                return cfArray[i];
            }
        }
        return false;
    }

    const addCustomFields = ()=>{
        var cf = [...memberQuestions];
        if(memberQuestion && memberQueRequired){
            if(!searchInCF(memberQuestion, memberQuestions)){
                cf.push({id:memberQuestionId, question:memberQuestion, required:memberQueRequired});            
                setMemberQuestions(cf);
                setMemberQuestionId(memberQuestionId+1);
            }
            setMemberQuestion('');
            setMemberQueRequired('No');            
        }        
    }
    const editCustomFields = (indx,fieldname,fieldvalue)=>{
        var cf = [...memberQuestions];
        for (var i = 0; i < cf.length; i++) {
          if (cf[i].id === indx) {
            if(fieldname==='question'){
                cf[i].question = fieldvalue
            }
            else if(fieldname==='required'){
                cf[i].required = fieldvalue
            }
          }
        }
        setMemberQuestions(cf);
    }
    const deleteCustomField = (index)=>{
        var cf = [...memberQuestions];
        cf.splice(index, 1);
        setMemberQuestions(cf);
    }

    function removeImage(index) {
        if (window.confirm("Are you sure you want to delete this image?")) {
            setUploadedWebImags([
                ...uploadedWebImages.slice(0, index),
                ...uploadedWebImages.slice(index + 1)
            ]);            
        }
    }

    const onCountryChange=async(country)=>{
        setTimeZoneLoading(true);
        const [data_timezones] = await Promise.all([
            getTimeZones(token, country)
        ]);
        setTimezeonList(data_timezones);
        setTimeZoneLoading(false);
    }

    useEffect(() => {
        async function load() {
            setLoading(true);            
            const [data_sports, playTeamsData] = await Promise.all([
                getSports(),
                getPlayTeams(token)
            ]);            
            setSportsList(data_sports);            
            setLoading(false);

            const playTeamArr=[];
            playTeamsData.data.forEach(pt=>{
                playTeamArr.push({value:pt.id, label:pt.name})
            })

            setPlayCricketTeams(playTeamArr);
            
            if(!isAddMode){
                var cf = [];
                if(team.customFields!==undefined && team.customFields.length > 0){
                    team.customFields.forEach(customField=>{
                        cf.push({id:customField._id, question:customField.name, required:customField.required ? 'Yes': 'No'}); 
                    })
                    setMemberQuestions(cf);
                }
            }

            if(!isAddMode){
                setValue('team_name', team.name);
                setValue('email', team.email);         
                if(team.sport!==undefined && team.sport.name!==undefined){                
                    setValue('sport',team.sport.name);
                }
                setValue('approval_to_join',team.approval_to_join);
                setValue('country',team.country);
                onCountryChange(team.country);
                setValue('fb_url', team.facebook);
                setValue('tw_url', team.twitter);
                setValue('insta_url', team.instagram);
                setValue('youtube_url', team.youtube);
    
                if(team.site_id!=="" && team.site_id!==null){
                    setValue('site_id', team.site_id);
                    setSelectedPlayTeam(team.site_id);
    
                    let eOptions = playTeamArr.filter(element=>element.value.toString()===team.site_id.toString());
                    setSelectedPlayTeamOut(eOptions);                
                }
                
                setValue('timezone',team.timezone !=="" ? team.timezone : timezone);
                setAboutEditor(team.description);
                setTeamSlug(team.slug);
                if(team.images!==undefined){
                    setUploadedWebImags(team.images);
                }
            }
            else{
                setValue('approval_to_join','manually');
                setValue('is_referral_code','No');
                setValue('timezone',timezone);
                setValue('email', userDetails?.user?.user);
                setValue('fb_url', 'https://www.facebook.com/');
                setValue('tw_url', 'https://www.twitter.com/');
                setValue('insta_url', 'https://www.instagram.com/');
                setValue('youtube_url', 'https://www.youtube.com/');
            }
        }
        load();
    },[]);

    const checkReferralCode=async(e)=>{
        if(e.target.value!==""){
            var formdata = new FormData();
            formdata.append("type", "check_referral_code");
            formdata.append("referral_code",e.target.value);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            secureHeader.append('access-control-allow-origin','*');
            
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    setValue("referral_code","");
                    setReferralCodeValidMsg(rdata.message);
                }
                else{                    
                    setReferralCodeValidMsg(null);
                }
            })
            .catch(error => console.log('error', error));
        }
    }

    useEffect(()=>{
        if(locationValue!==null){
            geocodeByAddress(locationValue.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) =>{
                    setLatLong({ lat, lng });
                }
            );
        }        
    },[locationValue]);
    
    const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <img src={DownArrowSvg} width={24} height={24} alt="" />
          </components.DropdownIndicator>
        );
    };

    const handleChange = (e, key) => {
        const { value } = e.target        
        setAboutEditor(value);
    }

    const watchCountryUk = watch("country");
    const watchSportCricket = watch("sport");
    
    return(
        !loading ?
        <>
            <div className={!isAddMode ? '' : 'body px-5 md:px-8 py-10'}>
                <div className='rounded-xl bg-[#363F6C] py-7.5 px-5'>
                    {!isAddMode &&
                        <div className="form-heading text-center mb-[30px] !text-white">Edit {team.name}</div>
                    }
                    
                    <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper flex flex-wrap">
                        <div className="w-full md:w-1/2 xl:w-1/3 md:px-2.5">
                            <div className="form-field-container">
                                <div className="label">Team Name*</div>
                                <div className='form-field-wrap mb-5 user-field-wrap relative'>
                                    <input 
                                        type="text" id="team-name" disabled={loading} placeholder="Team Name*" className='text-field'
                                        {...register("team_name")}
                                        aria-invalid={errors.team_name ? "true" : "false"}
                                        onChange={(e)=>{
                                            var a = e.target.value;                                        
                                            var b = a.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
                                            setTeamSlug(b);
                                        }}
                                    />                                
                                </div>
                                {errors.team_name && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.team_name?.message}</p>}
                            </div>
                            <div className="form-field-container">
                                <div className="label">Primary Email*</div>
                                <div className='form-field-wrap mb-5 email-field-wrap relative'>
                                    <input 
                                        type="email" id="primary-mail" disabled={loading} placeholder="Primary Email*" className='email-field'
                                        {...register("email")}
                                        aria-invalid={errors.email ? "true" : "false"} 
                                    />                                
                                </div>
                                {errors.email && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.email?.message}</p>}
                            </div>
                            <div className="form-field-container">
                                <div className="label">Select Sport*</div>                                                       
                                <div className='form-field-wrap mb-5 select-field-wrap sport-field-wrap relative'>
                                    <select {...register("sport")} onChange={(e)=>setValue('sport', e.target.value, { shouldValidate: true })}>
                                        <option value="">Select Sport*</option>
                                        {sportsList.map((sportItem,index)=>{
                                            return(
                                                <option key={index} value={sportItem.name}>{sportItem.name}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {errors.sport && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.sport?.message}</p>}
                            </div>
                            <div className="form-field-container">
                                <div className="label">Facebook Url</div>
                                <div className='form-field-wrap mb-5 facebook-field-wrap relative'>
                                    <input 
                                        type="url" id="facebook-url" disabled={loading} placeholder="https://www.facebook.com/" className='text-field'
                                        {...register("fb_url")}
                                    />                                
                                </div>
                            </div>
                            <div className="form-field-container">
                                <div className="label">Instagram Url</div>
                                <div className='form-field-wrap mb-5 instagram-field-wrap relative'>
                                    <input 
                                        type="url" id="instagram-url" disabled={loading} placeholder="https://www.instagram.com/" className='text-field'
                                        {...register("insta_url")}
                                    />                                
                                </div>
                            </div>                            
                        </div>
                        <div className="w-full md:w-1/2 xl:w-1/3 md:px-2.5">
                            <div className="form-field-container">
                                <div className="label">Select Country*</div>
                                <div className='form-field-wrap mb-5 select-field-wrap country-field-wrap relative'>
                                    <select {...register("country")} onChange={(e)=>{
                                            setValue('country', e.target.value, { shouldValidate: true })
                                            onCountryChange(e.target.value);
                                        }}>
                                        <option value="">Select Country*</option>
                                        {CountriesList.map((c,index)=>{
                                            return(
                                                <option key={index} value={c.code}>{c.name}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {errors.country && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.country?.message}</p>}
                            </div>
                            <div className="form-field-container">                                
                                <div className="label">Select Timezone*</div>
                                {!timezoneLoding ?
                                <>
                                <div className='form-field-wrap mb-5 select-field-wrap time-field-wrap relative'>
                                    <select {...register("timezone")} onChange={(e)=>setValue('timezone', e.target.value, { shouldValidate: true })}>
                                        <option value="">Select Timezone*</option>
                                        {timezoneList.map((tzone,index)=>{
                                            return(
                                                <option key={index} value={tzone.tzCode}>{tzone.label}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {errors.timezone && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.timezone?.message}</p>}
                                </>
                                :
                                <p className="text-white px-5">Loading timezone...</p>
                                }
                            </div>
                            <div className="form-field-container">
                                <div className="label">Club's Address</div>
                                <div className='form-field-wrap mb-5 select-field-wrap location-field-wrap relative z-[1]'>
                                    <GooglePlacesAutocomplete
                                        apiKey={process.env.REACT_APP_GAPIKEY}
                                        placeholder="Start typing your club's address..."
                                        selectProps={{
                                            //locationValue,                                        
                                            onChange: setLocationValue,
                                            //components: { DropdownIndicator },
                                            styles: {
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderWidth: 0,
                                                    backgroundColor: '#EFF1F9',
                                                    padding: '6px 7px 6px 46px',
                                                    color: '#000000',
                                                }),
                                                option: (baseStyles) => ({
                                                    ...baseStyles,
                                                    backgroundColor: '#ffffff',
                                                    color: '#000000',
                                                    zIndex: 10000
                                                }),
                                                menu: (baseStyles) => ({
                                                    ...baseStyles,
                                                    zIndex:1000,
                                                    backgroundColor: '#ffffff',
                                                    color: '#000000',
                                                }),
                                            },
                                            theme:(theme) => ({
                                                ...theme,
                                                borderRadius: 8,                                                
                                            }),
                                        }}                                    
                                    />
                                    {(!isAddMode && team.location!=="") && <p className="text-[10px] text-white">Previously: {team.location}</p>}                                
                                </div>
                            </div>
                            <div className="form-field-container">
                                <div className="label">Twitter Url</div>
                                <div className='form-field-wrap mb-5 twitter-field-wrap relative'>
                                    <input 
                                        type="url" id="twitter-url" disabled={loading} placeholder="https://www.twitter.com/" className='text-field'
                                        {...register("tw_url")}
                                    />                                
                                </div>
                            </div>
                            <div className="form-field-container">
                                <div className="label">YouTube Url</div>
                                <div className='form-field-wrap mb-5 youtube-field-wrap relative'>
                                    <input 
                                        type="url" id="youtube-url" disabled={loading} placeholder="https://www.youtube.com/" className='text-field'
                                        {...register("youtube_url")}
                                    />                                
                                </div>
                            </div>
                            {/*
                            <div className="form-field-container">
                                <div className="label">Website Url</div>
                                <div className='form-field-wrap mb-5 website-field-wrap relative'>
                                    <input 
                                        type="url" id="website-url" disabled={loading} placeholder="https://www.plaisport.com/" className='text-field'
                                        {...register("website_url")}
                                    />                                
                                </div>
                            </div>
                            */}
                        </div>
                        <div className="w-full xl:w-1/3 xl:px-2.5">
                            <div className='form-field-wrap mb-5 relative'>
                                <PlaiImageCropper default_label={'Upload a logo'} default_message={'Maximum upload file size: 2MB<br/>(1000 X 1000)'} editImage={(!isAddMode && team.logo && team.logo !== "") ? team.logo : ''} onFinalChange={(newImage)=>{setTeamImage(newImage)}} aspect={1 / 1}/>
                            </div>
                            {(watchCountryUk==='GB' && watchSportCricket==='Cricket') &&
                                <div className="form-field-container">
                                    <div className="label">Play-Cricket Team Name</div>
                                    <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                        <Controller
                                        control={control}
                                        name='site_id'
                                        render={({ field: { onChange, value, ref }}) => (
                                            <Select
                                                inputRef={ref}
                                                value={selectedPlayTeamOut}
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                components={{ DropdownIndicator }}
                                                placeholder={<div>Select Team</div>}
                                                onChange={val => {
                                                    onChange(val.value);
                                                    handleSelectChange(val);
                                                }}
                                                isSearchable={true}
                                                options={playCrickeTeams}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderWidth: 0,
                                                        backgroundColor: '#EFF1F9',
                                                        padding: '6px 7px 6px 7px'
                                                    }),
                                                    menu: (baseStyles) => ({
                                                        ...baseStyles,
                                                        zIndex:1000,
                                                        backgroundColor: '#ffffff'
                                                    })
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 8,                                                
                                                })}
                                            />
                                        )}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={`w-full xl:px-2.5 z-0 relative`}>
                            <TextEditor
                                value={aboutEditor}
                                placeholderText="Tell us about your team..."
                                height={200}
                                charCount={true}
                                charLimit={100}
                                onChange={(e) => handleChange({ target: { value: e } }, 'editor')}
                            />
                        </div>
                        <div className="w-full lg:w-1/3 md:px-2.5 xl:px-3.5">
                            <div className="form-field-container">
                                <div className="label inline-flex relative">Image Carousel for your Web Page <img src={InfoIcon} className="cursor-pointer ml-2.5" alt="" id="webimages_info" />
                                    <Tooltip anchorSelect="#webimages_info" className="z-10">
                                        <div className="w-[280px]">
                                            <h3 className="uppercase text-[22px] leading-[33px] font-semibold border-b border-b-[#FBDB03] border-b-solid text-center">PLAI</h3>
                                            <p className="mb-4 text-[14px] leading-[19px] text-center">As a Team or Club within PLAI community, we'll deliver to you a FREE public facing web page!</p>
                                            <p className="mb-4 text-[14px] leading-[19px] text-center">No fee, no fuss, just a stunning landing page to help attract new opportunities to your club.</p>
                                            <p className="mb-4 text-[14px] leading-[19px] text-center">Your web page will be located at:</p>
                                            <p className="mb-4 text-[14px] leading-[19px] text-center">http://team.plaisport.com/{teamSlug}</p>
                                        </div>
                                    </Tooltip>
                                </div>                                
                                <div className='form-field-wrap no-field-wrap mb-5 lg:mb-0 relative'>
                                    <FilePond
                                        files={imageFiles}
                                        onupdatefiles={setImageFile}                                    
                                        allowMultiple={true}
                                        credits={false}
                                        allowFileTypeValidation={true}
                                        acceptedFileTypes={['image/jpeg', 'image/png']}
                                        labelFileTypeNotAllowed='File of invalid type'
                                        //maxFiles={1}                                    
                                        labelIdle='
                                            <div class="upload-text">
                                                <h4>Select files</h4>                                            
                                            </div>'
                                        {...register("webimages")}
                                    />
                                </div>
                                {(!isAddMode && uploadedWebImages.length > 0) &&
                                <div className="my-5">
                                    <div className="text-[16px] leading-[16px] text-white font-medium">Uploaded images</div>
                                    <ul className='flex flex-wrap'>
                                        {uploadedWebImages.map((wimage, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className='mr-5 mb-5 w-[180px] h-[180px] relative aspect-square'>
                                                        <img src={wimage} alt="" className="absolute w-full h-full left-0 right-0 object-cover" />
                                                        <button type="button" className='button-tiny delete absolute top-[10px] right-[10px] rounded-full' onClick={() => {removeImage(index)}}></button>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            }
                            </div>
                        </div>
                        <div className="w-full xl:w-1/3 xl:px-2.5">
                            <div className="form-field-container">
                                <div className='label uppercase font-bold'>Member questions.</div>
                                <div className='member-questions'>
                                    {memberQuestions.length > 0 &&
                                        memberQuestions.map((customField,index)=>{
                                            return(
                                            <div className='member-question-row' key={index}>
                                                <textarea className='!min-h-[150px]' placeholder={`Add here any question that you need answering by your members when they request to join your team!\n\n e.g. What is your Home Address?`}
                                                onChange={(e) => {
                                                    editCustomFields(customField.id, 'question', e.target.value)
                                                }}
                                                value={customField.question}
                                                >
                                                </textarea>
                                                <div className='flex justify-between items-center mb-5'>
                                                    <div className='form-field-wrap check-field-wrap relative !p-0'>
                                                        <label htmlFor={`optional-${customField.id}`} className='!text-[16px] justify-center !mb-0 !text-white'>
                                                            <input value="Yes" type="checkbox" id={`optional-${customField.id}`} name={`optional-${customField.id}`} className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'                                    
                                                                onChange={(e)=>{
                                                                    if(e.target.checked){
                                                                        editCustomFields(customField.id, 'required', 'Yes')
                                                                    }
                                                                    else{
                                                                        editCustomFields(customField.id, 'required', 'No')
                                                                    }
                                                                }}
                                                                checked={customField.required==='Yes' ? true : false}
                                                            />Required
                                                        </label>
                                                    </div>
                                                    <div><button type='button' className='button button-tiny button-blue delete' onClick={()=>deleteCustomField(index)}></button></div>
                                                </div>
                                            </div>
                                            )
                                        })
                                    }
                                    <div className='member-question-row'>
                                        <textarea className='!min-h-[150px]' 
                                            placeholder={`Add here any question that you need answering by your members when they request to join your team!\n\n e.g. What is your Home Address?`}
                                            onChange={(e) => {
                                                setMemberQuestion(e.target.value)
                                            }}
                                            value={memberQuestion}
                                        ></textarea>
                                        <div className='flex justify-between items-center mb-5'>
                                            <div className='form-field-wrap check-field-wrap relative !p-0'>
                                                <label htmlFor={`optional-${memberQuestionId}`} className='!text-[16px] justify-center !mb-0 !text-white'>
                                                    <input type="checkbox" value="Yes" id={`optional-${memberQuestionId}`} name={`optional-${memberQuestionId}`} className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'                                    
                                                        onChange={(e)=>{
                                                            if(e.target.checked){
                                                                setMemberQueRequired('Yes');
                                                            }
                                                            else{
                                                                setMemberQueRequired('No');
                                                            }
                                                        }} 
                                                        checked={memberQueRequired==='Yes' ? true : false}                                       
                                                    />Required
                                                </label>
                                            </div>
                                            <div><button type='button' className='button button-action !rounded-[14px]' onClick={()=>addCustomFields()}>Add</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        <div className="w-full xl:w-1/3 xl:px-2.5 z-0 relative">
                            <div className="max-w-[450px]">
                                <div className='form-field-wrap button-group-wrap mb-5 items-center'>
                                    <div className="mb-2.5 button-grop-label !text-white inline-flex">Approval to Join? <img src={InfoIcon} className="cursor-pointer ml-2.5" alt="" id="apporval_info" />
                                        <Tooltip anchorSelect="#apporval_info" className="z-10">
                                            <div className="w-[280px]">
                                                <h3 className="uppercase text-[22px] leading-[33px] font-semibold border-b border-b-[#FBDB03] border-b-solid text-center">Apporval to Join</h3>
                                                <p className="mb-4 text-[14px] leading-[19px] text-center">
                                                    When a user on PLAI requests to join your team page, you have the choice as to whether they are AUTOMATICALLY allowed access to your team page, or whether you wish to MANUALLY ACCEPT each request.
                                                </p>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className="r-button-group flex">
                                        <div className="mr-4">
                                        <input type="radio" {...register("approval_to_join")} value="manually" id="approval-manually" className="peer hidden" 
                                            onChange={(e)=>{
                                                setValue("approval_to_join",e.target.value);
                                            }}/>
                                        <label
                                            htmlFor="approval-manually"
                                            className="block py-1 px-2 uppercase cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >Manually Accept</label>
                                        </div>
                                        <div>
                                        <input type="radio" {...register("approval_to_join")} value="auto" id="approval-auto" className="peer hidden" 
                                            onChange={(e)=>{
                                                setValue("approval_to_join",e.target.value);
                                            }}
                                        />
                                        <label
                                            htmlFor="approval-auto"
                                            className="block py-1 px-2 uppercase cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >Auto Accept</label>
                                        </div>
                                    </div>    
                                </div>
                                {isAddMode &&
                                <>
                                <div className='form-field-wrap button-group-wrap mb-5 flex justify-between items-center'>
                                    <div className="flex-1 button-grop-label !text-white">Do you have a 6 digit referral code?</div>
                                    <div className="r-button-group w-[148px] flex">
                                        <div className="mr-4">
                                        <input type="radio" {...register("is_referral_code")} value="Yes" id="notification-yes" className="peer hidden" 
                                            onChange={(e)=>{
                                                setValue("is_referral_code",e.target.value);
                                                setIsReferralcode(e.target.value);
                                            }}/>
                                        <label
                                            htmlFor="notification-yes"
                                            className="block py-1 w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >Yes</label>
                                        </div>
                                        <div>
                                        <input type="radio" {...register("is_referral_code")} value="No" id="notification-no" className="peer hidden" 
                                            onChange={(e)=>{
                                                setValue("is_referral_code",e.target.value);
                                                setIsReferralcode(e.target.value);
                                                setIsReferralcodeValid(false);
                                            }}
                                        />
                                        <label
                                            htmlFor="notification-no"
                                            className="block py-1 w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >No</label>
                                        </div>
                                    </div>    
                                </div>
                                {isReferralCode==='Yes' &&
                                    <div className="form-field-container">
                                        <div className="label">Enter Code*</div>
                                        <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                            <input 
                                                type="text" disabled={loading} placeholder="Enter Code*" className='text-field'
                                                {...register("referral_code")}
                                                onBlur={(e)=>{checkReferralCode(e);}}
                                                onFocus={(e)=>{setReferralCodeValidMsg(null)}}
                                            />                                
                                        </div>
                                        {errors.referral_code && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.referral_code?.message}</p>}
                                        {referralCodeValidMsg!==null &&
                                            <p className="text-[12px] text-white mb-2.5 -mt-2.5">{referralCodeValidMsg}</p>
                                        }
                                    </div>
                                }
                                </>
                            }
                            </div>
                        </div>
                        
                        {!loading && errorMessage ? <p className='text-red-500 mb-2.5 text-center'>{errorMessage}</p> : null}
                        <div className="w-full px-2.5 pt-5">
                            <div className='flex justify-center mb-10'>
                                <input className='button' type="submit" value={isAddMode ? 'Create Team' : 'Update Team'} disabled={loading}/>
                            </div>
                        </div>                        
                    </form>
                </div>
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}
export default TeamForm;