import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Spinner from "../../../components/Spinner/Spinner";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getMembers, getAllProducts } from "../../../libs/dataFunctions";
import ReactDatePicker from "react-datepicker";

dayjs.extend(utc);
dayjs.extend(tz);

const schema = yup.object({
    member: yup.string().required('Select a product.'),
    new_member_name:yup.string()
    .when('member',{
        is:'manually_add_member',
        then:yup.string().required('Member name is required.')
    }).nullable(),

    product_id: yup.string().required('Select a product.'),
    new_product_name:yup.string()
    .when('product_id',{
        is:'manually_add_product',
        then:yup.string().required('Enter Product Name')
    }),
    new_product_price:yup.number()
    .when('product_id',{
        is:'manually_add_product',
        then:yup.number().required('Enter price amount').typeError('Enter valid price amount')
    }).nullable(),
    purchase_date:yup.string().required('Select purchase date'),
    paid_through: yup.string().required('Select a pay as.'),
    paid_through_other:yup.string()
    .when('paid_through',{
        is:'Other',
        then:yup.string().required('Write about paid as')
    }),
}).required();

const AddPaymentForm = ({team, setShowForm, refreshShopOrders})=>{
    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(null);
    const [successMessage,setSuccessMessage] = useState(null);
    const [members, setMembers] = useState([]);
    const [productList, setProductList] = useState([]);
    
    const { register, watch, handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const selectedMember = watch("member");
    const selectedProduct = watch("product_id");
    const selectedPayType = watch("paid_through");

    const onSubmit = async(data) => {
        setLoading(true);
        setErrorMessage(null);
        setSuccessMessage(null);
        var formdata = new FormData();
        formdata.append("type", "manual_add_order");
        formdata.append("team_id", team._id);
        
        if(data.member === 'manually_add_member'){
            formdata.append("is_new_user",'Yes');
            formdata.append("user_name",data.new_member_name);
        }
        else{
            formdata.append("is_new_user",'No');
            formdata.append("user_sub",data.member);
        }

        if(data.product_id === 'manually_add_product'){
            formdata.append("is_new_product",'Yes');
            formdata.append("product_name",data.new_product_name);
            if(data.new_product_price>0){
                formdata.append("price",data.new_product_price * 100);
            }
            else{
                formdata.append("price",0);
            }
        }
        else{
            formdata.append("is_new_product",'No');
            formdata.append("product_id",data.product_id);
        }

        if(data.paid_through === 'Other'){
            formdata.append("paid_through",data.paid_through_other);
        }
        else{
            formdata.append("paid_through",data.paid_through);
        }        
        formdata.append("date",dayjs(data.purchase_date).format("YYYY-MM-DD"));
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                setSuccessMessage(rdata.message);
                setShowForm(false);
                refreshShopOrders('get_orders_others');
            }
        })
        .catch(error => console.log('error', error));
    }

    async function refreshData(){
        setLoading(true);
        const [membersData, productsData] = await Promise.all([
            getMembers(team._id, token),
            getAllProducts(team._id, token),
        ]); 
        let membersOptions = [];
        membersData.forEach(element => {
            membersOptions.push({'value':element.sub, 'label':element.name});
        });
        setMembers(membersOptions);
        setProductList(productsData);
        setLoading(false);        
    }

    useEffect(()=>{
        refreshData();
    },[]);


    return(
        <>
        {!loading ?
        <div className="popup-form">
            <div className="popup-form-wrap !w-full !max-w-[650px] !px-7.5 relative">
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                <div className="bg-[#363F6C] py-7.5 px-5 rounded-xl">
                    <div className="form-heading text-center mb-[30px] !text-white">Add A Payment</div>
                    <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                        <div className="form-field-container mb-5">
                            <div className="label">Select Member*</div>
                            <div className='form-field-wrap mb-1.5 no-field-wrap relative'>
                                <select {...register("member")} onChange={(e)=>{setValue('member', e.target.value, { shouldValidate: true });}}>
                                    <option value="">Select Member*</option>
                                    <option value="manually_add_member">Manually Add Member</option>
                                    {members.map((c,index)=>{
                                        return(
                                            <option key={index} value={c.value}>{c.label}</option>
                                        );
                                    })}                                    
                                </select>
                            </div>
                            {errors.member?.message && <p className="error mb-2.5" role="alert">{errors.member?.message}</p> }
                        </div>
                        {selectedMember==='manually_add_member' &&
                            <>
                            <div className="form-field-container mb-5">
                                <div className="label">Member Name*</div>
                                <div className='form-field-wrap mb-1.5 no-field-wrap relative'>
                                    <input 
                                        type="text" disabled={loading} className='text-field'
                                        {...register("new_member_name")}
                                    />                                
                                </div>
                                {errors.new_member_name?.message && <p className="error mb-2.5" role="alert">{errors.new_member_name?.message}</p> }
                            </div>
                            </>
                        }
                        <div className="form-field-container mb-5">
                            <div className="label">Select Product*</div>
                            <div className='form-field-wrap mb-1.5 no-field-wrap relative'>
                                <select {...register("product_id")} onChange={(e)=>{setValue('product_id', e.target.value, { shouldValidate: true });}}>
                                    <option value="">Select Product*</option>
                                    <option value="manually_add_product">Manually Add Product</option>
                                    {productList.map((c,index)=>{
                                        return(
                                            <option key={index} value={c._id}>{c.name}</option>
                                        );
                                    })}                                    
                                </select>
                            </div>
                            {errors.product_id?.message && <p className="error mb-2.5" role="alert">{errors.product_id?.message}</p> }
                        </div>
                        {selectedProduct==='manually_add_product' &&
                            <>
                            <div className="form-field-container mb-5">
                                <div className="label">Product Name*</div>
                                <div className='form-field-wrap mb-1.5 no-field-wrap relative'>
                                    <input 
                                        type="text" disabled={loading} className='text-field'
                                        {...register("new_product_name")}
                                    />                                
                                </div>
                                {errors.new_product_name?.message && <p className="error mb-2.5" role="alert">{errors.new_product_name?.message}</p> }
                            </div>
                            <div className="form-field-container mb-5">
                                <div className="label">Product Price*</div>
                                <div className='form-field-wrap mb-1.5 no-field-wrap relative'>
                                    <input 
                                        type="number" disabled={loading} className='text-field'
                                        {...register("new_product_price")}
                                    />
                                </div>
                                {errors.new_product_price?.message && <p className="error mb-2.5" role="alert">{errors.new_product_price?.message}</p> }
                            </div>
                            </>
                        }
                        <div className="form-field-container mb-5">
                            <div className="label">Purchase Date*</div>
                            <div className='form-field-wrap mb-1.5 select-field-wrap no-field-wrap relative'>
                                <Controller
                                    control={control}
                                    name='purchase_date'
                                    errors={errors}
                                    render={({ field, ref }) => (
                                        <ReactDatePicker
                                            placeholderText='Purchase Date*'
                                            onChange={(date) => {
                                                field.onChange(date); 
                                                setValue('purchase_date',date);
                                            }}
                                            //utcOffset={timeZone}
                                            selected={field.value}
                                            mask="____-__-__"
                                            dateFormat="dd-MM-yyyy"
                                            inputRef={ref}                                     
                                        />
                                    )}
                                />
                            </div>
                            {errors.purchase_date?.message && <p className="error mb-2.5" role="alert">{errors.purchase_date?.message}</p>}
                        </div>
                        <div className="form-field-container mb-5">
                            <div className="label">Paid As*</div>
                            <div className='form-field-wrap mb-1.5 no-field-wrap relative'>
                                <select {...register("paid_through")} onChange={(e)=>{setValue('paid_through', e.target.value, { shouldValidate: true });}}>
                                    <option value="">Pay As*</option>
                                    <option value="Cash">Cash</option>
                                    <option value="Bank Transfer">Bank Transfer</option>
                                    <option value="Cheque">Cheque</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            {errors.paid_through?.message && <p className="error mb-2.5" role="alert">{errors.paid_through?.message}</p> }
                        </div>
                        {selectedPayType==='Other' &&
                            <>
                            <div className="form-field-container mb-5">
                                <div className="label">Other*</div>
                                <div className='form-field-wrap mb-1.5 no-field-wrap relative'>
                                    <textarea disabled={loading} className='text-field' placeholder="Write Here"
                                        {...register("paid_through_other")}
                                    />
                                </div>
                                {errors.paid_through_other?.message && <p className="error mb-2.5" role="alert">{errors.paid_through_other?.message}</p> }
                            </div>                            
                            </>
                        }
                        <div className='flex justify-center'>
                            <input className='button' type="submit" value="Add" disabled={loading}/>
                        </div>
                        {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                        {successMessage!=='' && <p className="text-green-600 mb-2.5 text-center" role="alert">{successMessage}</p>}
                    </form>
                </div>
            </div>
        </div>
        :
        <div className="popup-form">
            <div className="popup-form-wrap !w-full !max-w-[650px] !px-7.5 relative">
                <div className="py-5 text-center"><Spinner /></div>
            </div>
        </div>
        }
        </>
    )
}

export default AddPaymentForm;