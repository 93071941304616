import { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';
import { NavLink, useNavigate } from 'react-router-dom';
import { getCalendarMonthEvent } from '../../libs/dataFunctions';
import Sidebar from '../../components/Sidebar/Sidebar';
import AdminNavbar from '../../components/AdminNavbar/AdminNavbar';
import placeholderImg from '../../assets/images/placeholder.png';
import { useAuthState } from '../../contexts/AuthContext';
import Spinner from '../../components/Spinner/Spinner';
import plaisportVector from '../../assets/images/plaisportVector.png';

import './monthcalendar.scss';

const MonthCalendar=()=>{
    const [value, onChange] = useState(new Date());
    const [loading,setLoading] = useState(false);
    const [eventsAll, setEventsAll] = useState([]);
    const [eventsList,setEventsList] = useState([]);
    const userDetails = useAuthState();
    const [searchParam] = useState(["dateStart_display"]);
    const [filterType, setFilterType] = useState('All');
    const [showFilterOpt,setFilterOpt] = useState(false);
    const [filterParam] = useState(["type"]);
    const [typeDropdown, setShowTypeDropdown] = useState(false);

    const [sideData, seSideData] = useState({
        event_type: {
            Game: 0,
            Others: 0,
            Practise: 0,
            'Social Event': 0
        },
        total_event: 0
    });

    const navigate = useNavigate();

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const fetchEvents=async (calDate)=>{
        setLoading(true);
        let eventsData = await getCalendarMonthEvent(token, calDate);
        
        setEventsAll(eventsData.data);
        seSideData({
            event_type: eventsData.event_type,
            total_event: eventsData.total_event
        });
        setEventsList([]);
        setFilterType('All');
        setFilterOpt(false);
        setLoading(false);
    }

    const changeDate = (e) => {
        var sortedData = searchbyDate(eventsAll, dayjs(e).format("YYYY-MM-DD"));
        setEventsList(sortedData);
    }

    const changeMonth = (activeStartDate) => {
        var month;
        var date = new Date(activeStartDate);
            month = ("0" + (date.getMonth() + 1)).slice(-2);
        
        setFilterOpt(false);
        setFilterType('All');
        fetchEvents(date.getFullYear()+'-'+month+'-01',token);
    };

    useEffect(()=>{
        const cmonth = new Date().toLocaleString("en-GB", { month: "2-digit" });
        const cyear = new Date().getUTCFullYear();
        fetchEvents(cyear+'-'+cmonth+'-01');
    },[]);

    const searchbyDate=(items,q)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem] ?
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(q.toLowerCase()) > -1
                    :
                    item
                );
            });
        });
    }
    const filterBy = (items) => {
        if(filterType!=='All'){
            return items.filter((item) => {
                return filterParam.some((newItem) => {
                    return (
                        item[newItem] ?
                            item[newItem]
                                .toString()
                                .toLowerCase()
                                .indexOf(filterType.toLowerCase()) > -1
                        :
                        item
                    );
                });
            });        
        }
        else{
            return items;
        }        
    }
    const caltypedropref = useRef();
    
    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if ((typeDropdown) && caltypedropref.current && !caltypedropref.current.contains(e.target)) {
                setShowTypeDropdown(false);
            }
        }      
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[typeDropdown]);

    return(
        <>
        <Sidebar type="thin"/>
        <div id="page" className="page-calendar-events">
            <AdminNavbar heading="Calendar" />
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to="/calendar" className='home'>
                    <span>Calendar</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                Month View
            </div>
            <div className="body px-5 lg:px-8 py-10">
                <div className='lg:flex flex-wrap'>
                    <div className='flex justify-between items-center w-full lg:w-9/12 mb-4'>
                        <button type="button" className='opacity-50' onClick={()=>navigate(-1)}> <span className='separator'>&lt;</span><span className='separator'>&lt;</span> Back</button>
                        <div className='flex gap-5 items-start'>
                            <div className='relative' ref={caltypedropref}>
                                <button type='button' onClick={()=>{setShowTypeDropdown(!typeDropdown)}} className='py-3.5 px-4 rounded-xl bg-white flex gap-5 items-center'>
                                    Monthly
                                    <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5 1.5L7.5 7.5L13.5 1.5" stroke="#5E6366" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                <div className={`bg-white shadow-lg rounded-xl flex-col absolute top-[100%] mt-2 w-full z-20 ${typeDropdown ? 'flex' : 'hidden'}`}>
                                    <NavLink to='/calendar' className="px-5 py-2 border-b border-slate-300 text-sm">Weekly</NavLink>
                                    <NavLink to='/calendar/month' className="px-5 py-2 text-sm">Monthly</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full lg:w-9/12 bg-white rounded-[10px] p-5 mt-6 lg:mt-0 flex flex-col lg:flex-row'>
                        <div className='calander-events-monthview mb-10 lg:mb-0'>
                            <Calendar
                                onChange={changeDate}
                                value={value}
                                onActiveStartDateChange={({ activeStartDate, view }) => {
                                    if (view === "month") {
                                        changeMonth(activeStartDate); // Call only when the view is for months
                                    }
                                }}
                                tileContent={({activeStartDate, date, view})=>{
                                    let count=0;
                                    if(eventsAll.length>0){
                                        if(eventsAll.find(x=>x.dateStart_display===dayjs(date).format("YYYY-MM-DD"))){
                                            count++;
                                        }
                                        return (
                                            count>0 ? 
                                                <>
                                                <div className="flex justify-center py-2 absolute w-full bottom-0 left-0">                                    
                                                    {searchbyDate(eventsAll,dayjs(date).format("YYYY-MM-DD")).map(function(object, i){
                                                        return <span className="w-[4px] h-[4px] mx-[2px] bg-[var(--color-yellow)] rounded-full" key={object._id+i}></span>
                                                    })}
                                                </div>
                                                </>
                                            : null
                                            
                                        );
                                    }
                                }}
                                tileClassName={({ date, view }) => {
                                    if (
                                        eventsAll.some(
                                            (event) => event.dateStart_display === dayjs(date).format("YYYY-MM-DD")
                                        )
                                    ) {
                                        return "has-event"; // Add your desired CSS class
                                    }
                                    return ""; // No class if no events
                                }}
                            />
                        </div>
                        <div className='flex-1 lg:pl-5 flex flex-col gap-5'>
                            {eventsList.length>0 ?
                            <>
                                {eventsList.map((eitem)=>{
                                    return(
                                        <NavLink to={`/team/${eitem.teamId}/event/${eitem._id}`} target="_blank" className='flex flex-col md:flex-row bg-[var(--color-darkblue)] relative p-5 rounded-2xl text-white' key={eitem._id}>
                                            <div className='absolute top-0 left-0'>
                                                <img src={plaisportVector} alt=''></img>
                                            </div>
                                            <div className='w-full flex flex-col md:flex-row'>
                                                <div className='w-full md:w-3/12 mb-5 md:mb-0'>
                                                    <img src={eitem?.club_logo} className='w-15 h-15 border-2 border-white rounded-full mx-auto' alt=''></img>
                                                </div>
                                                <div className='w-full md:w-9/12'>
                                                    <div className='flex flex-col md:flex-row justify-between mb-6'>
                                                        <div className='mb-6 md:mb-0'>
                                                            <p className='text-xs opacity-60'>Name</p>
                                                            <p className='text-sm font-medium'>{eitem?.name}</p>
                                                        </div>
                                                        <div className='event-metadata'>
                                                            <div className='event-time relative pl-7 lg:mr-5 2xl:mr-8'>{dayjs('1/1/1 '+eitem.timeStart_display).format('hh:mm A')} to {dayjs('1/1/1 '+eitem.timeEnd_display).format('hh:mm A')}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className='text-xs opacity-60'>Location</p>
                                                        <p className='text-sm font-medium'>{eitem?.location}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    )
                                })}
                            </>
                            :
                            <p>No Events Found...</p>
                            }
                        </div>
                    </div>
                    <div className='w-full lg:w-1/4 flex items-center flex-col'>
                        {userDetails?.user?.profilePic !== "" ?
                        <img src={userDetails?.user?.profilePic} className="w-40 h-40 rounded-full img-right-profile" alt="" width={40} height={40} />
                        :
                        <img src={placeholderImg} alt="" className="w-40 h-40 rounded-full img-right-profile" width={40} height={40} />
                        }
                        <div className='h-64 w-64 bg-[#363F6C] rounded-2xl -top-10 flex justify-center items-center flex-col'>
                            <div className='gradient-1'></div>
                            <p className='font-normal text-2xl text-white pt-7 pb-2'>Events this month</p>
                            <p className='font-extrabold text-7xl count-event pb-7'>{sideData?.total_event}</p>
                            <div className='gradient-2'></div>
                        </div>
                        <div className='grid grid-cols-2 gap-4 mt-6'>
                            <div className='category-count'>
                                <p className='category'>Practice</p>
                                <p className='count'>{sideData?.event_type?.Practise}</p>
                            </div>
                            <div className='category-count'>
                                <p className='category'>Games</p>
                                <p className='count'>{sideData?.event_type?.Game}</p>
                            </div>
                            <div className='category-count'>
                                <p className='category'>Social</p>
                                <p className='count'>{sideData?.event_type?.['Social Event']}</p>
                            </div>
                            <div className='category-count'>
                                <p className='category'>Others</p>
                                <p className='count'>{sideData?.event_type?.Others}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {loading &&
            <div className='popup-form justify-center'>
                <Spinner></Spinner>
            </div>
        }
        </>
    )
}

export default MonthCalendar;