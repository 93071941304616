import { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { DatePicker } from 'rsuite';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import CheckTreePicker from 'rsuite/CheckTreePicker';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CoachesWithRoleInputV2 from "../../../components/CoachesWithRoleInputv2/CoachesWithRoleInputv2";
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(customParseFormat);

const FixtureMigrationRow=({fixtureItem, team, token, parentGroups, groups, checkedDataList, setCheckedDataList})=>{
    const [coaches, setCoaches] = useState([]);
    const [selectedCoachesList, setSelectedCoachesList] = useState([]);  
    const [selectedGroups, setSelectedGroups]  = useState([]);
    const [isSelected, setIsSelected] = useState(false);
    const [fixtureLocalItem, setFixtureLocalItem] = useState(null);
    const contentRef=useRef();
    const [isMounted, setIsMounted] = useState(false);

    const handleCheck=(e)=>{
        const { id, checked, value } = e.target;
        let tmpObj = {};
        
        Object.assign(tmpObj, JSON.parse(value));
        setIsSelected(checked);
        if (!checked) {
            let dselected = checkedDataList.filter((item) => {
                if(item.event_match_id!==id.toString()){return item;} return false;
            })
            setCheckedDataList(dselected);
        }
        else{
            setCheckedDataList([...checkedDataList, fixtureLocalItem]);
        }
    }
    
    const handleLocationChange=async(val)=>{
        if(val!==null){
            let tmplatlang = await geocodeByAddress(val.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) =>{
                    return { lat, lng };
                }
            );   

            fixtureLocalItem.latitude=tmplatlang.lat;
            fixtureLocalItem.longitude=tmplatlang.lng;
            fixtureLocalItem.location=val.label;
            setFixtureLocalItem({...fixtureLocalItem});
            
            setCheckedDataList(checkedDataList.filter((item) => {
                if(item.event_match_id===fixtureLocalItem.event_match_id.toString()){
                    item.latitude=tmplatlang.lat;
                    item.longitude=tmplatlang.lng;
                    item.location=val.label;
                }
                return item;
            }));
        }
    }

    const handleGroupChange=(val)=>{
        fixtureLocalItem.groups=val.join('@#');
        setFixtureLocalItem({...fixtureLocalItem});
        setCheckedDataList(checkedDataList.filter((item) => {
            if(item.event_match_id===fixtureLocalItem.event_match_id.toString()){
                item.groups=val.join('@#');             
            }
            return item;
        }))
    }
    
    useEffect(()=>{
        if (!isMounted) {
            setIsMounted(true); // Mark as mounted
            return; // Don't run the effect if it's the initial render
        }
        var fixterDataJson=null;
        let foundFlag = [];
        if(checkedDataList!==undefined && checkedDataList.length > 0){
            foundFlag = checkedDataList.filter((item) => {
                if(item.event_match_id===fixtureItem.id.toString()){
                    let tmpDescription = "";
                    if(fixtureItem.home_club_name!=="" && fixtureItem.home_club_name!==null){
                        tmpDescription += fixtureItem.home_club_name+"<br>";
                    }
                    if(fixtureItem.home_team_name!=="" && fixtureItem.home_team_name!==null){
                        tmpDescription += fixtureItem.home_team_name+"<br>";
                    }
                    if(fixtureItem.away_club_name!=="" && fixtureItem.away_club_name!==null){
                        tmpDescription += fixtureItem.away_club_name+"<br>";
                    }
                    if(fixtureItem.away_team_name!=="" && fixtureItem.away_team_name!==null){
                        tmpDescription += fixtureItem.away_team_name+"<br>";
                    }
                    if(fixtureItem.competition_name!=="" && fixtureItem.competition_name!==null){
                        tmpDescription += fixtureItem.competition_name+"<br>";
                    }
                    if(fixtureItem.competition_type!=="" && fixtureItem.competition_type!==null){
                        tmpDescription += fixtureItem.competition_type+"<br>";
                    }
                    if(fixtureItem.league_name!=="" && fixtureItem.league_name!==null){
                        tmpDescription += fixtureItem.league_name+"<br>";
                    }

                    fixterDataJson = {
                        event_match_id: item.event_match_id,
                        name:fixtureItem.home_club_name+' vs '+fixtureItem.away_club_name,
                        sub_title: fixtureItem.home_team_name+' vs '+fixtureItem.away_team_name,
                        description: tmpDescription,
                        dateStart: item.dateStart,
                        timeStart: item.timeStart,
                        timeEnd: item.timeEnd,
                        location:item.location,
                        latitude:item.latitude,
                        longitude:item.longitude,
                        coaches_new:item.coaches_new,
                        groups:item.groups,
                        isError: item.isError,
                        isCompleted: item.isCompleted,
                        isStartTimeError:item.isStartTimeError,
                        isEndTimeError:item.isEndTimeError,
                        isEndTimeSmallError:item.isEndTimeSmallError,
                        isCoachErorr:item.isCoachErorr,
                        isGroupError:item.isGroupError
                    }; 
                    setFixtureLocalItem(fixterDataJson);
                    return fixterDataJson;
                }
                return [];
            });
        }
        
        if(foundFlag.length <= 0){
            let tmpDescription = "";
            if(fixtureItem.home_club_name!=="" && fixtureItem.home_club_name!==null){
                tmpDescription += fixtureItem.home_club_name+"<br>";
            }
            if(fixtureItem.home_team_name!=="" && fixtureItem.home_team_name!==null){
                tmpDescription += fixtureItem.home_team_name+"<br>";
            }
            if(fixtureItem.away_club_name!=="" && fixtureItem.away_club_name!==null){
                tmpDescription += fixtureItem.away_club_name+"<br>";
            }
            if(fixtureItem.away_team_name!=="" && fixtureItem.away_team_name!==null){
                tmpDescription += fixtureItem.away_team_name+"<br>";
            }
            if(fixtureItem.competition_name!=="" && fixtureItem.competition_name!==null){
                tmpDescription += fixtureItem.competition_name+"<br>";
            }
            if(fixtureItem.competition_type!=="" && fixtureItem.competition_type!==null){
                tmpDescription += fixtureItem.competition_type+"<br>";
            }
            if(fixtureItem.league_name!=="" && fixtureItem.league_name!==null){
                tmpDescription += fixtureItem.league_name+"<br>";
            }

            fixterDataJson = {
                event_match_id: fixtureItem.id.toString(),
                name:fixtureItem.home_club_name+' vs '+fixtureItem.away_club_name,
                sub_title: fixtureItem.home_team_name+' vs '+fixtureItem.away_team_name,
                description: tmpDescription,
                dateStart: fixtureItem.match_date,
                timeStart: fixtureItem.match_time!=="" ? fixtureItem.match_time : "",
                timeEnd: fixtureLocalItem.timeEnd,
                location:fixtureLocalItem.location,
                latitude:fixtureLocalItem.latitude,
                longitude:fixtureLocalItem.longitude,
                coaches_new:fixtureLocalItem.coaches_new,
                groups: fixtureLocalItem.groups,
                isError: false,
                isCompleted: false,
                isStartTimeError:false,
                isEndTimeError:false,
                isEndTimeSmallError:false,
                isCoachErorr:false,
                isGroupError:false
            };  
            setFixtureLocalItem(fixterDataJson);
        }
    },[checkedDataList, isMounted]);


    useEffect(()=>{
        var fixterDataJson=null;
        let tmpDescription = "";
        if(fixtureItem.home_club_name!=="" && fixtureItem.home_club_name!==null){
            tmpDescription += fixtureItem.home_club_name+"<br>";
        }
        if(fixtureItem.home_team_name!=="" && fixtureItem.home_team_name!==null){
            tmpDescription += fixtureItem.home_team_name+"<br>";
        }
        if(fixtureItem.away_club_name!=="" && fixtureItem.away_club_name!==null){
            tmpDescription += fixtureItem.away_club_name+"<br>";
        }
        if(fixtureItem.away_team_name!=="" && fixtureItem.away_team_name!==null){
            tmpDescription += fixtureItem.away_team_name+"<br>";
        }
        if(fixtureItem.competition_name!=="" && fixtureItem.competition_name!==null){
            tmpDescription += fixtureItem.competition_name+"<br>";
        }
        if(fixtureItem.competition_type!=="" && fixtureItem.competition_type!==null){
            tmpDescription += fixtureItem.competition_type+"<br>";
        }
        if(fixtureItem.league_name!=="" && fixtureItem.league_name!==null){
            tmpDescription += fixtureItem.league_name+"<br>";
        }

        fixterDataJson = {
            event_match_id: fixtureItem.id.toString(),
            name:fixtureItem.home_club_name+' vs '+fixtureItem.away_club_name,
            sub_title: fixtureItem.home_team_name+' vs '+fixtureItem.away_team_name,
            description: tmpDescription,
            dateStart: fixtureItem.match_date,
            timeStart: fixtureItem.match_time!=="" ? fixtureItem.match_time : "",
            timeEnd: '',
            location:'',
            location:'',
            latitude:'',
            coaches_new:'',
            groups:'',
            isError: false,
            isCompleted: false,
            isStartTimeError:false,
            isEndTimeError:false,
            isEndTimeSmallError:false,
            isCoachErorr:false,
            isGroupError:false
        };  
        setFixtureLocalItem(fixterDataJson);
    },[]);

    return (
        <>
        {fixtureLocalItem!=null &&
            <tr className={`border-b border-black-100/10 align-top ${(isSelected && fixtureLocalItem.isError) ? 'bg-red-100' : ''}`}>
                <td className="text-left py-4.5">
                    <div className="flex items-start gap-2.5">
                        <div className="pt-1">
                            <input id={fixtureLocalItem.event_match_id}
                                type="checkbox" 
                                className="appearance-none relative peer w-4.5 h-4.5 border-2 border-[#CCD6DF] shrink-0 checked:bg-black checked:border-black" 
                                onChange={handleCheck}
                                value={JSON.stringify(fixtureLocalItem)}
                                checked={checkedDataList.some((item)=>{ return item.event_match_id===fixtureLocalItem.event_match_id.toString() })}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor={fixtureLocalItem.event_match_id} className="cursor-pointer mb-0 text-xs font-semibold text-[var(--color-darkblue)]">{fixtureLocalItem.name}</label>
                            <span className="text-xs">{fixtureLocalItem.sub_title}</span>
                        </div>
                    </div>
                </td>
                <td className="text-left pt-8 pb-1 text-xs w-28">{dayjs(fixtureLocalItem.dateStart, 'DD/MM/YYYY').format("DD MMM YYYY")}</td>
                <td className={`text-left ${fixtureLocalItem.timeStart!=='' ? 'pt-8 pb-1' : 'py-4.5'} relative fixture-field text-xs w-40`}>
                    {fixtureLocalItem.timeStart!==''?
                    <>
                        {dayjs('1/1/1 '+fixtureLocalItem.timeStart).format('hh:mm A')}
                    </>
                    :
                    <>
                        <DatePicker 
                            placeholder='Start Time *'
                            format="hh:mm aa" 
                            onChange={(date) => {
                                fixtureLocalItem.timeStart=dayjs(date).format('HH:mm');
                                setFixtureLocalItem({...fixtureLocalItem});
                                setCheckedDataList(checkedDataList.filter((item) => {
                                    if(item.event_match_id===fixtureLocalItem.event_match_id.toString()){
                                        item.timeStart=dayjs(date).format('HH:mm');
                                    }
                                    return item;
                                }));
                            }}
                            caretAs={null} 
                            cleanable={false}
                        />
                        {(isSelected && fixtureLocalItem.isStartTimeError) && <p className="text-red-600 text-xs">Please select start time</p>}
                    </>
                    }
                </td>
                <td className="text-left py-4.5 relative fixture-field w-40">
                    <DatePicker 
                        placeholder='End Time*'
                        format="hh:mm aa" 
                        onChange={(date) => {
                            fixtureLocalItem.timeEnd=dayjs(date).format('HH:mm');
                            setFixtureLocalItem({...fixtureLocalItem});
                            setCheckedDataList(checkedDataList.filter((item) => {
                                if(item.event_match_id===fixtureLocalItem.event_match_id.toString()){
                                    item.timeEnd=dayjs(date).format('HH:mm');
                                }
                                return item;
                            }));
                        }}
                        caretAs={null} 
                        cleanable={false}
                    />
                    {(isSelected && fixtureLocalItem.isEndTimeError) && <p className="text-red-600 text-xs">Please select end time</p>}
                    {(isSelected && fixtureLocalItem.isEndTimeSmallError) && <p className="text-red-600 text-xs">End time must be greater than start time.</p>}
                    
                </td>
                <td className="text-left py-4.5 pr-4">
                    {/*
                    <input type="text" className="!bg-white !px-2.5 !py-2.5 border border-black/0.5 !text-sm !w-32" placeholder="Location" onChange={(e)=>{
                        handleLocationChange(e);
                    }} />
                     */}
                    <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_GAPIKEY}
                        selectProps={{
                            //locationValue,
                            onChange: handleLocationChange,
                            //components: { DropdownIndicator },
                            styles: {
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderWidth: 1,
                                    backgroundColor: '#ffffff',
                                    padding: '2px 7px 2px 0px'
                                }),
                                option: (baseStyles) => ({
                                    ...baseStyles,
                                    backgroundColor: '#ffffff',
                                    zIndex: 10000
                                }),
                                menu: (baseStyles) => ({
                                    ...baseStyles,
                                    zIndex:1000,
                                    backgroundColor: '#ffffff'
                                }),
                            },
                            theme:(theme) => ({
                                ...theme,
                                borderRadius: 8,                                                
                            }),
                        }}                                    
                    />                    
                </td>
                <td className="text-left py-4.5 relative">
                    <CoachesWithRoleInputV2 
                        teamID={team._id} 
                        token={token} 
                        className="!bg-white !px-2.5 !py-2.5 border border-black/0.5 !text-sm !w-52" 
                        selectedCoaches={selectedCoachesList} 
                        parentsGroups={parentGroups} 
                        options={coaches} 
                        onChange={(value)=>{
                            let selectedCoaches = [];
                            setSelectedCoachesList(value);
                            if(value!==undefined){
                                value.forEach((scoach)=>{
                                    if(scoach.role==='OTHER'){
                                        selectedCoaches.push({'sub':scoach.sub, 'coach_role':scoach.custom_role});
                                    }
                                    else{
                                        selectedCoaches.push({'sub':scoach.sub, 'coach_role':scoach.role});
                                    }                    
                                });
                                
                                fixtureLocalItem.coaches_new =JSON.stringify(selectedCoaches);
                                setFixtureLocalItem({...fixtureLocalItem});
                                setCheckedDataList(checkedDataList.filter((item) => {
                                    if(item.event_match_id===fixtureLocalItem.event_match_id.toString()){
                                        item.coaches_new =JSON.stringify(selectedCoaches);
                                    }
                                    return item;
                                }));
                            }
                        }}
                        onChangeMain={setCoaches} 
                    />
                    {(isSelected && fixtureLocalItem.isCoachErorr) && <p className="text-red-600 text-xs">Please select at least one coach</p>}
                </td>
                <td className="text-left py-4.5 relative fixture-group-field" ref={contentRef}>
                    <CheckTreePicker
                        defaultExpandAll
                        block={true}
                        placeholder="Select Groups*"
                        data={groups}
                        onChange={val => {
                            setSelectedGroups(val);
                            handleGroupChange(val);
                        }}
                        container={()=>contentRef.current}
                        onSelect={(item,val,event)=>{
                            if(item.label.toLowerCase()==="all members"){
                                if(item.check){
                                    let groupIds=[];
                                    groups.forEach((item)=>{
                                        groupIds.push(item.value);
                                    });
                                    setSelectedGroups(groupIds);
                                    handleGroupChange(groupIds);
                                }
                                else{
                                    setSelectedGroups([]);
                                    handleGroupChange([]);
                                }
                            }
                        }}
                        menuStyle={{zIndex: 100000000}}
                        defaultValue={selectedGroups}
                        value={selectedGroups}
                        style={{
                            width:'208px',
                            borderWidth:0,
                            height: '42px',
                            borderRadius: 8
                        }}                                                        
                    />
                    {(isSelected && fixtureLocalItem.isGroupError) && <p className="text-red-600 text-xs">Please select at least one group</p>}
                </td>
            </tr>
        }
        </>        
    )
}
export default FixtureMigrationRow;