export const getAgentCode = (token)=>{    
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_agent_code");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/club_signup' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    });
}

export const getClubAgentCode = ()=>{    
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_agent_code");
        var secureHeader = new Headers();
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/club_signup' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    });
}

export const getSports = ()=>{    
    return new Promise((resolve,reject)=>{
        var secureHeader = new Headers();
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'GET',
            redirect: 'follow',  
            headers: secureHeader  
        };

        fetch(process.env.REACT_APP_APIURL + '/app_sports' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    });
}

export const getLatests= (token) => {
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_home");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        fetch(process.env.REACT_APP_APIURL + '/home' , requestOptions)
        .then(response => response.text())
        .then(result => {    
            resolve(JSON.parse(result).data);
        })
        .catch(error => console.log('error', error));
    })
}

export const getLatestNews= (token) => {
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_latest_with_ads");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        fetch(process.env.REACT_APP_APIURL + '/home' , requestOptions)
        .then(response => response.text())
        .then(result => {    
            resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
    })
}

export const getTeams=(token)=>{
    return new Promise((resolve, reject) => {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      var formdata = new FormData();
      formdata.append("type", "all_teams");
      formdata.append("timezone",timeZone);
      var secureHeader = new Headers();
      secureHeader.append('Authorization','Bearer ' + token);
      secureHeader.append('device_type','W');
      const requestOptions = {
          method: 'POST',
          redirect: 'follow',
          body: formdata,
          headers: secureHeader
      };
      fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
      .then(response => response.text())
      .then(result => {    
        resolve(JSON.parse(result));
      })
      .catch(error => console.log('error', error));
    });
}

export const getAdminUpdateRequest=(token)=>{    
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_admin_update_request");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
        .then(response => response.text())
        .then(result => {    
            resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
    });
}

export const getTeamDetail = async(id, token) => {

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "team_detail");
        formdata.append("timezone", timeZone);
        formdata.append("team_id", id);
        formdata.append("is_need_to_check_child" ,"Yes");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    })
}

export const getNews= (id, token) => {
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_news_with_ads");
        formdata.append("team_id", id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_news' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    })
}

export const getNewsDetail= (teamid, newsid, token) => {
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "news_details");
        formdata.append("team_id", teamid);
        formdata.append("news_id",newsid)
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_news' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
    })
}

export const getEventsPast = (teamid, token)=> {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_events_with_ads");
        formdata.append("team_id", teamid);
        formdata.append('event_type','past');
        formdata.append('timezone',timeZone);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    })
}

export const getEventsUpcoming= (teamid, token)=> {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_events_with_ads");
        formdata.append("team_id", teamid);
        formdata.append('event_type','upcoming');
        formdata.append('timezone',timeZone);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    })
}

export const getEventsLists= (teamid, token, type, filterby=null, group_ids=null)=> {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_events_with_ads");
        formdata.append("team_id", teamid);
        formdata.append('event_type',type);
        formdata.append('timezone',timeZone);
        if(filterby!==null){
            formdata.append('search_filter',filterby);
        }
        if(group_ids!==null){
            formdata.append('group_ids',group_ids.join('@#'));
            formdata.append('separate_by', '@#')
        }
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    })
}

export const getEventDetail = (eventid, teamid, token)=>{
    return new Promise((resolve, reject) => {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        var formdata = new FormData();
        formdata.append("type", "event_details");
        formdata.append("team_id", teamid);
        formdata.append("event_id", eventid);
        formdata.append('timezone',timeZone);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {    
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    });
}

export const getDuties=(teamid, token)=>{
    return new Promise((resolve,reject)=>{
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        var formdata = new FormData();
        formdata.append("type", "get_duties");
        formdata.append("team_id", teamid);
        formdata.append('timezone',timeZone);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    })
}

export const getDutiesUpcoming= (teamid, token)=> {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_duties");
        formdata.append("team_id", teamid);
        formdata.append('event_type','upcoming');
        formdata.append('timezone',timeZone);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    })
}

export const getDutiesPast= (teamid, token)=> {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_duties");
        formdata.append("team_id", teamid);
        formdata.append('event_type','past');
        formdata.append('timezone',timeZone);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    })
}

export const getGroups=(teamid, token)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "team_groups");
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data);
        })
        .catch(error => console.log('error', error));
    })
}

export const getGroupsV2Old=(teamid, token)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "team_groups_v2");
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data);
        })
        .catch(error => console.log('error', error));
    })
}

export const getGroupsV2=(teamid, token)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "user_assign_groups");
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data);
        })
        .catch(error => console.log('error', error));
    })
}

export const getUserAsignedGroup=(token, teamid, itemId, module)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "user_assign_groups_v2");
        formdata.append("team_id", teamid);
        formdata.append("id", itemId);
        formdata.append("module_type",module);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data);
        })
        .catch(error => console.log('error', error));
    })
}

export const getGroupsV2Filter=(teamid, token)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "user_assign_groups_for_filter");
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data);
        })
        .catch(error => console.log('error', error));
    })
}

export const getGroupsNames=(teamid, token)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "team_groups_name");
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data);
        })
        .catch(error => console.log('error', error));
    })
}

export const getGroupMembers=(teamid, token, group_id)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_group_members");
        formdata.append("team_id", teamid);
        formdata.append("group_id",group_id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data);
        })
        .catch(error => console.log('error', error));
    })
}

export const getMembers = (teamid, token) => {
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_members");
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        secureHeader.append('access-control-allow-origin','*');
        
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data);
        })
        .catch(error => console.log('error', error));
    })
}

export const getMembersCoach = (teamid, token) => {
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_members_for_coach");
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        secureHeader.append('access-control-allow-origin','*');
        
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
    })
}

export const getInvitedMembers = (teamid, token) => {
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "invite_members");
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data);
        })
        .catch(error => console.log('error', error));
    })
}

export const getMemberDetail = (teamid, memberID, token) => {
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_member_details");
        formdata.append("team_id", teamid);
        formdata.append("member_id", memberID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
    })
}

export const getGroupMemberDetail = (teamid, memberID, token, groupId) => {
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_member_details");
        formdata.append("team_id", teamid);
        formdata.append("member_id", memberID);
        formdata.append("group_id", groupId);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
    })
}

export const getParentChilds = (teamid, parentsub, token) => {
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "joined_child");
        formdata.append("team_id", teamid);
        formdata.append("parent_sub",parentsub);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        secureHeader.append('access-control-allow-origin','*');
        
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data);
        })
        .catch(error => console.log('error', error));
    })
}

export const getProducts= (teamid,token)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_product");
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
    })
}

export const getProductsByCatg= (teamid,category,token)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_category_products");
        formdata.append("team_id", teamid);
        formdata.append("category", category);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
    })
}

export const getAllProducts= (teamid,token)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_all_product");
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data);
        })
        .catch(error => console.log('error', error));
    })
}

export const getProductDetail = (productid, teamid, token)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "product_details");
        formdata.append("team_id", teamid);
        formdata.append("product_id", productid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {    
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getProductCategories = (teamid,token) => {
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_prodcut_category");
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data);
        })
        .catch(error => console.log('error', error));
    })
}

export const getLinks = (teamid,token) => {
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_links");
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_links' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    });
}

export const getLinkIcons = (token) => {
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_link_icons");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_links' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    });
}

export const getShopOrders = (teamid,orderType,token) => {
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", orderType);
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getMemberOrders = (memberid,teamid,token) => {
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "member_orders");
        formdata.append("team_id", teamid);
        formdata.append("member_id", memberid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_orders' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getMyOrders = (token) => {
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "my_order");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_orders' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getTransitionFees = (counrycode,token, amount) => {
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_transactionFee");
        formdata.append("country", counrycode);
        formdata.append("amount",amount);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_transactionFee' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    });
}

export const checkEmailExists = (email) => {
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "email");
        formdata.append("value", email);
        var secureHeader = new Headers();
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/checkexistsemailmobile' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getAllTeams=(token, lat, lng)=>{
    return new Promise((resolve, reject) => {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      var formdata = new FormData();
      formdata.append("type", "search_teams_with_locations");
      formdata.append("timezone",timeZone);
      formdata.append("is_show_all_team",'Yes');
      formdata.append("latitude",lat!==undefined ? lat : 0);
      formdata.append("longitude",lng!==undefined ? lng : 0);
      var secureHeader = new Headers();
      secureHeader.append('Authorization','Bearer ' + token);
      secureHeader.append('device_type','W');
      const requestOptions = {
          method: 'POST',
          redirect: 'follow',
          body: formdata,
          headers: secureHeader
      };
      fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
      .then(response => response.text())
      .then(result => {    
        resolve(JSON.parse(result));
      })
      .catch(error => console.log('error', error));
    });
}

export const getSearchKeyTeams=(token, searchkey, lat, lng)=>{
    return new Promise((resolve, reject) => {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      var formdata = new FormData();
      formdata.append("type", "search_teams_with_locations");
      formdata.append("timezone",timeZone);
      formdata.append("is_show_all_team",'Yes');
      formdata.append("search_key",searchkey);
      formdata.append("latitude",lat!==undefined ? lat : 0);
      formdata.append("longitude",lng!==undefined ? lng : 0);
      var secureHeader = new Headers();
      secureHeader.append('Authorization','Bearer ' + token);
      secureHeader.append('device_type','W');
      const requestOptions = {
          method: 'POST',
          redirect: 'follow',
          body: formdata,
          headers: secureHeader
      };
      fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
      .then(response => response.text())
      .then(result => {    
        resolve(JSON.parse(result));
      })
      .catch(error => console.log('error', error));
    });
}

export const getMyTeams=(token)=>{
    return new Promise((resolve, reject) => {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      var formdata = new FormData();
      formdata.append("type", "my_teams_v2");
      formdata.append("timezone",timeZone);
      var secureHeader = new Headers();
      secureHeader.append('Authorization','Bearer ' + token);
      secureHeader.append('device_type','W');
      const requestOptions = {
          method: 'POST',
          redirect: 'follow',
          body: formdata,
          headers: secureHeader
      };
      fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
      .then(response => response.text())
      .then(result => {    
        resolve(JSON.parse(result));
      })
      .catch(error => console.log('error', error));
    });
}

export const getRequets=(teamid,token)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "user_requests");
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    });
}

export const getProfile=(token)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "getProfile");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_user' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    });
}

export const getEventResponseJoinMembers = (token,teamId,eventID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "all_join_members_v2");
        formdata.append("team_id", teamId);
        formdata.append("event_id", eventID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    });
}

export const getRemainingJoinMembers = (token,teamId)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "remaining_child");
        formdata.append("team_id", teamId);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    });
}

export const getPendingTeams=(token)=>{
    return new Promise((resolve, reject) => {
      var formdata = new FormData();
      formdata.append("type", "user_pending_requests");
      var secureHeader = new Headers();
      secureHeader.append('Authorization','Bearer ' + token);
      secureHeader.append('device_type','W');
      const requestOptions = {
          method: 'POST',
          redirect: 'follow',
          body: formdata,
          headers: secureHeader
      };
      fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
      .then(response => response.text())
      .then(result => {    
        resolve(JSON.parse(result));
      })
      .catch(error => console.log('error', error));
    });
}

export const getMyDocuments=(token)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_documents");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        fetch(process.env.REACT_APP_APIURL + '/app_documents' , requestOptions)
        .then(response => response.text())
        .then(result => {    
          resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
      });
}

export const getProductOrders=(teamid, productid,token)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_product_orders");
        formdata.append("team_id",teamid);
        formdata.append("product_id",productid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {    
          resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
      });
}

export const getMyPaymentCards=(token)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_cards");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        fetch(process.env.REACT_APP_APIURL + '/app_stripe_card' , requestOptions)
        .then(response => response.text())
        .then(result => {    
          resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
      });
}

export const getDutiesAll=(date, token)=>{
    return new Promise((resolve,reject)=>{
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        var formdata = new FormData();
        formdata.append("type", "get_all_duties_v2");
        formdata.append('date',date);
        formdata.append('timezone',timeZone);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data)
        })
        .catch(error => console.log('error', error));
    })
}

export const getSMScharges = (token,group_ids,teamId) => {
    return new Promise((resolve,reject)=>{
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        var formdata = new FormData();
        formdata.append("type", "get_sms_charges");
        formdata.append('team_id',teamId);
        formdata.append('group_ids',group_ids.join('@#'));
        formdata.append('separate_by','@#')
        formdata.append('timezone',timeZone);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    })
}

export const getAds = (teamID, token) =>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_ads");
        formdata.append('team_id',teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_ads' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getStripeAccounts = (teamID, token) =>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_stripe_account");
        formdata.append('team_id',teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getSubscriptionPlans = (subscriptionfor, token) =>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_subscription_plan");
        formdata.append('subscription_for',subscriptionfor);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_subscription' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getSubscriptionHistroy = (subscriptionfor, token, teamID=null) =>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_subscription_history");
        formdata.append('subscription_for',subscriptionfor);
        if(subscriptionfor==='team'){
            formdata.append('team_id',teamID);
        }
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_subscription' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getMyCart = (token,teamid) =>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_cart_details");
        formdata.append('team_id',teamid);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_checkout' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getDiscountsCatg = (token, teamID) =>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_discount_category");
        formdata.append("team_id",teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_user' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getDiscounts = (token,teamid) =>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_discount");
        formdata.append('team_id',teamid);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_user' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getPlayers = (teamid, token) =>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_players");
        formdata.append('team_id',teamid);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_fantasy' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

//Get fantacy game rule
export const getRule = (teamid, token) =>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_rules");
        formdata.append('team_id',teamid);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_fantasy' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getGameSubscriptionPlans = (token) =>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_subscription_details");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_fantasy' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getLeaderShipData = (token,type,teamid) =>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", type);
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_fantasy' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getLeaderShipDetail = (token,member_sub,teamid) =>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_member_team_info");
        formdata.append("team_id", teamid);
        formdata.append("member_sub", member_sub);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_fantasy' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getTimeZones = (token, country) => {
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_all_timezones_v2");
        formdata.append("country",country);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_user' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data);
        })
        .catch(error => console.log('error', error));
    })
}

export const getCalendarEvent=(token, sdate)=>{ //sdate should be like 2023-03-20 this format
    return new Promise((resolve, reject) => {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      var formdata = new FormData();
      formdata.append("type", "get_calendar_events");
      formdata.append("timezone",timeZone);
      formdata.append("date", sdate); 
      var secureHeader = new Headers();
      secureHeader.append('Authorization','Bearer ' + token);
      secureHeader.append('device_type','W');
      const requestOptions = {
          method: 'POST',
          redirect: 'follow',
          body: formdata,
          headers: secureHeader
      };
      fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
      .then(response => response.text())
      .then(result => {    
        resolve(JSON.parse(result));
      })
      .catch(error => console.log('error', error));
    });
}

export const getCalendarMonthEvent=(token, sdate)=>{ //sdate should be like 2023-03-20 this format
    return new Promise((resolve, reject) => {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      var formdata = new FormData();
      formdata.append("type", "get_calendar_events");
      formdata.append("calander_type", "month");
      formdata.append("typeof_calendar","month");
      formdata.append("timezone",timeZone);
      formdata.append("date", sdate); 
      var secureHeader = new Headers();
      secureHeader.append('Authorization','Bearer ' + token);
      secureHeader.append('device_type','W');
      const requestOptions = {
          method: 'POST',
          redirect: 'follow',
          body: formdata,
          headers: secureHeader
      };
      fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
      .then(response => response.text())
      .then(result => {    
        resolve(JSON.parse(result));
      })
      .catch(error => console.log('error', error));
    });
}

export const getMemberQuestion=(token, teamId)=>{
    return new Promise((resolve, reject) => {
      var formdata = new FormData();
      formdata.append("type", "get_member_question");
      formdata.append("team_id", teamId); 
      var secureHeader = new Headers();
      secureHeader.append('Authorization','Bearer ' + token);
      secureHeader.append('device_type','W');
      const requestOptions = {
          method: 'POST',
          redirect: 'follow',
          body: formdata,
          headers: secureHeader
      };
      fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
      .then(response => response.text())
      .then(result => {    
        resolve(JSON.parse(result));
      })
      .catch(error => console.log('error', error));
    });
}

export const FantacyRemainingMembers = (teamid, token) => {
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "remaining_members");
        formdata.append("team_id", teamid);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        secureHeader.append('access-control-allow-origin','*');
        
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_fantasy' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result).data);
        })
        .catch(error => console.log('error', error));
    })
}

export const getMembershipCards = (token) => {
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_membership_card");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        secureHeader.append('access-control-allow-origin','*');
        
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_user' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
    })
}

export const getMembershipCardsByMember = (token, membersub, teamId) => {
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_membership_card");
        formdata.append("team_id", teamId);
        formdata.append("user_sub", membersub);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        secureHeader.append('access-control-allow-origin','*');
        
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_user' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
    })
}

export const getRemainingAttendees = (token,teamID,eventID)=>{    
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "remaining_attendees");
        formdata.append("team_id",teamID);
        formdata.append("event_id",eventID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        secureHeader.append('access-control-allow-origin','*');
        
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
    })
}

export const getReferralTeams = (token,teamID)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_refferal_teams");
        formdata.append("team_id",teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        secureHeader.append('access-control-allow-origin','*');
        
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
    })
}

export const getNotRespondedList = (token,teamID, eventID)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "not_responded_members");
        formdata.append("team_id",teamID);
        formdata.append("event_id",eventID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        secureHeader.append('access-control-allow-origin','*');
        
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
    })
}

export const getManageNotification = (token,teamID)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_manage_notification");
        formdata.append("team_id",teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        secureHeader.append('access-control-allow-origin','*');
        
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/manage_notification' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
    })
}

export const getRemainingGroupMembers = (token,teamID, groupID)=>{
    return new Promise((resolve, reject) => {
        var formdata = new FormData();
        formdata.append("type", "get_remaining_members");
        formdata.append("team_id",teamID);
        formdata.append("group_id",groupID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        secureHeader.append('access-control-allow-origin','*');
        
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result));
        })
        .catch(error => console.log('error', error));
    })
}

//Chat related api calls
export const getConversations=(token,team_id)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_conversations");
        formdata.append("team_id", team_id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

//Chat Archive
export const getArchiveConversations=(token,team_id)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_archive_conversations");
        formdata.append("team_id", team_id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

//Get Conversation Detail
export const getConversationsDetails=(token,team_id,conv_id)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_conversation_details");
        formdata.append("team_id", team_id);
        formdata.append("conversation_id", conv_id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

//Get Chat Group Detail
export const getChatGroupDetails=(token,team_id,group_id)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_group_detail");
        //formdata.append("team_id", team_id);
        formdata.append("conversation_id", group_id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getMembersForChat=(token,team_id)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_members_for_chat");
        formdata.append("team_id", team_id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getNewMembersListForChat=(token,team_id)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_new_chat_user_list");
        formdata.append("team_id", team_id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getSingleConversation=(token,convID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_single_conversation");
        formdata.append("conversation_id", convID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getMessageHistory=(token,convID, msgID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "read_message_history");
        formdata.append("conversation_id", convID);
        formdata.append("conversation_details_id", msgID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getNewsSeenBy=(token,teamID, newsID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_news_seen_details");
        formdata.append("team_id", teamID);
        formdata.append("news_id", newsID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_news' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getEventSeenBy=(token,teamID, eventID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "events_seen_details");
        formdata.append("team_id", teamID);
        formdata.append("event_id", eventID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getGalleryFolder=(token,teamID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_gallery_folder");
        formdata.append("team_id", teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_gallery' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}
export const getGalleryMedia=(token,teamID,folderId)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_media");
        formdata.append("team_id", teamID);
        formdata.append("media_folder_id", folderId);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_gallery' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getUnApprovedMedia=(token,teamID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_unapproved_media");
        formdata.append("team_id", teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_gallery' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getDraftNews=(teamID, token)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_news_draft");
        formdata.append("team_id", teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_news' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getYourForms=(teamID, token)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_forms");
        formdata.append("team_id", teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_forms' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getYourFormDetail=(teamID, token, formID, sub)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "form_detail");
        formdata.append("team_id", teamID);
        formdata.append("form_id",formID);
        formdata.append("sub",sub);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_forms' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getYourFormDetailSubmitted=(teamID, token, formID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_submited_from_details");
        formdata.append("team_id", teamID);
        formdata.append("submited_form_id",formID)
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_forms' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getAllBookings = (teamID, token, calDate)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_all_booking");
        formdata.append("team_id", teamID);
        formdata.append("date",calDate)
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_booking_facilities' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getBookingJoinMembers=(token, teamID, bookingID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_booking_join_members");
        formdata.append("team_id", teamID);
        formdata.append("booking_id",bookingID)
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_booking_facilities' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getFacilities=(token, teamID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_facilities");
        formdata.append("team_id", teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_booking_facilities' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getMemberFacilities=(token,teamID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_member_booking");
        formdata.append("team_id", teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_booking_facilities' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getBookingDates=(token,teamID,facilityID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_booking_dates");
        formdata.append("team_id", teamID);
        formdata.append("booking_facilities_id",facilityID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_booking_facilities' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getBookingSlots=(token,teamID,facilityID,bookingDate)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_slots_v2");
        formdata.append("team_id", teamID);
        formdata.append("booking_facilities_id",facilityID);
        formdata.append("date",bookingDate);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_booking_facilities' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getFormResponses=(token, teamID, formID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_submited_froms");
        formdata.append("team_id", teamID);
        formdata.append("form_id",formID)
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_forms' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getPromoCode = (token,teamID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_promocode");
        formdata.append("team_id", teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_promocode' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getRemainingMatch = (token,teamID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_remaining_match");
        formdata.append("team_id", teamID);
        const d = new Date();
        let year = d.getFullYear();
        formdata.append("year", year);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getScorecardV2=(token, teamID, eventID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_scorecard_v2");
        formdata.append("team_id", teamID);
        formdata.append("event_id", eventID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getFantacyMatrix=(token, teamID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_fantasy_matrixs");
        formdata.append("team_id", teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_fantasy' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const checkStripeConnect=(token, teamID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "check_stripe_connect");
        formdata.append("team_id", teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getFacilityDetail=(token, teamID, facilityID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "facility_details");
        formdata.append("team_id", teamID);
        formdata.append("booking_facilities_id", facilityID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_booking_facilities' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getMergeMemberList=(token, teamID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_duplicate_members");
        formdata.append("team_id", teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getBookingFacilityDuration=(token, teamID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_booking_durations");
        formdata.append("team_id", teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_booking_facilities' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const checkInstallmentPlanActive=(token,teamID, productId)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "check_installment_plan_active");
        formdata.append("team_id", teamID);
        formdata.append("product_id", productId);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getGroupArray=(token,teamID)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_groups_array");
        formdata.append("team_id", teamID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getPlayTeams=(token)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_play_teams");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getWallet=(token, teamID=null)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_wallet");
        if(teamID!==null){
            formdata.append("team_id", teamID);
        }
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_wallet' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getWalletStatement=(token, teamID=null)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_wallet_statement");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_wallet' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getRequestDetail=(token, requestId)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_request_payment");
        formdata.append("request_id",requestId);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getEventAvailability=(token, teamID, date)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_event_availability_from_date");
        formdata.append("team_id",teamID);
        formdata.append("date",date);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getDisplayEcbConsent=(token)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "display_ecb_consent");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/home' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getJoinedChild=(formId,teamId,token)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "joined_child");
        formdata.append("team_id", teamId);
        formdata.append("form_id", formId);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_forms' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}

export const getGeneralScoreInfo=(token)=>{
    return new Promise((resolve,reject)=>{
        var formdata = new FormData();
        formdata.append("type", "get_general_score_info");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            resolve(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
    });
}