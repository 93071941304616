import { useState } from "react";
import dayjs from "dayjs";
import { useAuthState } from '../../../contexts/AuthProvider';
import placeholderImg from "../../../assets/images/placeholder.png";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import DeclineResponseForm from "./DeclineResponseForm";
//import ResponsePaymentForm from "./ResponsePaymentForm";

dayjs.extend(utc);
dayjs.extend(tz);
//Response row
const ResponseRow = ({response, status, teamid, eventid, eventType, setEventItem, refreshEvent, eventUpdating, setActionLoading})=>{
    const [responseLoading, setResponseLoading]=useState(false);
    const responseStatus = status;
    const Availability = response.availability;
    const user = useAuthState() //read user details from context
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const [showResponseDeclineForm, setShowResponseDeclineForm] = useState(false);

    let responseAvailability = '';
    let raclass='';
    if(Availability.toLowerCase() === 'available' ){
        responseAvailability = 'Available';
        raclass='available';
    }
    else if(Availability.toLowerCase() === 'more time needed'){
        responseAvailability = 'More time needed';
        raclass='more-time-needed';
    }
    else{
        responseAvailability = 'Unavailable';
        raclass='unavailable';
    }

    const SubmitResponseStatus = async (responseStatus) =>{
        try {
            setResponseLoading(true);
            setActionLoading(true);
            var formdata = new FormData();
            formdata.append("type", "change_request_status");
            formdata.append("team_id", teamid);
            formdata.append("event_id", eventid);
            formdata.append("request_id", response._id);
            formdata.append("status",responseStatus);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + user.token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
            .then(rresponse => rresponse.text())
            .then(result => {
                setResponseLoading(false);
                let rdata=JSON.parse(result);
                setEventItem(rdata.data);
                setActionLoading(false);
            })
            .catch(error => console.log('error', error));
        } catch (e) {
        }
    }

    
    const ShowResponseDeclineSubmit = ({ setShowForm }) => {
        return (
            <div className="popup-form">
                <div className="popup-form-wrap relative !px-7.5 !w-full !max-w-[560px]">
                    <button type="button" className="close-popup" onClick={(e) => { setShowForm(false) }}><span>close</span></button>
                    <DeclineResponseForm setShowForm={setShowForm} eventID={eventid} teamID={teamid} resonseID={response._id} refreshEvent={refreshEvent} setEventItem={setEventItem} setActionLoading={setActionLoading} />
                </div>
            </div>
        )
    }

    return(
        <>
        <li>
            <div className='response-row mb-2.5 flex items-center justify-between pl-[14px] pr-4 md:pr-[30px] py-[15px] w-full'>
                <div className='flex items-center flex-1'>
                    <div className='profile-pic'>
                        {response.profilePicture!=="" ? 
                            <img className='rounded-full mr-2.5' src={response.profilePicture} width="50px" height="50px" alt={response.name} />
                            :
                            <img className='rounded-full mr-2.5' src={placeholderImg} width="50px" height="50px" alt={response.name} />                            
                        }                        
                    </div>
                    <div className='response-detail'>
                        <div className='name'>{response.member_name}</div>
                        {response.user_name !== "" && 
                            <div className='response-additional text-slate-500 mb-[5px]'>Submitted by {response.user_name}</div>
                        }
                        {response.additional !== "" && 
                            <div className='response-additional mb-[5px]'>{response.additional}</div>
                        }
                        <div className='response-date relative pl-5 mb-2'>{dayjs.utc(response.date).tz(timeZone).format("DD MMM YYYY | hh:mm A")}</div>                        
                    </div>
                </div>
                <div className='response-action'>
                    <div className='flex flex-col'>
                        <div className={`response-status relative mb-4 ${raclass}`}>
                            {responseAvailability}
                        </div>
                        {(responseStatus === 'approved' && !responseLoading && !eventUpdating) &&
                            <div className='flex'>
                                <button type="button" className='response-button decline-line' onClick={() => {setShowResponseDeclineForm(true);}}>
                                    {eventType==='Game' || eventType==='Competition' ? 'Not Selected' :'Declined'}
                                </button>
                            </div>
                        }
                        {(responseStatus === 'rejected' && !responseLoading && !eventUpdating) &&
                            <div className='flex'>
                                <button type="button" className='response-button accept-blue' onClick={() => {SubmitResponseStatus('approved')}}>
                                    {eventType==='Game' || eventType==='Competition' ? 'Selected' :'Accept'}
                                </button>
                            </div>
                        }
                        {(responseStatus === 'pending_approval' && !responseLoading && !eventUpdating) && 
                                <div className='flex'>
                                    <button type="button" className='response-button accept mr-1.5' onClick={() => {SubmitResponseStatus('approved')}}>
                                        {eventType==='Game' || eventType==='Competition' ? 'Selected' :'Accept'}
                                    </button>
                                    <button type="button" className='response-button decline' onClick={() => {setShowResponseDeclineForm(true);}}>
                                        {eventType==='Game' || eventType==='Competition' ? 'Not Selected' :'Declined'}
                                    </button>
                                </div>                                                  
                        }
                        {(responseStatus === 'need_more_time' && !responseLoading && !eventUpdating) && 
                                <div className='flex'>
                                    <button type="button" className='response-button accept-blue mr-1.5' onClick={() => {SubmitResponseStatus('approved')}}>
                                        {eventType==='Game' || eventType==='Competition' ? 'Selected' :'Accept'}
                                    </button>
                                    <button type="button" className='response-button decline-line' onClick={() => {setShowResponseDeclineForm(true);}}>
                                        {eventType==='Game' || eventType==='Competition' ? 'Not Selected' :'Declined'}
                                    </button>
                                </div>                                                  
                        }
                        {(responseStatus === 'waitlist' && !responseLoading && !eventUpdating) && 
                                <div className='flex'>
                                    <button type="button" className='response-button accept-blue mr-1.5' onClick={() => {
                                        if (window.confirm("Your event is currently at the capacity set, are you sure you would like to add this player to the event?")) {
                                            SubmitResponseStatus('approved');
                                        }
                                    }}>
                                        {eventType==='Game' || eventType==='Competition' ? 'Selected' :'Accept'}
                                    </button>
                                    <button type="button" className='response-button decline-line' onClick={() => {setShowResponseDeclineForm(true);}}>
                                        {eventType==='Game' || eventType==='Competition' ? 'Not Selected' :'Declined'}
                                    </button>
                                </div>                                                  
                        }
                        {(responseLoading || eventUpdating) &&
                            <div className='flex'><button type="button" className='response-button loading'></button></div>
                        }
                        {showResponseDeclineForm &&
                            <ShowResponseDeclineSubmit setShowForm={setShowResponseDeclineForm} />
                        }
                    </div>
                </div>
            </div>
        </li>
        </>
    );
}

export default ResponseRow;