import { useEffect,useState } from "react";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import Sidebar from "../../components/Sidebar/Sidebar";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
//import { useAuthState } from "../../contexts/AuthContext";
import placeholderImg from '../../assets/images/placeholder.png';
import nochildren from '../../assets/images/no-children.svg';
import Spinner from "../../components/Spinner/Spinner";
import './profile.scss';
import { getProfile, getTeams } from "../../libs/dataFunctions";
import MyTeamRow from "../Settings/Myteams/MyTeamRow";
import ProfileForm from "./EditProfile";
import ChildernForm from "./ChildernForm";
import ChildRow from "./ChildRow";


const ProfilePage=()=>{
    const [userDetails, setUserDetail] = useState([]);
    const [myTeamList, setMyteams] = useState([])
    const [loading, setLoading] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [showChildForm, setChildFormShow] = useState(false);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const refreshProfile = async() =>{
        setLoading(true);
        
        const [profileData, myTeamsData] = await Promise.all([
            getProfile(token),
            //getMyTeams(token),
            getTeams(token)
        ]);
        setUserDetail(profileData);
        setMyteams(myTeamsData.data);
        setLoading(false);
    }

    const ProfileEditForm = ({setShowForm}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap popup-form-product w-full max-w-[800px] relative">                    
                    <ProfileForm user={userDetails} setShowForm={setShowForm} refreshProfile={refreshProfile}/>
                </div>
            </div>
        )
    }
    const ChildAddForm = ({setShowForm}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap !w-full !max-w-[870px] relative !px-7.5">                    
                    <ChildernForm setShowForm={setShowForm} refreshProfile={refreshProfile} />
                </div>
            </div>
        )
    }
    useEffect(()=>{
        refreshProfile();
    },[])
    
    const ProfileView=()=>{
        return(
            !loading ?
            <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                Profile
            </div>
            <div className="body px-5 lg:px-8 py-10 profile-page">
                <div className='profile-header rounded-[14px] md:flex md:flex-row justify-end md:justify-between md:items-center pl-5 lg:pl-[60px] pr-5 lg:pr-8 py-6'>
                    <div className="md:flex-1">
                        <div className="profile-meta flex w-full flex-col md:flex-row md:items-center">
                            <div className="image-wrapper rounded-full p-1.5 bg-white max-w-[187px] mx-auto md:mx-0">
                                {userDetails?.profilePicture && userDetails?.profilePicture !== "" ?
                                    <img src={userDetails?.profilePicture} className="rounded-full" alt="" width={175} height={175} />
                                    :
                                    <img src={placeholderImg} alt="" className="rounded-full" width={175} height={175} />
                                }
                            </div>
                            <div className="user-data flex-1 md:max-w-[440px] md:pl-[30px]">
                                <h1 className="mb-5 mt-[10px] text-center md:text-left">{userDetails?.firstName} {userDetails?.lastName}</h1>
                                <div className="mb-2.5 flex flex-wrap sm:justify-between text-[14px] leading-[24px] font-medium">
                                    <div className="w-full sm:w-1/3 country flex">
                                        <svg className="mr-2.5" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.99999 15.8596C8.0978 15.0901 7.26156 14.2464 6.49999 13.3375C5.35714 11.9725 4 9.93963 4 8.00249C3.9995 7.01318 4.29251 6.04596 4.84192 5.22323C5.39134 4.40051 6.17247 3.75926 7.08647 3.38065C8.00047 3.00203 9.00624 2.90307 9.9765 3.09629C10.9468 3.2895 11.8379 3.76621 12.5371 4.46607C13.0027 4.92954 13.3717 5.48074 13.6228 6.08777C13.8739 6.69479 14.0021 7.34559 14 8.00249C14 9.93963 12.6428 11.9725 11.5 13.3375C10.7384 14.2464 9.90217 15.0901 8.99999 15.8596ZM8.99999 5.85964C8.43167 5.85964 7.88663 6.08541 7.48476 6.48727C7.0829 6.88913 6.85714 7.43417 6.85714 8.00249C6.85714 8.57081 7.0829 9.11586 7.48476 9.51772C7.88663 9.91958 8.43167 10.1453 8.99999 10.1453C9.56831 10.1453 10.1134 9.91958 10.5152 9.51772C10.9171 9.11586 11.1428 8.57081 11.1428 8.00249C11.1428 7.43417 10.9171 6.88913 10.5152 6.48727C10.1134 6.08541 9.56831 5.85964 8.99999 5.85964Z" fill="#FBDB03"/>
                                        </svg>
                                        {userDetails?.country}
                                    </div>
                                    <div className="w-full sm:w-1/3 gender flex">
                                        <svg className="mr-2.5" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle opacity="0.1" cx="12.3125" cy="12" r="12" fill="black"/>
                                            <path d="M14.1328 9.24704L16.0221 7.32494C15.985 7.32076 15.9531 7.31435 15.9213 7.31407C15.6442 7.31407 15.3674 7.31407 15.0903 7.31407C14.9203 7.31268 14.8562 7.25419 14.8535 7.08094C14.8481 6.75133 14.8481 6.42173 14.8535 6.09212C14.8565 5.93029 14.9273 5.86929 15.0856 5.86929C16.121 5.86929 17.1555 5.86929 18.1892 5.86929C18.3922 5.86929 18.4461 5.92667 18.4461 6.13418C18.4461 7.19133 18.4461 8.24857 18.4461 9.3059C18.4461 9.47553 18.3837 9.54238 18.217 9.5435C17.899 9.54572 17.5811 9.54628 17.2634 9.5435C17.0947 9.5421 17.0394 9.4772 17.04 9.30145C17.04 8.98753 17.04 8.67334 17.04 8.36667L15.1507 10.3005C16.0544 11.5753 16.3127 13.0237 15.9294 14.5794C15.6267 15.8075 14.896 16.7447 13.886 17.4483C12.0624 18.7201 9.32391 18.5658 7.58453 16.6305C5.9566 14.82 5.94543 12.023 7.32189 10.2261C9.00731 8.02509 12.0409 7.65157 14.1328 9.24704ZM11.2175 9.70997C9.39557 9.65204 7.71259 11.2731 7.72594 13.263C7.73929 15.242 9.27895 16.835 11.2022 16.8358C13.1299 16.8358 14.6856 15.2365 14.6949 13.2613C14.7028 11.2946 13.0653 9.66541 11.2164 9.70997H11.2175Z" fill="#FBDB03"/>
                                        </svg>
                                        {userDetails?.gender}
                                    </div>
                                    {(userDetails?.dateOfBirth!=="" && userDetails.dateOfBirth!==null) &&
                                        <div className="w-full sm:w-1/3 dob flex">
                                            <svg className="mr-2.5" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.8889 4.11111H12.3333V3.55556C12.3333 3.25 12.0833 3 11.7778 3C11.4722 3 11.2222 3.25 11.2222 3.55556V4.11111H6.77778V3.55556C6.77778 3.25 6.52778 3 6.22222 3C5.91667 3 5.66667 3.25 5.66667 3.55556V4.11111H5.11111C4.49444 4.11111 4.00556 4.61111 4.00556 5.22222L4 13C4 13.2947 4.11706 13.5773 4.32544 13.7857C4.53381 13.994 4.81643 14.1111 5.11111 14.1111H12.8889C13.5 14.1111 14 13.6111 14 13V5.22222C14 4.61111 13.5 4.11111 12.8889 4.11111ZM12.8889 12.4444C12.8889 12.75 12.6389 13 12.3333 13H5.66667C5.36111 13 5.11111 12.75 5.11111 12.4444V6.88889H12.8889V12.4444ZM6.22222 8H7.33333V9.11111H6.22222V8ZM8.44444 8H9.55556V9.11111H8.44444V8ZM10.6667 8H11.7778V9.11111H10.6667V8Z" fill="#FBDB03"/>
                                            </svg>
                                            {dayjs(userDetails?.dateOfBirth).format('DD MMM YYYY')}
                                        </div>
                                    }
                                </div>
                                <div className="flex flex-wrap justify-between text-[14px] leading-[24px] font-medium">
                                    <div className="w-full sm:w-1/2 phone flex">
                                        <svg className="mr-2.5" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle opacity="0.1" cx="10" cy="10" r="10" fill="black"/>
                                            <path d="M14.736 9.68358H15.9991C15.9991 6.44358 13.553 4 10.3086 4V5.26316C12.8741 5.26316 14.736 7.1219 14.736 9.68358Z" fill="#FBDB03"/>
                                            <path d="M10.316 7.78939C11.6442 7.78939 12.2107 8.35592 12.2107 9.68413H13.4739C13.4739 7.64729 12.3528 6.52624 10.316 6.52624V7.78939ZM12.4772 11.2271C12.3559 11.1168 12.1964 11.0579 12.0325 11.063C11.8686 11.0681 11.713 11.1366 11.5987 11.2542L10.0873 12.8086C9.72355 12.7391 8.99218 12.5111 8.23934 11.7601C7.48649 11.0067 7.25849 10.2734 7.19092 9.91213L8.74397 8.40013C8.8617 8.2859 8.93037 8.13033 8.93544 7.96637C8.94051 7.8024 8.88158 7.64289 8.77113 7.5216L6.43744 4.9555C6.32694 4.83383 6.17337 4.76003 6.00933 4.74977C5.84529 4.73951 5.68371 4.7936 5.55892 4.90055L4.18839 6.07592C4.0792 6.18551 4.01402 6.33136 4.00523 6.48581C3.99576 6.64371 3.81513 10.3839 6.71534 13.2854C9.24544 15.8149 12.4147 15.9999 13.2875 15.9999C13.4151 15.9999 13.4934 15.9961 13.5143 15.9949C13.6687 15.9862 13.8145 15.9208 13.9235 15.8111L15.0983 14.4399C15.2053 14.3152 15.2595 14.1537 15.2494 13.9896C15.2392 13.8256 15.1655 13.672 15.044 13.5614L12.4772 11.2271Z" fill="#FBDB03"/>
                                        </svg>
                                        {userDetails?.phoneCode}{userDetails?.phoneNumber}
                                    </div>
                                    <div className="w-full sm:w-1/2 email"><a href={`mailto:${userDetails?.email}`} className="underline text-[#FBDB03]">{userDetails?.email}</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile-cta md:w-[100px] pt-2.5 md:pt-0 text-center md:text-right">
                        <button type="button" className='button button-white button-white-edit mx-auto' onClick={(e)=>{setOpenEditForm(true)}}>Edit</button>
                    </div>                    
                </div>
                {openEditForm &&
                    <ProfileEditForm setShowForm={setOpenEditForm} />
                }
                <div className="flex flex-wrap">
                    <div className="w-full md:w-2/3 md:pr-[13px] mb-2.5 lg:mb-0">
                        <div className="my-teams bg-white rounded-[14px] p-[32px]">
                            <h2 className="uppercase font-bold text-[20px] leading-[22px] mb-[18px]">My Club</h2>
                            <div className="flex flex-wrap">
                            {myTeamList.length > 0 &&
                                <>
                                {myTeamList.map((myTeam)=>{
                                    return(
                                        <div className="w-full mb-5 md:w-1/2 px-2.5" key={myTeam._id}>
                                            <MyTeamRow myteam={myTeam} refreshMyTeams={refreshProfile} setLoading={setLoading} />
                                        </div>
                                    )
                                    })
                                }
                                </>
                            }
                            </div>
                        </div>
                    </div>
                    {userDetails.childrens && userDetails.childrens.length>0 ?
                    <div className="w-full md:w-1/3 md:pl-[13px]">
                        <div className="bg-white rounded-[14px] p-[32px]">
                            <h2 className="uppercase font-bold text-[20px] leading-[22px] mb-[18px]">My Children</h2>
                            <div className="children-list flex flex-wrap">
                                {userDetails.childrens.map((ch,index)=>{
                                    return(
                                        <ChildRow index={index} key={index} childitem={ch} refreshProfile={refreshProfile}/>
                                    )
                                })}
                            </div>
                            <div className='auth-form-field-wrap flex justify-center mb-2.5 md:mb-10'>
                                <input className='button button--large' type="button" value={'Add Another child'} onClick={()=>{setChildFormShow(true)}} />
                            </div>  
                        </div>
                    </div>
                    :
                    <>
                    <div className="w-full md:w-1/3 md:pl-[13px]">
                        <div className="bg-white rounded-[14px] p-[32px]">
                            <h2 className="uppercase font-bold text-[20px] leading-[22px] mb-[18px]">My Children</h2>                            
                            <div className='flex flex-col justify-center items-center mb-10'>
                                <img src={nochildren} width={231} height={214} alt="" className='mb-2.5' />
                                <p className='text-center text-[16px] leading-[28px] text-black'>No Children added yet!</p>
                            </div>
                            <div className='auth-form-field-wrap flex justify-center mb-2.5 md:mb-10'>
                                <input className='button button--large' type="button" value={'Add your child'} onClick={()=>{setChildFormShow(true)}} />
                            </div>                            
                        </div>
                    </div>
                    </>
                    }
                    {showChildForm &&
                        <ChildAddForm setShowForm={setChildFormShow} />
                    }
                </div>
                
            </div>
            </>
            :
            <>
                <div className="py-5 text-center"><Spinner /></div>
            </>
        )
    }
    return(
        <>
            <Sidebar type="thin" />
            <div id="page" className="page-profile">
                <AdminNavbar heading="Profile"/>
                <ProfileView />                   
            </div>
        </>
    )
}

export default ProfilePage;