import { useState, useEffect } from "react";
import Spinner from "../../../../components/Spinner/Spinner";
import TeamMembershipCardBlock from "./TeamMembershipCardBlock";
import { getMembershipCardsByMember } from "../../../../libs/dataFunctions";
const TeamMembershipCards=({teamId, membersub, token, setShowForm})=>{
    const [loading,setLoading] = useState();
    const [membershipCards,setMembershipCards] = useState([]);
    const [cardsFetchError, setCardsFetchError] = useState('');

    async function fetchMembershipCards(){
        setLoading(true);
        setCardsFetchError('');
        let membershipData = await getMembershipCardsByMember(token, membersub, teamId);
        if(membershipData.data && membershipData.data.length > 0){
            setMembershipCards(membershipData.data);
        }
        else{
            setCardsFetchError(membershipData.message);
        }
        setLoading(false);
    }
    
    useEffect(()=>{
        fetchMembershipCards();
    },[]);

    return(
        <>
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails !w-auto !max-w-[95vw] relative !px-2.5 sm:!px-8">
            {!loading ?
                <>                                    
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                <div className="my-documents">
                    <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                        <h2 className="title mr-5">Membership Card</h2>
                        <div className="sep mr-5 sm:mr-11"></div>
                    </div>                
                    {membershipCards.length > 0 ?
                    <>
                        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-4 xl:gap-x-7.5">
                            {membershipCards.map((mcard,index) => { 
                                return (
                                    <li key={index} className={`mb-7.5`}>
                                        {<TeamMembershipCardBlock cardDetail={mcard} />}
                                    </li>
                                )
                            })}                            
                        </ul>
                    </>
                    :
                    <p className="mb-5">{cardsFetchError}</p>
                    }
                        
                </div>
                </>
                :
                <>
                <div className="py-5 text-center"><Spinner /></div>
                </>
            }
            </div>
        </div>
        </>
    )
}

export default TeamMembershipCards;